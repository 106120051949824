import React from "react";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import WebLayout from "../components/WebLayout";
import PostList from "../components/PostList";

const GET_ENROLLMENT = gql`
  query GetEnrollment {
    articles(category: ENROLLMENT) {
      id
      title
      source
      picture {
        filename
        mimetype
        encoding
        location
      }
      description
      content
      createdAt
    }
  }
`;

export default function Enrollment() {
  const { data } = useQuery(GET_ENROLLMENT);
  return (
    <WebLayout>
      {data && <PostList data={data.articles} />}
    </WebLayout>
  );
}
