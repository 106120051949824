import React, { createContext, useContext, useReducer } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
} from "@material-ui/core";

const AlertContext = createContext();
const defaultAlertSitting = {
  title: "通知",
  content: "",
  buttons: [{ text: "確認", onClick: () => {} }],
};

export function useAlert() {
  const { alertItemDispatch } = useContext(AlertContext);
  const alert = (title, content, buttons) => {
    alertItemDispatch({
      type: "開啟",
      item: { title, content, buttons },
    });
  };
  return { alert };
}

function _alertItemDispatch(state, action) {
  const alertSetting = {
    title: action.item.title || defaultAlertSitting["title"],
    content: action.item.content || defaultAlertSitting["content"],
    buttons: action.item.buttons || defaultAlertSitting["buttons"],
    item: action.item,
  };
  switch (action.type) {
    case "開啟":
      return { open: true, ...alertSetting };
    case "關閉":
      return { open: false, ...alertSetting };
    default:
      return { open: false, ...alertSetting };
  }
}

export function Provider({ children }) {
  const [alertItem, alertItemDispatch] = useReducer(
    _alertItemDispatch,
    defaultAlertSitting
  );
  function _alertClose() {
    alertItemDispatch({ type: "關閉", item: alertItem.item });
  }
  const buttonType = (type) => {
    switch (type) {
      case "ok":
        return "contained";
      case "cancel":
        return "outlined";
      case "close":
        return "text";
      default:
        return "text";
    }
  };
  return (
    <AlertContext.Provider value={{ alertItemDispatch }}>
      {children}
      <Dialog
        open={alertItem.open}
        onClose={_alertClose}
        disableScrollLock
        fullWidth
      >
        <DialogTitle>{alertItem.title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{alertItem.content}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {alertItem.buttons.map((item) => (
            <Button
              color="primary"
              variant={buttonType(item.type)}
              onClick={() => {
                item.onClick && item.onClick();
                _alertClose();
              }}
              disableElevation
            >
              {item.text}
            </Button>
          ))}
        </DialogActions>
      </Dialog>
    </AlertContext.Provider>
  );
}
