import React from "react";
import {
  Box,
  Grid,
  Button,
  InputBase,
  useTheme,
  makeStyles,
} from "@material-ui/core";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { ReactComponent as AddImageIcon } from "../images/addImage.svg";
import { DeleteButton } from "./Buttons";
import CardBox from "./CardBox";
import { useAlert } from "./Alert";

const UPLOAD_IMAGE = gql`
  mutation UploadImage($image: Upload!) {
    uploadImage(image: $image) {
      filename
      mimetype
      encoding
      location
    }
  }
`;

export default function PostEdit({
  title,
  setTitle,
  source,
  setSource,
  picture,
  setPicture,
  description,
  setDescription,
  content,
  setContent,
}) {
  const theme = useTheme();
  const useStyles = makeStyles({
    CopyContent: {},
  });
  const classes = useStyles();
  const CardSize = { flex: 1, margin: theme.spacing(0.5) };
  const Alert = useAlert();
  const [uploadImage] = useMutation(UPLOAD_IMAGE, {
    onCompleted({ uploadImage }) {
      delete uploadImage.__typename;
      setPicture(uploadImage);
    },
  });
  function _DeleteImage() {
    Alert.alert("", "確定要刪除除片？", [
      { text: "確定", onClick: () => setPicture(null), type: "ok" },
      { text: "取消" },
    ]);
  }
  // useEffect(() => {
  //   onChangeImage(uploadImage);
  // }, [uploadImage]);
  return (
    <Grid container>
      <Grid container item>
        <Grid item xs={12} sm={6}>
          <CardBox title={"標題"} style={CardSize}>
            <InputBase
              placeholder="請輸入標題"
              fullWidth
              value={title}
              onChange={(event) => setTitle(event.target.value)}
            />
          </CardBox>
        </Grid>
        <Grid item xs={12} sm={6}>
          <CardBox title={"來源網址"} style={CardSize}>
            <InputBase
              placeholder="請輸入來源網址"
              fullWidth
              value={source}
              onChange={(event) => setSource(event.target.value)}
            />
          </CardBox>
        </Grid>
      </Grid>
      <Grid container item>
        <Grid container direction="column" item xs={12} sm={6}>
          <Grid item>
            <CardBox
              title={"上傳圖片"}
              style={CardSize}
              buttons={[
                picture && (
                  <DeleteButton title={"圖片"} onClick={_DeleteImage} />
                ),
              ]}
            >
              <Box display="flex" flex={1} height={200}>
                {picture ? (
                  <img
                    src={picture.location}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                ) : (
                  <>
                    <Button
                      variant="contained"
                      color="secondary"
                      component="label"
                      htmlFor="imageUpload"
                      disableElevation
                      style={{
                        display: "flex",
                        flex: 1,
                        position: "relative",
                      }}
                    >
                      <AddImageIcon width={50} height={50} fill={"white"} />
                    </Button>
                    <input
                      id="imageUpload"
                      type="file"
                      accept="image/*"
                      onChange={(event) => {
                        const {
                          target: {
                            validity,
                            files: [file],
                          },
                        } = event;

                        if (validity.valid) {
                          uploadImage({ variables: { image: file } });
                        }
                      }}
                      style={{
                        display: "none",
                      }}
                    />
                  </>
                )}
              </Box>
            </CardBox>
          </Grid>
          <Grid item>
            <CardBox title={"簡述"} style={CardSize}>
              <InputBase
                placeholder="請輸入文章大綱"
                rowsMax={2}
                multiline
                fullWidth
                value={description}
                onChange={(event) => setDescription(event.target.value)}
              />
            </CardBox>
          </Grid>
        </Grid>
        <Grid container item direction="column" xs={12} sm={6}>
          <CardBox title={"文案內容"} style={CardSize}>
            <InputBase
              placeholder="請輸入文案內容"
              rows={10}
              fullWidth
              multiline
              classes={{ root: classes.CopyContent }}
              value={content}
              onChange={(event) => setContent(event.target.value)}
            />
          </CardBox>
        </Grid>
      </Grid>
    </Grid>
  );
}
