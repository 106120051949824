import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  Grid,
  Tabs,
  Tab,
  Typography,
  Button,
  Box,
  useTheme,
  Select,
  MenuItem,
} from "@material-ui/core";
import { Calendar } from "@material-ui/pickers";
import moment from "moment";
import { DatePicker } from "@material-ui/pickers";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

import WebLayout from "../components/WebLayout";
import divisions from "./../../divisions.json";
import { useAlert } from "./../components/Alert";

const COUNSELING_ROOMS = gql`
  query counselingRooms($division: Division) {
    counselingRooms(division: $division) {
      id
      name
      division
      address
    }
  }
`;

const RESERVATIONS = gql`
  query reservations(
    $startDate: Date
    $endDate: Date
    $counselingRoomId: ID
    $latest: Boolean
  ) {
    reservations(
      startDate: $startDate
      endDate: $endDate
      counselingRoomId: $counselingRoomId
      latest: $latest
    ) {
      id
      applicant
      phoneNumber
      division
      date
      time
      membership
      paymentDate
    }
  }
`;

const UPDATE_RESERVATION_PAYMENTDATE = gql`
  mutation updateReservationPaymentDate($id: ID!, $paymentDate: Date!) {
    updateReservationPaymentDate(id: $id, paymentDate: $paymentDate) {
      success
      message
    }
  }
`;

export default function ConsoleReservationPage() {
  const [selectCalendar, setSelectCalendar] = useState(new Date());
  const [counselingRoomType, setCounselingRoomType] = useState(0);
  const [division, setDivision] = useState("");
  const [counselingRoomArray, setCounselingRoomArray] = useState([]);
  const [counselingRoomId, setCounselingRoomId] = useState("");
  const [findMORNING, setFindMORNING] = useState();
  const [findAFTERNOON, setFindAFTERNOON] = useState();
  const [findNIGHT, setFindNIGHT] = useState();
  const { data } = useQuery(COUNSELING_ROOMS, {
    fetchPolicy: "network-only",
    variables: {
      division,
    },
  });

  const { refetch } = useQuery(RESERVATIONS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      startDate: moment(selectCalendar).format("YYYY-MM-DD"),
      endDate: moment(selectCalendar).format("YYYY-MM-DD"),
      counselingRoomId,
      latest: true,
    },
    onCompleted({ reservations }) {
      setFindMORNING(
        reservations.find((item) => item.time === "MORNING" && counselingRoomId)
      );
      setFindAFTERNOON(
        reservations.find(
          (item) => item.time === "AFTERNOON" && counselingRoomId
        )
      );
      setFindNIGHT(
        reservations.find((item) => item.time === "NIGHT" && counselingRoomId)
      );
    },
  });
  useEffect(() => {
    if (data) {
      data.counselingRooms.map((item, index) => {
        delete item.__typename;
        counselingRoomArray.push({ id: item.id, name: item.name });
      });
      setCounselingRoomArray((e) => [...e]);
    }
  }, [data]);
  useEffect(() => {
    if (counselingRoomArray[0]) {
      let counselingRoomItem = counselingRoomArray.map((item, index) => {
        return index;
      });
      let counselingRoom = counselingRoomItem.indexOf(counselingRoomType);
      setCounselingRoomId(counselingRoomArray[counselingRoom].id);
    }
  }, [data, counselingRoomType]);
  return (
    <WebLayout title={"會員諮商室預約"}>
      <Grid container spacing={1}>
        <Grid container item>
          <Card style={{ width: "100%" }}>
            <CardContent
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Typography gutterBottom>選擇地區</Typography>
              <Select
                value={division}
                onChange={(e) => setDivision(e.target.value)}
                variant="outlined"
                fullWidth
                displayEmpty
              >
                <MenuItem value="" disabled>
                  請選擇
                </MenuItem>
                {Object.keys(divisions)
                  .filter((eItem) => eItem === "TAIPEI_CITY")
                  .map((item) => (
                    <MenuItem value={item}>{divisions[item].zhTW}</MenuItem>
                  ))}
              </Select>
            </CardContent>
          </Card>
        </Grid>
        <Grid container item>
          <Card style={{ width: "100%" }}>
            <Tabs
              value={counselingRoomType}
              onChange={(e, n) => setCounselingRoomType(n)}
            >
              {counselingRoomArray.map((item) => (
                <Tab label={item.name} />
              ))}
            </Tabs>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <Card style={{ width: "100%" }}>
            <CardContent>
              <Calendar date={selectCalendar} onChange={setSelectCalendar} />
            </CardContent>
          </Card>
        </Grid>
        <Grid container item xs={12} sm={12} md={6}>
          {findMORNING && (
            <ReservationItem
              data={findMORNING}
              type="MORNING"
              onRefetch={refetch}
            />
          )}
          {findAFTERNOON && (
            <ReservationItem
              data={findAFTERNOON}
              type="AFTERNOON"
              onRefetch={refetch}
            />
          )}
          {findNIGHT && (
            <ReservationItem
              data={findNIGHT}
              type="NIGHT"
              onRefetch={refetch}
            />
          )}
        </Grid>
      </Grid>
    </WebLayout>
  );
}
function ReservationItem({ data, type, onChange = () => {}, onRefetch }) {
  const Alert = useAlert();
  const theme = useTheme();
  const [payDay, setPayDay] = useState(undefined);
  function switchTimeType() {
    switch (type) {
      case "MORNING":
        return <Typography align="center">10:00&ensp;~&ensp;13:00</Typography>;
      case "AFTERNOON":
        return <Typography align="center">14:00&ensp;~&ensp;17:00</Typography>;
      case "NIGHT":
        return <Typography align="center">18:00&ensp;~&ensp;21:00</Typography>;
      default:
        break;
    }
  }
  useEffect(() => {
    if (data) {
      setPayDay(data.paymentDate);
    }
  }, [data]);
  function determine() {
    updateReservationPaymentDate();
  }
  const [updateReservationPaymentDate, { loading }] = useMutation(
    UPDATE_RESERVATION_PAYMENTDATE,
    {
      variables: {
        id: data.id,
        paymentDate: moment(payDay).format("YYYY-MM-DD"),
      },
      onCompleted({ updateReservationPaymentDate }) {
        if (updateReservationPaymentDate.success) {
          Alert.alert("", "付款日期更改成功", [
            {
              text: "確定",
              onClick: () => {
                onRefetch();
              },
              type: "ok",
            },
          ]);
        } else {
          Alert.alert("", "儲存失敗，請重新輸入後再次嘗試。", [
            { text: "確定", type: "ok" },
          ]);
        }
      },
      onError(error) {
        Alert.alert("", `${error}`, [
          {
            text: "確定",
            type: "ok",
          },
        ]);
      },
    }
  );
  return (
    <Grid container item style={{ marginBottom: theme.spacing(1) }}>
      <Card style={{ width: "100%" }}>
        <CardContent
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          {switchTimeType()}
        </CardContent>
        <CardContent style={{ display: "flex", flexDirection: "column" }}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6} md={12} lg={6}>
              <Typography gutterBottom>預約人姓名</Typography>
              <Typography gutterBottom variant="body2">
                {data.applicant}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={12} lg={6}>
              <Typography gutterBottom>預約人電話</Typography>
              <Typography gutterBottom variant="body2">
                {data.phoneNumber}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={12} lg={6}>
              <Typography gutterBottom>是否為催眠師會員</Typography>
              <Typography gutterBottom variant="body2">
                {data.membership ? "是" : "否"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6} md={12} lg={6}>
              <Typography gutterBottom>付款日期</Typography>
              {payDay ? (
                <Box display="flex">
                  <DatePicker
                    value={payDay}
                    onChange={setPayDay}
                    inputVariant="outlined"
                    size="small"
                    style={{ flex: 1 }}
                    disabled={Boolean(data.paymentDate)}
                  />
                  {!data.paymentDate && (
                    <Button onClick={() => setPayDay(undefined)}>移除</Button>
                  )}
                </Box>
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setPayDay(moment())}
                >
                  新增付款日期
                </Button>
              )}
            </Grid>
            {payDay && !data.paymentDate && (
              <Grid item xs={12} sm={6} md={12} lg={6}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={determine}
                >
                  確定付款日期
                </Button>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
    </Grid>
  );
}
