import React, { useState, useEffect } from "react";

import WebLayout from "../components/WebLayout";
import {
  Box,
  Grid,
  CardContent,
  Typography,
  ButtonBase,
  makeStyles,
  useTheme,
  Dialog,
  DialogActions,
  DialogContent,
  InputBase,
  DialogTitle,
  CircularProgress,
} from "@material-ui/core";
import MovieIcon from "@material-ui/icons/Movie";
import AspectRatioBox from "../components/AspectRatioBox";
import CardBox from "../components/CardBox";
import { AddButton, CancelButton, ConfirmButton } from "../components/Buttons";
import { useHistory } from "react-router-dom";
import { useAlert } from "./../components/Alert";
import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

const GET_ALBUMS = gql`
  query GetAlbums($latest: Boolean) {
    albums(latest: $latest) {
      id
      name
      coverIndex
      files {
        filename
        mimetype
        encoding
        location
      }
      videos {
        url
      }
    }
  }
`;

const CREATE_ALBUM = gql`
  mutation CreateAlbum($name: String!) {
    createAlbum(name: $name) {
      id
      name
      coverIndex
      files {
        filename
        mimetype
        encoding
        location
      }
      videos {
        url
      }
    }
  }
`;

export default function ConsoleAlbumPage() {
  const Alert = useAlert();
  const theme = useTheme();
  const [openAddAlbum, setOpenAddAlbum] = useState(false);
  const [albumArray, setAlbumArray] = useState([]);
  const [nowDate, setNowDate] = useState(null); //用來刷新畫面
  const [newAlbumName, setNewAlbumName] = useState("");
  const { loading } = useQuery(GET_ALBUMS, {
    fetchPolicy: "network-only",
    variables: { latest: false },
    onCompleted({ albums }) {
      if (albums) {
        albums.map((albumsItem, albumsIndex) => {
          albumArray.push({
            id: albumsItem.id,
            title: albumsItem.name,
            cover:
              albumsItem.files[0] &&
              (albumsItem.coverIndex || albumsItem.coverIndex == 0) &&
              albumsItem.files[albumsItem.coverIndex]
                ? albumsItem.files[albumsItem.coverIndex].location
                : require("../images/backgroundImage.jpg"),
          });
        });
        setAlbumArray((e) => [...e]);
      }
    },
  });
  //新增跳到最下方
  useEffect(() => {
    nowDate && _scrollBottom();
  }, [nowDate]);
  function _scrollBottom() {
    let anchor = document.body;
    anchor.scrollIntoView({
      block: "end",
      inline: "nearest",
      behavior: "smooth",
    });
  }
  function _DeleteAlbum(index) {
    let oldArray = albumArray.slice();
    oldArray.splice(index, 1);
    setAlbumArray([...oldArray]);
  }
  function _addAlbum() {
    addAlbum();
  }
  const [addAlbum] = useMutation(CREATE_ALBUM, {
    variables: {
      name: newAlbumName,
    },
    onCompleted({ createAlbum }) {
      if (createAlbum) {
        Alert.alert("", "儲存成功", [
          {
            text: "確定",
            onClick: () => {
              setAlbumArray((e) => [
                ...e,
                {
                  id: createAlbum.id,
                  title: createAlbum.name,
                  cover: require("../images/backgroundImage.jpg"),
                },
              ]);
            },
            type: "ok",
          },
        ]);
      } else {
        Alert.alert("", "儲存失敗，請重新輸入後再次嘗試。", [
          { text: "確定", type: "ok" },
        ]);
      }
    },
    onError(error) {
      Alert.alert("", `${error}`, [
        {
          text: "確定",
          type: "ok",
        },
      ]);
    },
  });
  return (
    <WebLayout title={"活動花絮"}>
      {loading ? (
        <Grid container justify="center">
          <CircularProgress color="secondary" />
        </Grid>
      ) : (
        <>
          <Grid container>
            <CardBox
              title={"相簿"}
              style={{ flex: 1, margin: `${theme.spacing(1)}px 0` }}
            >
              <Grid container spacing={2}>
                {albumArray.map((item, index) => (
                  <AlbumItem data={item} onDelete={() => _DeleteAlbum(index)} />
                ))}
              </Grid>
            </CardBox>
          </Grid>
          <AddButton
            title="相簿"
            style={{
              position: "sticky",
              bottom: theme.spacing(1),
              right: theme.spacing(1),
              alignSelf: "flex-end",
            }}
            onClick={() => setOpenAddAlbum(true)}
          />
          <AddAlbum
            open={openAddAlbum}
            onClose={() => setOpenAddAlbum(false)}
            onAddAlbum={_addAlbum}
            newAlbumName={newAlbumName}
            onChangeAlbum={(e) => setNewAlbumName(e)}
          />
        </>
      )}
    </WebLayout>
  );
}

function AlbumItem({ data }) {
  const history = useHistory();
  const useStyles = makeStyles({
    TouchBox: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      alignItems: "center",
    },
    TitleText: {
      height: "3rem",
      textAlign: "center",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
    },
  });
  const classes = useStyles();
  return (
    <Grid container item xs={6} sm={3} md={2}>
      <ButtonBase
        classes={{ root: classes.TouchBox }}
        onClick={() =>
          history.push(`/console/album/${data.id}`, { data: data })
        }
      >
        {data.cover.split(".jpg").length > 1 ||
        data.cover.split(".png").length > 1 ? (
          <Box width="inherit" maxWidth={150}>
            <AspectRatioBox>
              <img src={data.cover} />
            </AspectRatioBox>
          </Box>
        ) : (
          <Box width="inherit" maxWidth={150}>
            <AspectRatioBox>
              <MovieIcon
                fontSize="large"
                style={{ display: "flex", flex: 1 }}
              />
            </AspectRatioBox>
          </Box>
        )}
        <CardContent>
          <Typography classes={{ root: classes.TitleText }}>
            {data.title}
          </Typography>
        </CardContent>
      </ButtonBase>
    </Grid>
  );
}

function AddAlbum({
  open,
  onClose = () => {},
  onAddAlbum = () => {},
  newAlbumName,
  onChangeAlbum,
}) {
  return (
    <Dialog open={open} onClose={onClose} disableScrollLock>
      <DialogTitle>新增相簿</DialogTitle>
      <DialogContent>
        <InputBase
          placeholder="相簿名稱"
          value={newAlbumName}
          onChange={(e) => onChangeAlbum(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={onClose} />
        <ConfirmButton
          disabled={newAlbumName.length == 0}
          onClick={() => {
            onAddAlbum(newAlbumName);
            onClose();
          }}
        />
      </DialogActions>
    </Dialog>
  );
}
