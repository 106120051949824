import React from "react";
import { Grid, makeStyles, useTheme } from "@material-ui/core";

export default function GridLayout({ spacing = 0, children }) {
  const theme = useTheme();
  const useStyles = makeStyles({
    GridRoot: {
      "& > *": {
        margin: `${theme.spacing(spacing)}px 0`,
      },
    },
  });
  const classes = useStyles();
  return (
    <Grid container classes={{ root: classes.GridRoot }}>
      {children}
    </Grid>
  );
}
