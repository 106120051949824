import React from "react";

import { Box, CircularProgress } from "@material-ui/core";

export default function LoadingBox() {
  return (
    <Box display="flex" flex={1} justifyContent="center" alignItems="center">
      <CircularProgress />
    </Box>
  );
}
