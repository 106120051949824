import React, { useState } from "react";
import {
  Grid,
  useTheme,
  makeStyles,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import { useHistory, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import WebLayout from "../components/WebLayout";
import HypnotistEdit from "../components/HypnotistEdit";
import { useAlert } from "./../components/Alert";

const GET_HYPNOTIST = gql`
  query GetHypnotist($id: ID!) {
    hypnotist(id: $id) {
      id
      picture {
        filename
        mimetype
        encoding
        location
      }
      email
      address
      line
      facebook
      name
      content
    }
  }
`;

const UPDATE_HYPNOTIST = gql`
  mutation UpdateHypnotist($id: ID!, $input: HypnotistInput!) {
    updateHypnotist(id: $id, input: $input) {
      success
      message
    }
  }
`;

export default function ConsoleHypnotistPage() {
  const Alert = useAlert();
  const history = useHistory();
  const { id } = useParams();
  const theme = useTheme();
  const useStyles = makeStyles({
    Loading: {
      zIndex: theme.zIndex.drawer + 1,
      color: "white",
    },
  });
  const classes = useStyles();
  const [random, setRandom] = useState(0);
  const [hypnotistInput, setHypnotistInput] = useState({
    picture: null,
    email: null,
    address: null,
    line: null,
    facebook: null,
    name: null,
    content: null,
  });
  const { loading } = useQuery(GET_HYPNOTIST, {
    fetchPolicy: "network-only",
    variables: { id },
    onCompleted({ hypnotist }) {
      delete hypnotist.picture.__typename;
      setHypnotistInput({
        picture: hypnotist.picture,
        email: hypnotist.email,
        address: hypnotist.address,
        line: hypnotist.line,
        facebook: hypnotist.facebook,
        name: hypnotist.name,
        content: hypnotist.content,
      });
    },
  });
  function createHypnotist() {
    _createHypnotist();
  }
  const [_createHypnotist, { loading: editHypnotistLoading }] = useMutation(
    UPDATE_HYPNOTIST,
    {
      variables: {
        id,
        input: hypnotistInput,
      },
      onCompleted({ updateHypnotist }) {
        if (updateHypnotist) {
          Alert.alert("", "儲存成功", [
            {
              text: "確定",
              onClick: () => {
                history.goBack();
              },
              type: "ok",
            },
          ]);
        } else {
          Alert.alert("", "儲存失敗，請重新輸入後再次嘗試。", [
            { text: "確定", type: "ok" },
          ]);
        }
      },
      onError(error) {
        Alert.alert("", `${error}`, [
          {
            text: "確定",
            type: "ok",
          },
        ]);
      },
    }
  );
  return (
    <WebLayout title={"尋找催眠師"} onUpdate={() => createHypnotist()}>
      {loading ? (
        <Grid container justify="center">
          <CircularProgress color="secondary" />
        </Grid>
      ) : (
        <>
          <Backdrop
            open={editHypnotistLoading}
            classes={{ root: classes.Loading }}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <HypnotistEdit
            hypnotists={hypnotistInput}
            onChangeData={(e) => {
              if (e.type == "uploadImage") {
                hypnotistInput.picture = {
                  filename: e.value.filename,
                  mimetype: e.value.mimetype,
                  encoding: e.value.encoding,
                  location: e.value.location,
                };
              }
              if (e.type == "deleteImage") {
                hypnotistInput.picture = null;
              }
              if (e.type == "email") {
                hypnotistInput.email = e.value;
              }
              if (e.type == "address") {
                hypnotistInput.address = e.value;
              }
              if (e.type == "line") {
                hypnotistInput.line = e.value;
              }
              if (e.type == "facebook") {
                hypnotistInput.facebook = e.value;
              }
              if (e.type == "name") {
                hypnotistInput.name = e.value;
              }
              if (e.type == "content") {
                hypnotistInput.content = e.value;
              }
              setRandom(random + 1);
            }}
          />
        </>
      )}
    </WebLayout>
  );
}
