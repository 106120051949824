import React, { useState } from "react";
import { useQuery, useMutation, useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import WebLayout from "../components/WebLayout";
import { Grid, InputBase, useTheme } from "@material-ui/core";
import CardBox from "../components/CardBox";
import LoadingBox from "../components/LoadingBox";
import { useAlert } from "./../components/Alert";

const GET_MEMBER_CONTENT = gql`
  query GetMemberContent($name: String!) {
    content(name: $name) {
      body
    }
  }
`;

const SET_MEMBER_CONTENT = gql`
  mutation SetMemberContent($name: String!, $body: String!) {
    setContent(name: $name, body: $body) {
      success
      message
    }
  }
`;

export default function ConsoleMemberPage() {
  const Alert = useAlert();
  let [title, setTitle] = useState("");
  const [renderLoading, setRenderLoading] = useState(true);
  useQuery(GET_MEMBER_CONTENT, {
    fetchPolicy: "network-only",
    variables: { name: "MEMBER_TITLE" },
    onCompleted({ content }) {
      if (content && content.body) {
        setTitle(content.body);
      }
      getContent2();
    },
  });
  let [content, setContent] = useState();
  const [getContent2] = useLazyQuery(GET_MEMBER_CONTENT, {
    fetchPolicy: "network-only",
    variables: { name: "MEMBER_CONTENT" },
    onCompleted({ content }) {
      if (content && content.body) {
        setContent(content.body);
      }
      getContent3();
    },
  });
  let [paymentRules, setPaymentRules] = useState();
  const [getContent3] = useLazyQuery(GET_MEMBER_CONTENT, {
    fetchPolicy: "network-only",
    variables: { name: "MEMBER_PAYMENT_RULES" },
    onCompleted({ content }) {
      if (content && content.body) {
        setPaymentRules(content.body);
      }
      getContent4();
    },
  });
  let [sponsorRules, setSponsorRules] = useState();
  const [getContent4] = useLazyQuery(GET_MEMBER_CONTENT, {
    fetchPolicy: "network-only",
    variables: { name: "MEMBER_SPONSOR_RULES" },
    onCompleted({ content }) {
      if (content && content.body) {
        setSponsorRules(content.body);
      }
      getContent5();
    },
  });
  let [reminder, setReminder] = useState();
  const [getContent5] = useLazyQuery(GET_MEMBER_CONTENT, {
    fetchPolicy: "network-only",
    variables: { name: "MEMBER_REMINDER" },
    onCompleted({ content }) {
      if (content && content.body) {
        setReminder(content.body);
      }
      setRenderLoading(false);
    },
  });
  const theme = useTheme();
  const cardSize = { flex: 1, margin: theme.spacing(0.5) };
  const [setMemberContent] = useMutation(SET_MEMBER_CONTENT);
  const readyToUpdate = !!(
    title &&
    content &&
    paymentRules &&
    sponsorRules &&
    reminder
  );

  async function submitUpdates() {
    const allVariables = [
      {
        name: "MEMBER_TITLE",
        body: title,
      },
      {
        name: "MEMBER_CONTENT",
        body: content,
      },
      {
        name: "MEMBER_PAYMENT_RULES",
        body: paymentRules,
      },
      {
        name: "MEMBER_SPONSOR_RULES",
        body: sponsorRules,
      },
      {
        name: "MEMBER_REMINDER",
        body: reminder,
      },
    ];

    for (let variables of allVariables) {
      const { data } = await setMemberContent({ variables });

      if (!data.setContent.success) {
        Alert.alert("", `${data.setContent.message}`, [
          {
            text: "確定",
            type: "ok",
          },
        ]);
        return;
      }
    }

    Alert.alert("", `更新完成！`, [
      {
        text: "確定",
        type: "ok",
      },
    ]);
  }

  return (
    <WebLayout title={"會員資訊"} onUpdate={readyToUpdate && submitUpdates}>
      {readyToUpdate || !renderLoading ? (
        <Grid container>
          <Grid container item direction="column" xs={12} sm={6}>
            <Grid item>
              <CardBox title="標題" style={cardSize}>
                <InputBase
                  placeholder="請輸入標題名稱"
                  fullWidth
                  value={title}
                  onChange={(event) => setTitle(event.target.value)}
                />
              </CardBox>
            </Grid>
            <CardBox title="文案內容" style={cardSize}>
              <InputBase
                placeholder="請輸入文案內容"
                rows={10}
                fullWidth
                multiline
                value={content}
                onChange={(event) => setContent(event.target.value)}
              />
            </CardBox>
          </Grid>
          <Grid container item direction="column" xs={12} sm={6}>
            <Grid item>
              <CardBox title="會費說明" style={cardSize}>
                <InputBase
                  placeholder="請輸入會費說明"
                  rows={3}
                  multiline
                  fullWidth
                  value={paymentRules}
                  onChange={(event) => setPaymentRules(event.target.value)}
                />
              </CardBox>
            </Grid>
            <Grid item>
              <CardBox title="會員說明" style={cardSize}>
                <InputBase
                  placeholder="請輸入會員說明"
                  rows={3}
                  multiline
                  fullWidth
                  value={sponsorRules}
                  onChange={(event) => setSponsorRules(event.target.value)}
                />
              </CardBox>
            </Grid>
            <Grid item>
              <CardBox title="溫馨提醒" style={cardSize}>
                <InputBase
                  placeholder="請輸入溫馨提醒"
                  rows={3}
                  multiline
                  fullWidth
                  value={reminder}
                  onChange={(event) => setReminder(event.target.value)}
                />
              </CardBox>
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <LoadingBox />
      )}
    </WebLayout>
  );
}
