import React, { useState, useEffect, useReducer, useRef } from "react";

import WebLayout from "../components/WebLayout";
import {
  Box,
  Grid,
  Typography,
  ButtonBase,
  makeStyles,
  Menu,
  MenuItem,
  useTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputBase,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useHistory, useParams } from "react-router-dom";
import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { useAlert } from "./../components/Alert";
import AspectRatioBox from "../components/AspectRatioBox";
import CardBox from "../components/CardBox";
import {
  CancelButton,
  ConfirmButton,
  DeleteButton,
} from "../components/Buttons";

const GET_ALBUM = gql`
  query album($id: ID!) {
    album(id: $id) {
      id
      name
      coverIndex
      files {
        filename
        mimetype
        encoding
        location
      }
      videos {
        url
      }
    }
  }
`;

const ADD_FILESTOALBUM = gql`
  mutation addFilesToAlbum($id: ID!, $files: [FileInput]!) {
    addFilesToAlbum(id: $id, files: $files) {
      success
    }
  }
`;

const ADD_VIDEOSTOALBUM = gql`
  mutation addVideosToAlbum($id: ID!, $videoUrls: [String]!) {
    addVideosToAlbum(id: $id, videoUrls: $videoUrls) {
      success
    }
  }
`;

const REMOVE_FILESFROMALBUM = gql`
  mutation removeFilesFromAlbum($id: ID!, $fileIndexes: [Int]!) {
    removeFilesFromAlbum(id: $id, fileIndexes: $fileIndexes) {
      success
    }
  }
`;

const REMOVE_VIDEOSFROMALBUM = gql`
  mutation removeVideosFromAlbum($id: ID!, $videoUrls: [String]!) {
    removeVideosFromAlbum(id: $id, videoUrls: $videoUrls) {
      success
    }
  }
`;

const RENAME_ALBUM = gql`
  mutation renameAlbum($id: ID!, $name: String!) {
    renameAlbum(id: $id, name: $name) {
      success
    }
  }
`;

const REMOVE_ALBUM = gql`
  mutation removeAlbum($id: ID!) {
    removeAlbum(id: $id) {
      success
    }
  }
`;

const CHOOSE_ALBUMCOVER = gql`
  mutation chooseAlbumCover($id: ID!, $fileIndex: Int!) {
    chooseAlbumCover(id: $id, fileIndex: $fileIndex) {
      success
    }
  }
`;

const UPLOAD_IMAGE = gql`
  mutation UploadImage($image: Upload!) {
    uploadImage(image: $image) {
      filename
      mimetype
      encoding
      location
    }
  }
`;

const UPLOAD_FILE = gql`
  mutation UploadFile($file: Upload!) {
    uploadFile(file: $file) {
      filename
      mimetype
      encoding
      location
    }
  }
`;

export default function ConsoleAlbumPicturePage() {
  const Alert = useAlert();
  const history = useHistory();
  const { id } = useParams();
  const theme = useTheme();
  const [editType, editTypeDispatch] = useReducer(_editTypeDispatch, {
    type: null,
  });
  const useStyles = makeStyles({
    Loading: {
      zIndex: theme.zIndex.drawer + 1,
      color: "white",
    },
    Button: {
      backgroundColor: theme.palette.secondary.main,
      "&:hover": {
        backgroundColor: theme.palette.secondary.dark,
      },
      position: "sticky",
      zIndex: theme.zIndex.drawer,
      bottom: theme.spacing(1),
      right: theme.spacing(1),
      alignSelf: "flex-end",
      height: 40,
      width: 40,
      borderRadius: 20,
      color: "white",
    },
  });
  const classes = useStyles();
  function _editTypeDispatch(state, action) {
    switch (action.type) {
      case "刪除檔案":
        return { type: "MultipleDelete" };
      case "選擇封面":
        return { type: "SingleChose" };
      case "取消":
        return { type: null };
      default:
        break;
    }
  }
  const [albumArray, setAlbumArray] = useState([]);
  const [albumName, setAlbumName] = useState("");
  const [newAlbumName, setNewAlbumName] = useState("");
  const [addPhotoArray, setAddPhotoArray] = useState([]);
  const [deletePhotoArray, setDeletePhotoArray] = useState([]);
  const [deleteVideoArray, setDeleteVideoArray] = useState([]);
  const [nowDate, setNowDate] = useState(null); //用來刷新畫面
  const [videoUrl, setVideoUrl] = useState(null);
  const [openAddVideo, setOpenAddVideo] = useState(false);
  const [openRenameAlbum, setOpenRenameAlbum] = useState(false);
  const [coverSelect, setCoverSelect] = useState(null); //封面圖是幾號
  const [newCoverSelect, setNewCoverSelect] = useState(null);
  const [readyDeleteAlbum, setReadyDeleteAlbum] = useState(false);
  const [renderFirst, setRenderFirst] = useState(false);
  const deleteFileRef = useRef("");
  //新增跳到最下方
  useEffect(() => {
    nowDate && _scrollBottom();
  }, [nowDate]);
  function _scrollBottom() {
    let anchor = document.body;
    anchor.scrollIntoView({
      block: "end",
      inline: "nearest",
      behavior: "smooth",
    });
  }
  useEffect(() => {
    if (renderFirst && Boolean(videoUrl)) {
      _uploadAddVideo();
    }
  }, [videoUrl]);
  useEffect(() => {
    if (renderFirst && Boolean(newAlbumName)) {
      _renameAlbum();
    }
  }, [newAlbumName]);
  useEffect(() => {
    if (renderFirst && Boolean(coverSelect || coverSelect === 0)) {
      _chooseAlbumCover();
    }
  }, [coverSelect]);
  const { loading, refetch } = useQuery(GET_ALBUM, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: { id },
    onCompleted({ album }) {
      const newAlbumArray = [];

      album.files.map((albumItem, albumIndex) => {
        newAlbumArray.push({
          id: albumIndex,
          photo: albumItem.location,
          name: albumItem.filename,
        });
      });
      album.videos.map((videoItem) => {
        let newVideoUrl = null;

        const videoPCUrl = videoItem.url.startsWith(
          "https://www.youtube.com/watch?v="
        );
        const videoMobileUrl = videoItem.url.startsWith("https://youtu.be/");

        if (videoPCUrl) {
          newVideoUrl = videoItem.url.replaceAll(
            "https://www.youtube.com/watch?v=",
            ""
          );
          newAlbumArray.push({
            url: newVideoUrl,
            type: "PC",
          });
        } else if (videoMobileUrl) {
          newVideoUrl = videoItem.url.replaceAll("https://youtu.be/", "");
          newAlbumArray.push({
            url: newVideoUrl,
            type: "Mobile",
          });
        }
      });
      setAlbumArray(newAlbumArray);
      setAlbumName(album.name);
      setRenderFirst(true);
    },
  });
  const [_onAddPhoto, { loading: onAddPhotoLoading }] = useMutation(
    UPLOAD_IMAGE,
    {
      onCompleted({ uploadImage }) {
        delete uploadImage.__typename;
        setAddPhotoArray((e) => [
          ...e,
          {
            filename: uploadImage.filename,
            mimetype: uploadImage.mimetype,
            encoding: uploadImage.encoding,
            location: uploadImage.location,
          },
        ]);
      },
    }
  );
  const [_uploadAddPhoto, { loading: addFilesToAlbumLoading }] = useMutation(
    ADD_FILESTOALBUM,
    {
      variables: {
        id,
        files: addPhotoArray,
      },
      onCompleted({ addFilesToAlbum }) {
        if (addFilesToAlbum.success) {
          setAddPhotoArray([]);
          refetch();
          Alert.alert("", "新增圖片成功", [
            {
              text: "確定",
              type: "ok",
            },
          ]);
        } else {
          Alert.alert("", "新增圖片失敗，請重新輸入後再次嘗試。", [
            { text: "確定", type: "ok" },
          ]);
        }
      },
      onError(error) {
        Alert.alert("", `${error}`, [
          {
            text: "確定",
            type: "ok",
          },
        ]);
      },
    }
  );

  const [
    _DeleteFilePhoto,
    { loading: removeFilesFromAlbumLoading },
  ] = useMutation(REMOVE_FILESFROMALBUM, {
    variables: {
      id,
      fileIndexes: deletePhotoArray,
    },
    onCompleted({ removeFilesFromAlbum }) {
      if (removeFilesFromAlbum.success) {
        if (deleteFileRef.current === "photo&video") {
          _DeleteFileVideo();
        } else if (deleteFileRef.current === "photo") {
          Alert.alert("", "刪除成功", [
            {
              text: "確定",
              type: "ok",
            },
          ]);
          refetch();
        }
        deleteFileRef.current = "";
        setDeletePhotoArray([]);
      } else {
        Alert.alert("", "刪除失敗，請重新輸入後再次嘗試。", [
          { text: "確定", type: "ok" },
        ]);
      }
    },
    onError(error) {
      Alert.alert("", `${error}`, [
        {
          text: "確定",
          type: "ok",
        },
      ]);
    },
  });
  const [_uploadAddVideo, { loading: addVideosToAlbumLoading }] = useMutation(
    ADD_VIDEOSTOALBUM,
    {
      variables: {
        id,
        videoUrls: [videoUrl],
      },
      onCompleted({ addVideosToAlbum }) {
        if (addVideosToAlbum.success) {
          setVideoUrl(undefined);
          refetch();
          Alert.alert("", "新增影片成功", [
            {
              text: "確定",
              type: "ok",
            },
          ]);
        } else {
          Alert.alert("", "新增影片失敗，請重新輸入後再次嘗試。", [
            { text: "確定", type: "ok" },
          ]);
        }
      },
      onError(error) {
        Alert.alert("", `${error}`, [
          {
            text: "確定",
            type: "ok",
          },
        ]);
      },
    }
  );

  const [
    _DeleteFileVideo,
    { loading: removeVideosFromAlbumLoading },
  ] = useMutation(REMOVE_VIDEOSFROMALBUM, {
    variables: {
      id,
      videoUrls: deleteVideoArray,
    },
    onCompleted({ removeVideosFromAlbum }) {
      if (removeVideosFromAlbum.success) {
        deleteFileRef.current = "";
        setDeleteVideoArray([]);
        refetch();
        Alert.alert("", "刪除成功", [
          {
            text: "確定",
            type: "ok",
          },
        ]);
      } else {
        Alert.alert("", "刪除失敗，請重新輸入後再次嘗試。", [
          { text: "確定", type: "ok" },
        ]);
      }
    },
    onError(error) {
      Alert.alert("", `${error}`, [
        {
          text: "確定",
          type: "ok",
        },
      ]);
    },
  });
  const [_renameAlbum, { loading: renameAlbumLoading }] = useMutation(
    RENAME_ALBUM,
    {
      variables: {
        id,
        name: newAlbumName,
      },
      onCompleted({ renameAlbum }) {
        if (renameAlbum.success) {
          setNewAlbumName("");
          refetch();
          Alert.alert("", "更改相簿名稱成功", [
            {
              text: "確定",
              type: "ok",
            },
          ]);
        } else {
          Alert.alert("", "更改相簿名稱失敗，請重新輸入後再次嘗試。", [
            { text: "確定", type: "ok" },
          ]);
        }
      },
      onError(error) {
        Alert.alert("", `${error}`, [
          {
            text: "確定",
            type: "ok",
          },
        ]);
      },
    }
  );
  const [_removeAlbum, { loading: removeAlbumLoading }] = useMutation(
    REMOVE_ALBUM,
    {
      variables: {
        id,
      },
      onCompleted({ removeAlbum }) {
        if (removeAlbum.success) {
          history.goBack();
        } else {
          Alert.alert("", "更改相簿名稱失敗，請重新輸入後再次嘗試。", [
            { text: "確定", type: "ok" },
          ]);
        }
      },
      onError(error) {
        Alert.alert("", `${error}`, [
          {
            text: "確定",
            type: "ok",
          },
        ]);
      },
    }
  );

  const [_chooseAlbumCover, { loading: chooseAlbumCoverLoading }] = useMutation(
    CHOOSE_ALBUMCOVER,
    {
      variables: {
        id,
        fileIndex: coverSelect,
      },
      onCompleted({ chooseAlbumCover }) {
        if (chooseAlbumCover.success) {
          setCoverSelect(undefined);
          setNewCoverSelect(undefined);
          editTypeDispatch({ type: "取消" });
          Alert.alert("", "封面更改成功", [
            {
              text: "確定",
              type: "ok",
            },
          ]);
        } else {
          Alert.alert("", "封面更改失敗，請重新輸入後再次嘗試。", [
            { text: "確定", type: "ok" },
          ]);
        }
      },
      onError(error) {
        Alert.alert("", `${error}`, [
          {
            text: "確定",
            type: "ok",
          },
        ]);
      },
    }
  );

  function _onAddVideo() {
    setOpenAddVideo(true);
    setNowDate(Date.now()); //解決畫面滾動問題
  }
  function _onDeleteFile() {
    editTypeDispatch({ type: "刪除檔案" });
  }
  function _DeleteFile() {
    if (
      Boolean(deletePhotoArray[0] || deletePhotoArray[0] === 0) &&
      Boolean(deleteVideoArray[0] || deleteVideoArray[0] === 0)
    ) {
      deleteFileRef.current = "photo&video";
      _DeleteFilePhoto();
    } else if (Boolean(deletePhotoArray[0] || deletePhotoArray[0] === 0)) {
      deleteFileRef.current = "photo";
      _DeleteFilePhoto();
    } else if (Boolean(deleteVideoArray[0] || deleteVideoArray[0] === 0)) {
      deleteFileRef.current = "video";
      _DeleteFileVideo();
    }
    editTypeDispatch({ type: "取消" });
  }
  function _onRenameAlbum() {
    setOpenRenameAlbum(true);
  }
  function _onDeleteAlbum() {
    setReadyDeleteAlbum(true);
  }
  function _onChoseCover() {
    editTypeDispatch({ type: "選擇封面" });
  }
  function _choseCover() {
    setCoverSelect(newCoverSelect);
  }
  return (
    <WebLayout title={"活動花絮編輯照片"}>
      <Backdrop
        open={
          addFilesToAlbumLoading ||
          removeFilesFromAlbumLoading ||
          addVideosToAlbumLoading ||
          removeVideosFromAlbumLoading ||
          renameAlbumLoading ||
          removeAlbumLoading ||
          chooseAlbumCoverLoading ||
          onAddPhotoLoading
        }
        classes={{ root: classes.Loading }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container>
        {loading ? (
          <Grid container justify="center">
            <CircularProgress color="secondary" />
          </Grid>
        ) : (
          <CardBox
            title={albumName}
            style={{ flex: 1, margin: `${theme.spacing(1)}px 0` }}
          >
            <Grid container spacing={2} style={{ minHeight: 200 }}>
              {!readyDeleteAlbum &&
                albumArray.map((item, index) => (
                  <AlbumItem
                    data={item}
                    type={editType.type}
                    onCoverSelect={(e) => {
                      if (e.id || e.id === 0) {
                        setNewCoverSelect(index);
                      } else {
                        Alert.alert("", "影片不可為封面！", [
                          {
                            text: "確定",
                            type: "ok",
                          },
                        ]);
                      }
                    }}
                    coverSelect={
                      typeof newCoverSelect == "number"
                        ? index == newCoverSelect
                        : index == coverSelect
                    }
                    onFilesSelect={(e) => {
                      if (e.url) {
                        const has = deleteVideoArray.find(
                          (item) =>
                            item ===
                              `https://www.youtube.com/watch?v=${e.url}` ||
                            item === `https://youtu.be/${e.url}`
                        );

                        if (has) {
                          deleteVideoArray.map((item, index) => {
                            if (
                              item ===
                                `https://www.youtube.com/watch?v=${e.url}` ||
                              item === `https://youtu.be/${e.url}`
                            ) {
                              deleteVideoArray.splice(index, 1);
                            }
                          });
                        } else {
                          if (e.type === "PC") {
                            deleteVideoArray.push(
                              `https://www.youtube.com/watch?v=${e.url}`
                            );
                          } else if (e.type === "Mobile") {
                            deleteVideoArray.push(`https://youtu.be/${e.url}`);
                          }
                        }
                      } else {
                        const has = deletePhotoArray.find(
                          (item) => item === e.id
                        );

                        if (has) {
                          deletePhotoArray.map((item, index) => {
                            if (item === e.id) {
                              deletePhotoArray.splice(index, 1);
                            }
                          });
                        } else {
                          deletePhotoArray.push(parseInt(e.id, 10));
                        }
                      }
                      setDeleteVideoArray((e) => [...e]);
                      setDeletePhotoArray((e) => [...e]);
                    }}
                  />
                ))}
            </Grid>
            <DeleteAlbumModal
              open={readyDeleteAlbum}
              onClose={() => setReadyDeleteAlbum(false)}
            />
          </CardBox>
        )}
      </Grid>
      {editType.type == "MultipleDelete" || editType.type == "SingleChose" ? (
        <span
          style={{
            position: "sticky",
            display: "inline",
            alignSelf: "flex-end",
            bottom: theme.spacing(1),
            right: theme.spacing(1),
          }}
        >
          <CancelButton
            style={{
              margin: `0 ${theme.spacing(0.5)}px 0 0`,
              backgroundColor: "white",
            }}
            onClick={() => {
              if (editType.type == "SingleChose") {
                setAddPhotoArray([]);
                setDeleteVideoArray([]);
                setDeletePhotoArray([]);
                setNewCoverSelect(null);
                setCoverSelect(null);
                editTypeDispatch({ type: "取消" });
              } else {
                setAddPhotoArray([]);
                setDeleteVideoArray([]);
                setDeletePhotoArray([]);
                editTypeDispatch({ type: "取消" });
              }
            }}
            variant="outlined"
          />
          {editType.type == "MultipleDelete" && (
            <DeleteButton title="選擇檔案" onClick={_DeleteFile} />
          )}
          {editType.type == "SingleChose" && (
            <ConfirmButton onClick={_choseCover} />
          )}
        </span>
      ) : (
        <>
          {!readyDeleteAlbum && (
            <FloatMenu
              onAddPhoto={_onAddPhoto}
              uploadAddPhoto={_uploadAddPhoto}
              onAddVideo={_onAddVideo}
              onDeleteFile={_onDeleteFile}
              onRenameAlbum={_onRenameAlbum}
              onDeleteAlbum={_onDeleteAlbum}
              onChoseCover={_onChoseCover}
            />
          )}
        </>
      )}
      {readyDeleteAlbum && (
        <span
          style={{
            position: "sticky",
            display: "inline",
            alignSelf: "flex-end",
            bottom: theme.spacing(1),
            right: theme.spacing(1),
          }}
        >
          <ConfirmButton onClick={_removeAlbum} />
        </span>
      )}
      <AddVideo
        data={videoUrl}
        open={openAddVideo}
        onClose={() => setOpenAddVideo(false)}
        onAddVideo={(e) => setVideoUrl(e)}
      />
      <RenameAlbum
        data={albumName}
        open={openRenameAlbum}
        onClose={() => setOpenRenameAlbum(false)}
        onRenameAlbum={(e) => setNewAlbumName(e)}
      />
    </WebLayout>
  );
}

function AlbumItem({
  data,
  type,
  coverSelect,
  onSelect = () => {},
  onCoverSelect = () => {},
  onFilesSelect = () => {},
}) {
  const theme = useTheme();
  const [select, setSelect] = useState(null);
  useEffect(() => {
    setSelect(null);
  }, [type]);
  const selected = () => {
    if (type) {
      switch (type) {
        case "MultipleDelete":
          return select;
        case "SingleChose":
          return coverSelect;
        default:
          break;
      }
    } else {
      return false;
    }
  };
  const useStyles = makeStyles({
    TouchBox: {
      display: "flex",
      width: "100%",
      flexDirection: "column",
      alignItems: "center",
    },
    ImageBox: {
      overflow: "hidden",
      boxSizing: "border-box",
      border:
        selected() &&
        `${theme.shape.borderRadius}px solid ${
          type == "MultipleDelete"
            ? theme.palette.error.main
            : theme.palette.primary.main
        }`,
      borderRadius: selected() && theme.shape.borderRadius * 2,
    },
    TitleText: {
      height: "3rem",
      textAlign: "center",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
    },
  });
  const classes = useStyles();
  function _selectArea() {
    switch (type) {
      case "MultipleDelete":
        return setSelect((e) => !e), onSelect(select), onFilesSelect(data);
      case "SingleChose":
        return onCoverSelect(data);
      default:
        break;
    }
  }
  return (
    <Grid container item xs={6} sm={3} md={2}>
      <ButtonBase
        disabled={!type}
        classes={{ root: classes.TouchBox }}
        onClick={_selectArea}
      >
        <Box
          width="inherit"
          maxWidth={150}
          classes={{ root: classes.ImageBox }}
        >
          <AspectRatioBox>
            <img
              src={
                data.photo ||
                `http://img.youtube.com/vi/${data.url}/sddefault.jpg`
              }
              style={{
                height: "100%",
                width: "100%",
                objectFit: "contain",
                backgroundColor: "black",
              }}
            />
          </AspectRatioBox>
        </Box>
      </ButtonBase>
    </Grid>
  );
}

function FloatMenu({
  onAddPhoto = () => {},
  uploadAddPhoto = () => {},
  onAddVideo = () => {},
  onDeleteFile = () => {},
  onRenameAlbum = () => {},
  onDeleteAlbum = () => {},
  onChoseCover = () => {},
}) {
  const theme = useTheme();
  const useStyles = makeStyles({
    Button: {
      backgroundColor: theme.palette.secondary.main,
      "&:hover": {
        backgroundColor: theme.palette.secondary.dark,
      },
      position: "sticky",
      zIndex: theme.zIndex.drawer,
      bottom: theme.spacing(1),
      right: theme.spacing(1),
      alignSelf: "flex-end",
      height: 40,
      width: 40,
      borderRadius: 20,
      color: "white",
    },
  });
  const classes = useStyles();
  const [openMenu, setOpenMenu] = useState(false);
  function _closeMenu() {
    setOpenMenu(false);
  }
  const menuArray = [
    { title: "新增照片", loadType: "Image" },
    { title: "新增影片", fn: () => onAddVideo() },
    { title: "刪除檔案", fn: () => onDeleteFile() },
    { title: "相簿改名", fn: () => onRenameAlbum() },
    { title: "相簿刪除", fn: () => onDeleteAlbum() },
    { title: "選擇封面", fn: () => onChoseCover() },
  ];
  return (
    <>
      <ButtonBase
        classes={{ root: classes.Button }}
        onClick={(e) => setOpenMenu(e.currentTarget)}
      >
        <MoreVertIcon />
      </ButtonBase>
      <Menu
        disableScrollLock
        anchorEl={openMenu}
        keepMounted
        open={Boolean(openMenu)}
        onClose={_closeMenu}
      >
        {menuArray.map((item) => (
          <MenuItem
            onClick={() => {
              _closeMenu();
              item.fn && item.fn();
            }}
            component="label"
            for={item.loadType && `upload${item.loadType}`}
          >
            {item.title}
          </MenuItem>
        ))}
      </Menu>
      <input
        type="file"
        style={{ display: "none" }}
        id="uploadImage"
        multiple
        onChange={async (event) => {
          const {
            target: { validity, files },
          } = event;

          if (validity.valid) {
            for (let file of files) {
              await onAddPhoto({ variables: { image: file } });
            }
            uploadAddPhoto();
          }
        }}
      />
    </>
  );
}

function AddVideo({
  data,
  open,
  onClose = () => {},
  onAddVideo = () => {},
  ...props
}) {
  const [videoUrl, setVideoUrl] = useState(data);
  useEffect(() => {
    setVideoUrl(data);
  }, [open]);
  return (
    <Dialog open={open} onClose={onClose} {...props} disableScrollLock>
      <DialogTitle>
        <Typography>新增影片網址</Typography>
      </DialogTitle>
      <DialogContent>
        <InputBase
          value={videoUrl}
          placeholder="請輸入新增影片的網址"
          onChange={(e) => setVideoUrl(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={onClose} />
        <ConfirmButton
          onClick={() => {
            onClose();
            onAddVideo(videoUrl);
          }}
        />
      </DialogActions>
    </Dialog>
  );
}

function RenameAlbum({
  data,
  open,
  onClose = () => {},
  onRenameAlbum = () => {},
  ...props
}) {
  const [rename, setRename] = useState(data);
  useEffect(() => {
    setRename(data);
  }, [open]);
  return (
    <Dialog open={open} onClose={onClose} {...props} disableScrollLock>
      <DialogTitle>
        <Typography>編輯相簿名稱</Typography>
      </DialogTitle>
      <DialogContent>
        <InputBase
          value={rename}
          placeholder="請輸入新的相簿名稱"
          onChange={(e) => setRename(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <CancelButton onClick={onClose} />
        <ConfirmButton
          disabled={rename.length < 1}
          onClick={() => {
            onClose();
            onRenameAlbum(rename);
          }}
        />
      </DialogActions>
    </Dialog>
  );
}

function DeleteAlbumModal({ open, onClose = () => {} }) {
  const useStyles = makeStyles({
    Button: {
      width: "100%",
      height: "100%",
    },
  });
  const classes = useStyles();
  return (
    <Backdrop
      open={open}
      style={{ zIndex: 1, position: "absolute", color: "white" }}
    >
      <ButtonBase classes={{ root: classes.Button }} onClick={onClose}>
        <Typography variant="h6">取消刪除相簿</Typography>
      </ButtonBase>
    </Backdrop>
  );
}
