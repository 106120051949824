import React from "react";
import { useHistory } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

import WebLayout from "../components/WebLayout";
import PostTable from "../components/PostTable";
import { useAlert } from "./../components/Alert";

const GET_STORY_ARTICLES = gql`
  query GetStoryArticles {
    articles(category: STORY) {
      id
      title
      source
      picture {
        filename
        mimetype
        encoding
        location
      }
      description
      content
      createdAt
    }
  }
`;

const REMOVE_STORY_ARTICLE = gql`
  mutation RemoveStoryArticle($id: ID!) {
    removeArticle(id: $id) {
      success
      message
    }
  }
`;

export default function ConsoleStoryListPage() {
  const Alert = useAlert();
  const history = useHistory();
  const { data, refetch } = useQuery(GET_STORY_ARTICLES, {
    fetchPolicy: "no-cache",
  });
  const [removeArticle] = useMutation(REMOVE_STORY_ARTICLE, {
    fetchPolicy: "no-cache",
    onCompleted({ removeArticle }) {
      if (removeArticle.success) {
        refetch();
      } else {
        Alert.alert("", `${removeArticle.message}`, [
          {
            text: "確定",
            type: "ok",
          },
        ]);
      }
    },
    onError(error) {
      Alert.alert("", `${error}`, [
        {
          text: "確定",
          type: "ok",
        },
      ]);
    },
  });
  return (
    <WebLayout
      title={"心靈雞湯"}
      onAdd={() => history.push(`/console/story/add`)}
    >
      <PostTable
        hypnotist={data && data.articles}
        onEdit={(index) => {
          let has = data.articles.find(
            (articlesItem) => articlesItem.id == index
          );
          if (has.picture) {
            delete has.picture.__typename;
          }
          history.push(`/console/story/${has.id}`, { data: has });
        }}
        onDelete={(index) => {
          let has = data.articles.find(
            (articlesItem) => articlesItem.id == index
          );
          Alert.alert("", "確定要刪除？", [
            {
              text: "確定",
              onClick: () => {
                removeArticle({ variables: { id: has.id } });
              },
              type: "ok",
            },
            {
              text: "取消",
            },
          ]);
        }}
      />
    </WebLayout>
  );
}
