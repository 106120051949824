import React, { createElement, useEffect } from "react";
import "./sass/global.scss";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import { ThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { CssBaseline } from "@material-ui/core";
import { Provider as AlertProvider } from "./console/components/Alert";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import twLocale from "date-fns/locale/zh-TW";
/**前台 */
//首頁
import Home from "./pages/Home";
//協會介紹
import About from "./pages/About";
//活動訊息
import EventList from "./pages/EventList";
import EventDetail from "./pages/EventDetail";
//活動花絮
import AlbumList from "./pages/AlbumList";
import AlbumDetail from "./pages/AlbumDetail";
//會員資訊
import Member from "./pages/Member";
//相關文章
import EssayList from "./pages/EssayList";
import EssayDetail from "./pages/EssayDetail";
//Q&A
import Faq from "./pages/Faq";
//心靈雞湯
import StoryList from "./pages/StoryList";
import StoryDetail from "./pages/StoryDetail";
//聯絡我們
import Contact from "./pages/Contact";
//尋找附近催眠師
import HypnotistList from "./pages/HypnotistList";
import HypnotistDetail from "./pages/HypnotistDetail";
//會員諮商室租用預約
import Rental from "./pages/Rental";
//研習活動報名
import EnrollmentList from "./pages/EnrollmentList";
import EnrollmentDetail from "./pages/EnrollmentDetail";
//催眠師課程資訊
import CourseList from "./pages/CourseList";
import CourseDetail from "./pages/CourseDetail";
//會員諮商室預約
import Reservation from "./pages/Reservation";

/**後台 */
//登入
import ConsoleLoginPage from "./console/pages/ConsoleLoginPage";
//會員諮商室租用預約
import ConsoleReservation from "./console/pages/ConsoleReservationPage";
//會員資訊
import ConsoleMemberPage from "./console/pages/ConsoleMemberPage";
//活動訊息
import ConsoleEventListPage from "./console/pages/ConsoleEventListPage";
import ConsoleEventAddPage from "./console/pages/ConsoleEventAddPage";
//心靈雞湯
import ConsoleStoryListPage from "./console/pages/ConsoleStoryListPage";
import ConsoleStoryAddPage from "./console/pages/ConsoleStoryAddPage";
//相關文章
import ConsoleEssayListPage from "./console/pages/ConsoleEssayListPage";
import ConsoleEssayAddPage from "./console/pages/ConsoleEssayAddPage";
//研習活動報名
import ConsoleEnrollmentListPage from "./console/pages/ConsoleEnrollmentListPage";
import ConsoleEnrollmentAddPage from "./console/pages/ConsoleEnrollmentAddPage";
//催眠師課程資訊
import ConsoleCourseListPage from "./console/pages/ConsoleCourseListPage";
import ConsoleCourseAddPage from "./console/pages/ConsoleCourseAddPage";
//活動花絮
import ConsoleAlbumPage from "./console/pages/ConsoleAlbumPage";
import ConsoleAlbumPicturePage from "./console/pages/ConsoleAlbumPicturePage";
//Q&A
import ConsoleFaqPage from "./console/pages/ConsoleFaqPage";
//尋找催眠師
import ConsoleHypnotistPage from "./console/pages/ConsoleHypnotistPage";
import ConsoleHypnotistListPage from "./console/pages/ConsoleHypnotistListPage";
import ConsoleHypnotistAddPage from "./console/pages/ConsoleHypnotistAddPage";
//關於我們
import ConsoleAboutPage from "./console/pages/ConsoleAboutPage";

function App() {
  const webTheme = createMuiTheme({
    palette: {
      primary: {
        main: "#EF8E13",
        light: "#ffbf4c",
        dark: "#b76000",
        contrastText: "#ffffff",
      },
      secondary: {
        main: "#009292",
        light: "#52c3c2",
        dark: "#006464",
        contrastText: "#ffffff",
      },
    },
    typography: {
      fontFamily: ["Noto Sans TC", "sans-serif", "cwTeXKai", "serif"],
    },
    props: {
      MuiSelect: {
        variant: "outlined",
      },
      MuiButton: {
        disableElevation: true,
      },
    },
    overrides: {
      MuiSelect: {
        root: {
          padding: `10.5px 14px`,
        },
      },
    },
  });
  const consoleTheme = createMuiTheme({
    palette: {
      primary: { main: "#E1693A", contrastText: "#ffffff" },
      secondary: { main: "#F2AC39", contrastText: "#ffffff" },
    },
    props: {
      MuiPaper: {
        variant: "outlined",
      },
      MuiButton: {
        disableElevation: true,
      },
    },
    overrides: {
      MuiSelect: {
        root: {
          padding: `10.5px 14px`,
        },
      },
    },
    typography: { fontFamily: ["Noto Sans TC", "sans-serif"] },
  });
  const routeList = [
    { path: "/", component: Home }, //首頁
    { path: "/about", component: About }, // 協會介紹
    { path: "/event", component: EventList }, // 活動訊息
    { path: "/event/:id", component: EventDetail }, // 活動訊息內頁
    { path: "/album", component: AlbumList }, // 活動花絮
    { path: "/album/:id", component: AlbumDetail }, // 活動花絮內頁
    { path: "/member", component: Member }, // 會員資訊
    { path: "/essay", component: EssayList }, // 相關文章
    { path: "/essay/:id", component: EssayDetail }, // 相關文章內頁
    { path: "/faq", component: Faq }, // Q&A
    { path: "/story", component: StoryList }, // 心靈雞湯
    { path: "/story/:id", component: StoryDetail }, // 心靈雞湯內頁
    { path: "/contact", component: Contact }, // 聯絡我們
    { path: "/hypnotist", component: HypnotistList }, // 尋找附近催眠師
    { path: "/hypnotist/:id", component: HypnotistDetail }, // 尋找附近催眠師內頁
    // { path: "/rental", component: Rental }, // 會員諮詢室租用預約
    { path: "/enrollment", component: EnrollmentList }, // 研習辦理報名
    { path: "/enrollment/:id", component: EnrollmentDetail }, // 研習辦理報名內頁
    { path: "/course", component: CourseList }, // 催眠師課程諮詢
    { path: "/course/:id", component: CourseDetail }, // 催眠師課程諮詢內頁
    // { path: "/reservation", component: Reservation }, // 會員諮商室預約
  ];
  const consoleRoute = [
    { path: "/console", component: ConsoleLoginPage },
    { path: "/console/login", component: ConsoleLoginPage }, // 登入
    { path: "/console/about", component: ConsoleAboutPage }, // 關於我們
    { path: "/console/reservation", component: ConsoleReservation }, //  會員諮商室預約
    { path: "/console/member", component: ConsoleMemberPage }, // 會員資訊
    { path: "/console/event", component: ConsoleEventListPage }, //  活動訊息
    { path: "/console/event/add", component: ConsoleEventAddPage }, //  活動訊息新增
    { path: "/console/event/:id", component: ConsoleEventAddPage }, //  活動訊息內頁
    { path: "/console/story", component: ConsoleStoryListPage }, // 心靈雞湯
    { path: "/console/story/add", component: ConsoleStoryAddPage }, // 心靈雞湯新增
    { path: "/console/story/:id", component: ConsoleStoryAddPage }, // 心靈雞湯內頁
    { path: "/console/essay", component: ConsoleEssayListPage }, // 相關文章
    { path: "/console/essay/add", component: ConsoleEssayAddPage }, // 相關文章新增
    { path: "/console/essay/:id", component: ConsoleEssayAddPage }, // 相關文章內頁
    { path: "/console/enrollment", component: ConsoleEnrollmentListPage }, // 研習活動報名
    { path: "/console/enrollment/add", component: ConsoleEnrollmentAddPage }, // 研習活動報名新增
    { path: "/console/enrollment/:id", component: ConsoleEnrollmentAddPage }, // 研習活動報名內頁
    { path: "/console/course", component: ConsoleCourseListPage }, // 催眠師課程資訊
    { path: "/console/course/add", component: ConsoleCourseAddPage }, // 催眠師課程資訊新增
    { path: "/console/course/:id", component: ConsoleCourseAddPage }, // 催眠師課程資訊內頁
    { path: "/console/album", component: ConsoleAlbumPage }, // 活動花絮
    { path: "/console/album/:id", component: ConsoleAlbumPicturePage }, // 活動花絮編輯照片
    { path: "/console/faq", component: ConsoleFaqPage }, // Q&A
    { path: "/console/hypnotist", component: ConsoleHypnotistListPage }, // 尋找催眠師
    { path: "/console/hypnotist/Add", component: ConsoleHypnotistAddPage }, // 尋找催眠師新增
    { path: "/console/hypnotist/:id", component: ConsoleHypnotistPage }, // 尋找催眠師內頁
  ];
  return (
    <ThemeProvider>
      <CssBaseline />
      <ThemeProvider theme={webTheme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={twLocale}>
          <AlertProvider>
            <Router>
              <ScrollToTop />
              <Switch>
                {routeList.map((item) => (
                  <Route exact path={item.path} key={item.path}>
                    {createElement(item.component)}
                  </Route>
                ))}
              </Switch>
            </Router>
          </AlertProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
      <ThemeProvider theme={consoleTheme}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={twLocale}>
          <AlertProvider>
            <Router>
              <ScrollToTop />
              <Switch>
                {consoleRoute.map((item) => (
                  <Route exact path={item.path} key={item.path}>
                    {createElement(item.component)}
                  </Route>
                ))}
              </Switch>
            </Router>
          </AlertProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </ThemeProvider>
  );
}

export default App;

export function ScrollToTop() {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return null;
}
