import React, { useState } from "react";
import {
  useTheme,
  makeStyles,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { useHistory } from "react-router-dom";

import WebLayout from "../components/WebLayout";
import HypnotistEdit from "../components/HypnotistEdit";
import { useAlert } from "./../components/Alert";

const CREATE_HYPNOTIST = gql`
  mutation CreateHypnotist($input: HypnotistInput!) {
    createHypnotist(input: $input) {
      success
      message
    }
  }
`;

export default function ConsoleHypnotistAddPage() {
  const Alert = useAlert();
  const history = useHistory();
  const theme = useTheme();
  const useStyles = makeStyles({
    Loading: {
      zIndex: theme.zIndex.drawer + 1,
      color: "white",
    },
  });
  const classes = useStyles();
  const [random, setRandom] = useState(0);
  const [hypnotistInput, setHypnotistInput] = useState({
    picture: null,
    email: null,
    address: null,
    line: null,
    facebook: null,
    name: null,
    content: null,
  });
  function createHypnotist() {
    if (
      !hypnotistInput.picture ||
      !hypnotistInput.email ||
      !hypnotistInput.address ||
      !hypnotistInput.line ||
      !hypnotistInput.facebook ||
      !hypnotistInput.name ||
      !hypnotistInput.content
    ) {
      setTimeout(() => {
        Alert.alert("", "尚有資料未填寫！！", [
          {
            text: "確定",
            type: "ok",
          },
        ]);
      }, 500);
      return null;
    }
    _createHypnotist();
  }
  const [_createHypnotist, { loading }] = useMutation(CREATE_HYPNOTIST, {
    variables: {
      input: hypnotistInput,
    },
    onCompleted({ createHypnotist }) {
      if (createHypnotist) {
        Alert.alert("", "儲存成功", [
          {
            text: "確定",
            onClick: () => {
              history.goBack();
            },
            type: "ok",
          },
        ]);
      } else {
        Alert.alert("", "儲存失敗，請重新輸入後再次嘗試。", [
          { text: "確定", type: "ok" },
        ]);
      }
    },
    onError(error) {
      Alert.alert("", `${error}`, [
        {
          text: "確定",
          type: "ok",
        },
      ]);
    },
  });
  return (
    <WebLayout title={"新增催眠師"} onUpdate={() => createHypnotist()}>
      <Backdrop open={loading} classes={{ root: classes.Loading }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <HypnotistEdit
        hypnotists={hypnotistInput}
        onChangeData={(e) => {
          if (e.type == "uploadImage") {
            hypnotistInput.picture = {
              filename: e.value.filename,
              mimetype: e.value.mimetype,
              encoding: e.value.encoding,
              location: e.value.location,
            };
          }
          if (e.type == "deleteImage") {
            hypnotistInput.picture = null;
          }
          if (e.type == "email") {
            hypnotistInput.email = e.value;
          }
          if (e.type == "address") {
            hypnotistInput.address = e.value;
          }
          if (e.type == "line") {
            hypnotistInput.line = e.value;
          }
          if (e.type == "facebook") {
            hypnotistInput.facebook = e.value;
          }
          if (e.type == "name") {
            hypnotistInput.name = e.value;
          }
          if (e.type == "content") {
            hypnotistInput.content = e.value;
          }
          setRandom(random + 1);
        }}
      />
    </WebLayout>
  );
}
