import React, { useState } from "react";
import { Grid, CircularProgress } from "@material-ui/core";
import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import Geocode from "react-geocode";

import WebLayout from "../components/WebLayout";
import PostList from "../components/PostList";

const GET_HYPNOTISTS = gql`
  query GetHypnotists($latest: Boolean) {
    hypnotists(latest: $latest) {
      id
      picture {
        filename
        mimetype
        encoding
        location
      }
      email
      address
      line
      facebook
      name
      content
    }
  }
`;

export default function Hypnotist() {
  Geocode.setApiKey("AIzaSyCI2xlLq3HhJedcQJRS8c2mtQgtZ71Az44");
  Geocode.setLanguage("zh-TW");
  const [googleMapLocation, setGoogleMapLocation] = useState([]);
  const [random, setRandom] = useState(0);
  const { data, loading } = useQuery(GET_HYPNOTISTS, {
    fetchPolicy: "network-only",
    variables: { latest: true },
    onCompleted({ hypnotists }) {
      hypnotists.map((hypnotist) => {
        Geocode.fromAddress(`${hypnotist.address}`).then(
          (res) => {
            let destination = {
              lat: res.results[0].geometry.location.lat,
              lng: res.results[0].geometry.location.lng,
              title: hypnotist.name,
              id: hypnotist.id,
            };
            googleMapLocation.push(destination);
          },
          (error) => {
            console.error(error);
          }
        );
      });
      setRandom(random + 1);
    },
  });
  return (
    <WebLayout data={googleMapLocation}>
      {loading ? (
        <Grid container justify="center">
          <CircularProgress color="secondary" />
        </Grid>
      ) : (
        <PostList data={data ? data.hypnotists : undefined} />
      )}
    </WebLayout>
  );
}
