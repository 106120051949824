import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import WebLayout from "../components/WebLayout";
import PostPage from "../components/PostPage";

const GET_ESSAY = gql`
  query GetEssay($id: ID!) {
    article(id: $id) {
      id
      title
      source
      picture {
        filename
        mimetype
        encoding
        location
      }
      description
      content
      createdAt
    }
  }
`;

export default function EssayPost() {
  const { id } = useParams();
  const { data } = useQuery(GET_ESSAY, { variables: { id } });
  const essayPost = {
    title: "麻醉後就聽不到了嗎？ 研究證實：潛意識仍持續接受訊息",
    image: require("../images/essayImage.jpg"),
    creatTime: Date.now(),
    content: `很多人都以為麻醉後進入昏迷狀態中，意識停擺了，所以什麼都不知道了。但真相是這樣嗎？
    我們的潛意識無時無刻都在吸收大量的訊息。例如光線、溫度、風吹在臉上的感覺、空氣濕度、行走時路面的軟硬度、公車訊息、捷運廣播、馬路上的電線桿、跑馬燈新聞、臺股數字、老闆一個眼神、親密伴侶的嘴角上揚的角度、入睡時被縟的柔軟….一天下來，我們的感官一直在接收訊息，完全沒有停過。只是，在大腦有個更有效率的過濾裝置，將不重要的直接摒除在外，好讓我們只需要聚焦在對我們重大又重要的事物上。`,
    otherPost: [
      {
        id: ":id",
        title: "2019第一屆亞太區催眠研討會實況紀錄",
        image: require("../images/essayImage.jpg"),
      },
      {
        id: ":id",
        title: "2019第一屆亞太區催眠研討會實況紀錄",
        image: require("../images/essayImage.jpg"),
      },
      {
        id: ":id",
        title: "2019第一屆亞太區催眠研討會實況紀錄",
        image: require("../images/essayImage.jpg"),
      },
    ],
  };
  return (
    <WebLayout>
      {data && <PostPage data={data.article} />}
    </WebLayout>
  );
}
