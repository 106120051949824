import React from "react";
import { Grid, Typography, useTheme, IconButton } from "@material-ui/core";

import { ReactComponent as FacebookIcon } from "../images/facebook.svg";
import { ReactComponent as LineIcon } from "../images/line.svg";
import { ReactComponent as MailIcon } from "../images/mail.svg";
import { ReactComponent as PhoneIcon } from "../images/phone.svg";
import { ReactComponent as LocationIcon } from "../images/location.svg";
import { useLocation } from "react-router-dom";

export default function InfoArea({ data }) {
  const location = useLocation();
  const inContact = location.pathname == "/contact";
  const theme = useTheme();
  const iconSetting = {
    height: 75,
    width: 75,
    fill: theme.palette.secondary.dark,
  };
  function InfoGrid({ children }) {
    return (
      <Grid
        container
        item
        xs={6}
        sm={3}
        md={2}
        direction="column"
        alignItems="center"
      >
        {children}
      </Grid>
    );
  }
  function InfoText({ children }) {
    return <Typography align="center">{children}</Typography>;
  }
  return (
    <Grid container item spacing={1} justify="center">
      <InfoGrid>
        <IconButton
          href={
            data && data.address
              ? Boolean(data.address.indexOf("路") >= 0)
                ? `https://www.google.com/maps/search/?api=1&query=${data.address}`
                : `https://maps.app.goo.gl/zFjqQWdJ1HbpV6jEA`
              : `https://maps.app.goo.gl/zFjqQWdJ1HbpV6jEA`
          }
          target="_blank"
          rel="noopener"
        >
          <LocationIcon {...iconSetting} />
        </IconButton>
      </InfoGrid>
      {inContact && (
        <InfoGrid>
          <IconButton href={`tel:+886912025115`} target="_blank" rel="noopener">
            <PhoneIcon {...iconSetting} />
          </IconButton>
        </InfoGrid>
      )}
      <InfoGrid>
        <IconButton
          href={`mailto:${
            data && data.email
              ? Boolean(data.email.indexOf("@") >= 0)
                ? data.email
                : "nghasia2024@gmail.com"
              : "nghasia2024@gmail.com"
          }`}
          target="_blank"
          rel="noopener"
        >
          <MailIcon {...iconSetting} />
        </IconButton>
      </InfoGrid>
      {!inContact && (
        <InfoGrid>
          <IconButton
            href={
              data && data.line
                ? Boolean(
                    data.line.startsWith("https://") ||
                      data.line.startsWith("http://")
                  )
                  ? data.line
                  : `https://lin.ee/${"r4lutog"}`
                : `https://lin.ee/${"r4lutog"}`
            }
            target="_blank"
            rel="noopener"
          >
            <LineIcon {...iconSetting} />
          </IconButton>
        </InfoGrid>
      )}
      <InfoGrid>
        <IconButton
          href={
            data && data.facebook
              ? Boolean(
                  data.facebook.startsWith("https://") ||
                    data.facebook.startsWith("http://")
                )
                ? data.facebook
                : `https://www.facebook.com/${"亞洲ngh催眠師協會-1226616290852274"}`
              : `https://www.facebook.com/${"亞洲ngh催眠師協會-1226616290852274"}`
          }
          target="_blank"
          rel="noopener"
        >
          <FacebookIcon {...iconSetting} />
        </IconButton>
      </InfoGrid>
    </Grid>
  );
}
