import React from "react";
import {
  Grid,
  Box,
  Card,
  CardActions,
  CardContent,
  Typography,
  makeStyles,
  Button,
} from "@material-ui/core";
import AspectRatioBox from "../components/AspectRatioBox";
import { useHistory, useLocation } from "react-router-dom";
import moment from "moment";

export default function PostList({ data = [] }) {
  const history = useHistory();
  const location = useLocation();
  const useStyles = makeStyles({
    TitleText: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
    },
    ContentText: {
      height: `calc(1rem * 1.5 * 3)`,
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-line-clamp": 3,
      "-webkit-box-orient": "vertical",
    },
    ButtonArea: {
      display: "flex",
      justifyContent: "center",
    },
  });
  const classes = useStyles();
  return (
    <Grid container spacing={2} justify={"center"}>
      {Boolean(data[0]) ? (
        data.map((item) => (
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <Card variant={"outlined"}>
              <AspectRatioBox widthSize={16} heightSize={9}>
                <img
                  src={item.picture && item.picture.location}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              </AspectRatioBox>
              <CardContent>
                <Box>
                  <Typography
                    classes={{ root: classes.TitleText }}
                    variant={"h6"}
                    align={"center"}
                  >
                    {item.title || item.name}
                  </Typography>
                </Box>
              </CardContent>
              <CardContent>
                {item.creatTime && (
                  <Typography>
                    {moment(item.createdAt).format("YYYY年MM月")}
                  </Typography>
                )}
                <Box>
                  <Typography classes={{ root: classes.ContentText }}>
                    {item.description || item.content}
                  </Typography>
                </Box>
              </CardContent>
              <CardActions classes={{ root: classes.ButtonArea }}>
                <Button
                  color={"primary"}
                  size={"large"}
                  fullWidth
                  onClick={() =>
                    history.push(
                      `${location.pathname}/${item.id}`.replace("//", "/"),
                      { id: item.id }
                    )
                  }
                >
                  閱讀更多
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))
      ) : (
        <div>
          <a>尚無資料</a>
        </div>
      )}
    </Grid>
  );
}
