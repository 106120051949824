import React, { useState } from "react";

import WebLayout from "../components/WebLayout";
import {
  Grid,
  CardContent,
  Typography,
  useTheme,
  Box,
  CircularProgress,
} from "@material-ui/core";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { ReactComponent as QuestionIcon } from "../images/question.svg";

const GET_FAQ_CONTENT = gql`
  query GETFAQContent {
    content(name: "FAQ") {
      body
    }
  }
`;

export default function Faq() {
  const theme = useTheme();
  const [faqArray, setFaqArray] = useState([]);

  const { loading } = useQuery(GET_FAQ_CONTENT, {
    fetchPolicy: "network-only",
    onCompleted({ content }) {
      if (content && content.body) {
        setFaqArray(JSON.parse(content.body));
      }
    },
  });
  return (
    <WebLayout>
      <Grid container direction={"column"} spacing={1}>
        {loading ? (
          <Grid container justify="center">
            <CircularProgress color="secondary" />
          </Grid>
        ) : Boolean(faqArray[0]) ? (
          faqArray.map((item, index) => {
            let itemColor =
              theme.palette[index % 2 == 1 ? "secondary" : "primary"].main;
            return (
              <Grid item>
                <Box>
                  <CardContent
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <QuestionIcon
                      height={`calc(1.25rem * 1.6)`}
                      style={{ margin: `0 ${theme.spacing(1)}px 0 0` }}
                      fill={itemColor}
                    />
                    <Typography variant={"h6"} style={{ color: itemColor }}>
                      {item.question}
                    </Typography>
                  </CardContent>
                  <CardContent>
                    <Typography
                      style={{
                        textAlign: "justify",
                        textJustify: "inter-character",
                      }}
                    >
                      &emsp;&emsp;{item.answer}
                    </Typography>
                  </CardContent>
                </Box>
              </Grid>
            );
          })
        ) : (
          <div>
            <a>{"尚無 Q & A"}</a>
          </div>
        )}
      </Grid>
    </WebLayout>
  );
}
