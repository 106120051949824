import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

import WebLayout from "../components/WebLayout";
import PostEdit from "../components/PostEdit";
import { useAlert } from "./../components/Alert";

const CREATE_STORY_ARTICLE = gql`
  mutation CreateStoryArticle(
    $title: String!
    $source: String!
    $picture: FileInput
    $description: String!
    $content: String!
  ) {
    createArticle(
      input: {
        category: STORY
        title: $title
        source: $source
        picture: $picture
        description: $description
        content: $content
      }
    ) {
      success
      message
    }
  }
`;

const UPDATE_STORY_ARTICLE = gql`
  mutation UpdateStoryArticle(
    $id: ID!
    $title: String!
    $source: String!
    $picture: FileInput
    $description: String!
    $content: String!
  ) {
    updateArticle(
      id: $id
      input: {
        category: STORY
        title: $title
        source: $source
        picture: $picture
        description: $description
        content: $content
      }
    ) {
      success
      message
    }
  }
`;

export default function ConsoleStoryAddPage() {
  const Alert = useAlert();
  const history = useHistory();
  let id = history.location.state && history.location.state.data.id;
  let [title, setTitle] = useState(
    (history.location.state && history.location.state.data.title) || ""
  );
  let [source, setSource] = useState(
    (history.location.state && history.location.state.data.source) || ""
  );
  let [picture, setPicture] = useState(
    history.location.state && history.location.state.data.picture
  );
  let [description, setDescription] = useState(
    (history.location.state && history.location.state.data.description) || ""
  );
  let [content, setContent] = useState(
    (history.location.state && history.location.state.data.content) || ""
  );

  const [createArticle] = useMutation(CREATE_STORY_ARTICLE, {
    variables: {
      title,
      source,
      picture,
      description,
      content,
    },
    onCompleted({ createArticle }) {
      if (createArticle.success) {
        history.replace("/console/story");
      } else {
        Alert.alert("", `${createArticle.message}`, [
          {
            text: "確定",
            type: "ok",
          },
        ]);
      }
    },
    onError(error) {
      Alert.alert("", `${error}`, [
        {
          text: "確定",
          type: "ok",
        },
      ]);
    },
  });
  const [updateArticle] = useMutation(UPDATE_STORY_ARTICLE, {
    variables: {
      id,
      title,
      source,
      picture,
      description,
      content,
    },
    onCompleted({ updateArticle }) {
      if (updateArticle.success) {
        history.replace("/console/story");
      } else {
        Alert.alert("", `${updateArticle.message}`, [
          {
            text: "確定",
            type: "ok",
          },
        ]);
      }
    },
    onError(error) {
      Alert.alert("", `${error}`, [
        {
          text: "確定",
          type: "ok",
        },
      ]);
    },
  });
  return (
    <WebLayout
      title={"新增心靈雞湯"}
      onUpdate={id ? updateArticle : createArticle}
    >
      <PostEdit
        title={title}
        setTitle={setTitle}
        source={source}
        setSource={setSource}
        picture={picture}
        setPicture={setPicture}
        description={description}
        setDescription={setDescription}
        content={content}
        setContent={setContent}
        onChangeImage={(e) => {}}
      />
    </WebLayout>
  );
}
