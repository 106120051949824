import React from "react";
import {
  Grid,
  Typography,
  Divider,
  Box,
  makeStyles,
  useTheme,
} from "@material-ui/core";

export default function TitleBox({ title = "", children, ...props }) {
  const theme = useTheme();
  const useStyles = makeStyles({
    GridBox: {
      margin: `${theme.spacing(2)}px 0`,
    },
    Divider: {
      backgroundColor: theme.palette.primary.main,
      height: 4,
    },
  });
  const classes = useStyles();
  return (
    <Grid
      container
      item
      direction={"column"}
      classes={{ root: classes.GridBox }}
      {...props}
    >
      <Grid container item justify={"center"}>
        <Box>
          <Typography
            variant={"h5"}
            color={"primary"}
            align={"center"}
            gutterBottom
          >
            {title}
          </Typography>
          <Divider variant={"middle"} classes={{ root: classes.Divider }} />
        </Box>
      </Grid>
      <Grid container item style={{ padding: theme.spacing(1) }}>
        {children}
      </Grid>
    </Grid>
  );
}
