import React, { useState } from "react";

import WebLayout from "../components/WebLayout";
import InfoArea from "../components/InfoArea";
import TitleBox from "../components/TitleBox";
import {
  Box,
  Typography,
  Grid,
  Card,
  CardHeader,
  CardContent,
  useTheme,
  ButtonBase,
  makeStyles,
  CardActions,
  Button,
  useMediaQuery,
} from "@material-ui/core";

import { ReactComponent as CreditCardIcon } from "../images/creditCard.svg";
import { ReactComponent as BankIcon } from "../images/bank.svg";

export default function Contact() {
  const theme = useTheme();
  const useStyles = makeStyles({
    CardTouchArea: {
      width: "100%",
      borderRadius: theme.shape.borderRadius,
      position: "relative",
    },
    BoxContent: {
      display: "flex",
      flexDirection: "column",
      flex: 1,
      alignItems: "center",
      justifyContent: "center",
    },
    InfoBox: {
      display: "flex",
      "& :first-child": {
        width: 100,
      },
    },
  });
  const classes = useStyles();

  function CardTouchArea({ title = "", content = "", type }) {
    const [areaBox, setAreaBox] = useState(false);
    function _changeBox() {
      setAreaBox((e) => !e);
    }
    const boxSize = { height: 330, width: "100%" };
    const iconSize = { height: 75 };
    const itemColor =
      theme.palette[type == "card" ? "primary" : "secondary"].light;
    return (
      <>
        {areaBox ? (
          <Card
            variant="outlined"
            style={{
              ...boxSize,
              display: "flex",
              flexDirection: "column",
              color: "white",
              backgroundColor: itemColor,
            }}
          >
            {type == "card" ? (
              <Box classes={{ root: classes.BoxContent }}>
                <Button
                  color={"inherit"}
                  size={"large"}
                  onClick={() => window.open("./NGH協會信用卡授權書.doc")}
                >
                  下載信用卡捐款單
                </Button>
              </Box>
            ) : (
              <Box classes={{ root: classes.BoxContent }}>
                <Box display={"flex"} flexDirection={"column"}>
                  <Box classes={{ root: classes.InfoBox }}>
                    <Typography>台新銀行</Typography>
                    <Typography>文心分行（0805）</Typography>
                  </Box>
                  <Box classes={{ root: classes.InfoBox }}>
                    <Typography>帳號</Typography>
                    <Typography>2080-0100-013647</Typography>
                  </Box>
                  <Box classes={{ root: classes.InfoBox }}>
                    <Typography>金融代號</Typography>
                    <Typography>812</Typography>
                  </Box>
                  <Box classes={{ root: classes.InfoBox }}>
                    <Typography>戶名</Typography>
                    <Typography>社團法人亞洲NGH催眠師協會</Typography>
                  </Box>
                </Box>
              </Box>
            )}
            <CardActions>
              <Button color={"inherit"} onClick={_changeBox}>
                關閉
              </Button>
            </CardActions>
          </Card>
        ) : (
          <ButtonBase
            classes={{ root: classes.CardTouchArea }}
            onClick={_changeBox}
          >
            <Card variant="outlined" style={boxSize}>
              <CardContent>
                {type == "card" ? (
                  <CreditCardIcon {...iconSize} fill={itemColor} />
                ) : (
                  <BankIcon {...iconSize} fill={itemColor} />
                )}
              </CardContent>
              <CardHeader
                title={title}
                titleTypographyProps={{ align: "center" }}
              />
              <CardContent>
                <Typography align="center">{content}</Typography>
              </CardContent>
            </Card>
          </ButtonBase>
        )}
      </>
    );
  }
  return (
    <WebLayout>
      <TitleBox title="聯絡資訊">
        <Grid container item style={{ marginBottom: theme.spacing(1) }}>
          <Typography align="center" style={{ width: "100%" }}>
            您可以透過以下方式聯絡我們或關注我們
          </Typography>
        </Grid>
        <InfoArea />
      </TitleBox>
      <TitleBox title="捐款">
        <Grid container item style={{ marginBottom: theme.spacing(1) }}>
          <Typography align="center" style={{ width: "100%" }}>
            親愛的捐款朋友，感謝您的愛心支持，不論您是使用下列那一種方式捐款，皆可收到本協會開立之正式收據，並用於報稅使用。
          </Typography>
        </Grid>
        <Grid container item justify="space-evenly" spacing={2}>
          <Grid item xs={12} md={6}>
            <CardTouchArea
              title="信用卡捐款"
              content={
                "可分為單筆及每月固定扣款，同時也是對捐款朋友最為便利的方式，您只需填寫好信用卡捐款單，郵寄、E-Mail至本會，我們將依照您上面所填金額和捐款期限進行扣款。"
              }
              type="card"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <CardTouchArea
              title="銀行匯款"
              content={
                "因選擇銀行匯款時，並不會顯示您的資料，需請您匯款後，請來電或E-MAIL您的捐款人姓名、收據抬頭、電話及郵寄地址，以利協會核對資料，並寄發收據給您。-Mail至本會，我們將依照您上面所填金額和捐款期限進行扣款。"
              }
              type="bank"
            />
          </Grid>
        </Grid>
      </TitleBox>
    </WebLayout>
  );
}
