import React, { useState } from "react";

import WebLayout from "../components/WebLayout";
import {
  Grid,
  ButtonBase,
  makeStyles,
  useTheme,
  Modal,
  Box,
  Typography,
  IconButton,
} from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";
import CloseIcon from "@material-ui/icons/Close";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import YouTube from "react-youtube";

import AspectRatioBox from "../components/AspectRatioBox";
import TitleBox from "../components/TitleBox";
import LoadingBox from "../console/components/LoadingBox";

const GET_ALBUM = gql`
  query GetAlbum($id: ID!) {
    album(id: $id) {
      id
      name
      coverIndex
      files {
        filename
        mimetype
        encoding
        location
      }
      videos {
        url
      }
    }
  }
`;

export default function AlbumPhotos() {
  const { id } = useParams();
  const theme = useTheme();
  const useStyles = makeStyles({
    photoButton: {
      borderRadius: theme.shape.borderRadius,
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
      width: "100%",
    },
    ImageModalAppBar: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "absolute",
      height: 75,
      width: "100%",
      backgroundColor: `rgb(0,0,0,0.4)`,
      color: "white",
      top: 0,
      zIndex: 1,
      padding: `0px ${theme.spacing(2)}px`,
    },
    ImageModalImage: {
      width: "100vw",
      height: "100vh",
      maxWidth: "100%",
      maxHeight: "100%",
      objectFit: "contain",
    },
    ImageIndexButton: {
      position: "absolute",
      color: "white",
    },
    VideoAppBar: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: 75,
      width: "100%",
      backgroundColor: `rgb(0,0,0,0.4)`,
      color: "white",
      padding: `0px ${theme.spacing(2)}px`,
    },
    VideoButtonTitle: {
      textAlign: "center",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
    },
    AppBarTitle: {
      flex: 1,
      textAlign: "center",
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
    },
    AppBarClose: {
      color: "white",
      width: 48,
    },
  });
  const classes = useStyles();
  const [imageIndex, setImageIndex] = useState(0);
  const [imageModal, setImageModal] = useState(false);
  const [videoIndex, setVideoIndex] = useState(undefined);
  const [videoModal, setVideoModal] = useState(false);
  const [showImageAppBar, setShowImageAppBar] = useState(true);
  const [photosArray, setPhotosArray] = useState([]);
  const [videoArray, setVideoArray] = useState([]);
  const { loading } = useQuery(GET_ALBUM, {
    fetchPolicy: "network-only",
    variables: { id },
    onCompleted({ album }) {
      if (album) {
        const newPhotosArray = [];
        const newVideoArray = [];

        album.files.map((fileItem, filesIndex) => {
          newPhotosArray.push({ image: fileItem.location });
        });
        album.videos.map((videoItem, videoIndex) => {
          let newVideoUrl = null;
          const videoPCUrl = videoItem.url.startsWith(
            "https://www.youtube.com/watch?v="
          );
          const videoMobileUrl = videoItem.url.startsWith("https://youtu.be/");

          if (videoPCUrl) {
            newVideoUrl = videoItem.url.replaceAll(
              "https://www.youtube.com/watch?v=",
              ""
            );
            newVideoArray.push({
              image: `http://img.youtube.com/vi/${newVideoUrl}/sddefault.jpg`,
            });
          } else if (videoMobileUrl) {
            newVideoUrl = videoItem.url.replaceAll("https://youtu.be/", "");
            newVideoArray.push({
              image: `http://img.youtube.com/vi/${newVideoUrl}/sddefault.jpg`,
            });
          }
        });
        setPhotosArray(newPhotosArray);
        setVideoArray(newVideoArray);
      }
    },
  });
  return (
    <WebLayout>
      {loading ? (
        <LoadingBox />
      ) : (
        <>
          {Boolean(photosArray[0]) && (
            <TitleBox title="活動照片">
              <Grid container spacing={1}>
                {photosArray.map((item, index) => (
                  <Grid item xs={6} sm={4} md={3}>
                    <ButtonBase
                      classes={{ root: classes.photoButton }}
                      onClick={() => {
                        setImageIndex(index);
                        setImageModal(true);
                      }}
                    >
                      <AspectRatioBox widthSize={16} heightSize={9}>
                        <img
                          src={item.image}
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "contain",
                            backgroundColor: "black",
                          }}
                        />
                      </AspectRatioBox>
                    </ButtonBase>
                  </Grid>
                ))}
              </Grid>
            </TitleBox>
          )}
          <Modal
            open={imageModal}
            onClose={() => setImageModal(false)}
            disableScrollLock
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgb(0,0,0,0.75)",
            }}
          >
            <>
              <SwipeableViews
                enableMouseEvents
                index={imageIndex}
                slideStyle={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onChangeIndex={(e) => setImageIndex(e)}
                onClick={() => setShowImageAppBar(!showImageAppBar)}
              >
                {photosArray.map((item) => (
                  <img src={item.image} className={classes.ImageModalImage} />
                ))}
              </SwipeableViews>
              {showImageAppBar && (
                <>
                  <Box classes={{ root: classes.ImageModalAppBar }}>
                    <Box width={48} height={48} />
                    <Typography
                      classes={{ root: classes.AppBarTitle }}
                      variant="h6"
                    >
                      {imageIndex + 1} of {photosArray.length}
                    </Typography>
                    <IconButton
                      classes={{ root: classes.AppBarClose }}
                      onClick={() => setImageModal(false)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  <IconButton
                    classes={{ root: classes.ImageIndexButton }}
                    style={{ left: theme.spacing(1) }}
                    onClick={() =>
                      setImageIndex((e) =>
                        e > 0 ? e - 1 : photosArray.length - 1
                      )
                    }
                  >
                    <ChevronLeftIcon fontSize={"large"} />
                  </IconButton>
                  <IconButton
                    classes={{ root: classes.ImageIndexButton }}
                    style={{ right: theme.spacing(1) }}
                    onClick={() =>
                      setImageIndex((e) =>
                        e < photosArray.length - 1 ? e + 1 : 0
                      )
                    }
                  >
                    <ChevronRightIcon fontSize={"large"} />
                  </IconButton>
                </>
              )}
            </>
          </Modal>
          {Boolean(videoArray[0]) && (
            <TitleBox title="活動影片">
              <Grid container spacing={1}>
                {videoArray.map((item, index) => (
                  <Grid item xs={6} sm={4} md={3}>
                    <ButtonBase
                      classes={{ root: classes.photoButton }}
                      onClick={() => {
                        setVideoIndex(index);
                        setVideoModal(true);
                      }}
                    >
                      <AspectRatioBox widthSize={16} heightSize={9}>
                        <img
                          src={item.image}
                          style={{
                            height: "100%",
                            width: "100%",
                            objectFit: "contain",
                            backgroundColor: "black",
                          }}
                        />
                      </AspectRatioBox>
                    </ButtonBase>
                  </Grid>
                ))}
              </Grid>
            </TitleBox>
          )}
          <Modal
            open={videoModal}
            onClose={() => setVideoModal(false)}
            disableScrollLock
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "rgb(0,0,0,0.75)",
            }}
          >
            {typeof videoIndex == "number" && (
              <>
                <Box classes={{ root: classes.VideoAppBar }}>
                  <YouTube
                    videoId={videoArray[videoIndex].image.split("/")[4]}
                    opts={{
                      playerVars: {
                        autoplay: 1,
                      },
                    }}
                  />
                </Box>
              </>
            )}
          </Modal>
        </>
      )}
    </WebLayout>
  );
}
