import React, { useState } from "react";

import WebLayout from "../components/WebLayout";
import {
  Grid,
  Card,
  Typography,
  CardContent,
  Box,
  CircularProgress,
  useTheme,
  Link,
} from "@material-ui/core";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { useHistory, useParams } from "react-router-dom";
import Geocode from "react-geocode";

import AspectRatioBox from "../components/AspectRatioBox";
import TitleBox from "../components/TitleBox";
import { useAlert } from "../console/components/Alert";

const GET_HYPNOTIST = gql`
  query GetHypnotist($id: ID!) {
    hypnotist(id: $id) {
      id
      picture {
        filename
        mimetype
        encoding
        location
      }
      email
      address
      line
      facebook
      name
      content
    }
  }
`;

export default function HypnotistPost() {
  const Alert = useAlert();
  const history = useHistory();
  Geocode.setApiKey("AIzaSyDCQ0z5hpf34eSEBHeu465HmM4EyiucPv0");
  Geocode.setLanguage("zh-TW");
  const { id } = useParams();
  const [hypnotistInput, setHypnotistInput] = useState({
    picture: null,
    email: null,
    address: null,
    line: null,
    facebook: null,
    name: null,
    content: null,
  });
  const [googleMapLocation, setGoogleMapLocation] = useState([]);
  const [random, setRandom] = useState(0);
  const { loading } = useQuery(GET_HYPNOTIST, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: { id: id },
    onCompleted({ hypnotist }) {
      if (hypnotist) {
        delete hypnotist.picture.__typename;
        setHypnotistInput({
          picture: hypnotist.picture,
          email: hypnotist.email,
          address: hypnotist.address,
          line: hypnotist.line,
          facebook: hypnotist.facebook,
          name: hypnotist.name,
          content: hypnotist.content,
        });
        Geocode.fromAddress(`${hypnotist.address}`).then(
          (res) => {
            let destination = {
              lat: res.results[0].geometry.location.lat,
              lng: res.results[0].geometry.location.lng,
              title: hypnotist.name,
              id: id,
            };
            googleMapLocation.push(destination);
            setRandom(random + 1);
          },
          (error) => {
            console.error(error);
          }
        );
      } else {
        Alert.alert("", "資料載入錯誤！", [
          {
            text: "確定",
            onClick: () => history.replace("/"),
            type: "ok",
          },
          { text: "取消" },
        ]);
      }
    },
  });
  return (
    <WebLayout data={googleMapLocation}>
      {loading ? (
        <Grid container justify="center">
          <CircularProgress color="secondary" />
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid
            container
            item
            xs={12}
            sm={4}
            direction="column"
            alignItems="center"
          >
            <Card style={{ width: "100%", maxWidth: 300 }}>
              <Box margin={1}>
                <AspectRatioBox widthSize={1} heightSize={1}>
                  <img
                    src={
                      hypnotistInput.picture && hypnotistInput.picture.location
                    }
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "contain",
                    }}
                  />
                </AspectRatioBox>
              </Box>
              <CardContent>
                <Typography align="center">{hypnotistInput.name}</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={8}>
            <TitleBox title={"簡介"}>
              <Typography paragraph>
                &emsp;&emsp;
                {hypnotistInput.content &&
                  hypnotistInput.content.split("\n").map((contentData) => (
                    <>
                      <br />
                      <span>{contentData}</span>
                    </>
                  ))}
                <br />
                <span>地&emsp;&emsp;址:&emsp;</span>
                <Link
                  href={`https://www.google.com/maps/search/?api=1&query=${hypnotistInput.address}`}
                  target="_blank"
                  rel="noopener"
                >
                  {hypnotistInput.address}
                </Link>
                <br />
                <span>信&emsp;&emsp;箱:&emsp;</span>
                <Link
                  href={`mailto:${hypnotistInput.email}`}
                  target="_blank"
                  rel="noopener"
                >
                  {hypnotistInput.email}
                </Link>
                <br />
                <span>粉&emsp;&emsp;專:&emsp;</span>
                <Link
                  href={`${hypnotistInput.facebook}`}
                  target="_blank"
                  rel="noopener"
                >
                  {hypnotistInput.facebook}
                </Link>
                <br />
                <span>預約方式:&emsp;</span>
                <span>{hypnotistInput.line}</span>
              </Typography>
            </TitleBox>
          </Grid>
        </Grid>
      )}
    </WebLayout>
  );
}
