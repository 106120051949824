import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import WebLayout from "../components/WebLayout";
import PostPage from "../components/PostPage";

const GET_EVENT = gql`
  query GetEvent($id: ID!) {
    article(id: $id) {
      id
      title
      source
      picture {
        filename
        mimetype
        encoding
        location
      }
      description
      content
      createdAt
    }
  }
`;

export default function EventPost() {
  const { id } = useParams();
  const { data } = useQuery(GET_EVENT, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: { id },
  });
  return <WebLayout>{data && <PostPage data={data.article} />}</WebLayout>;
}
