import React, { useState, useEffect } from "react";
import { Grid, Typography, Box, makeStyles, useTheme } from "@material-ui/core";
import AspectRatioBox from "../components/AspectRatioBox";
import { useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import WebLayout from "../components/WebLayout";
import TitleBox from "../components/TitleBox";
import LoadingBox from "../console/components/LoadingBox";

const GET_ORGANIZATION = gql`
  query organization {
    organization {
      origin
      image {
        filename
        mimetype
        encoding
        location
      }
      chairperson {
        name
        image {
          filename
          mimetype
          encoding
          location
        }
      }
      secretaryGeneral {
        name
        image {
          filename
          mimetype
          encoding
          location
        }
      }
      councilMembers {
        name
        image {
          filename
          mimetype
          encoding
          location
        }
      }
      supervisoryBoardMembers {
        name
        image {
          filename
          mimetype
          encoding
          location
        }
      }
    }
  }
`;

export default function About() {
  const [origin, setOrigin] = useState("");
  const [image, setImage] = useState();
  const [chairperson, setChairperson] = useState({
    name: "",
    image: null,
  });
  const [secretaryGeneral, setSecretaryGeneral] = useState({
    name: "",
    image: null,
  });
  const [organizationalChart, setOrganizationalChart] = useState([]);
  const [councilMembers, setCouncilMembers] = useState([
    { name: "", image: null },
  ]);
  const [supervisoryBoardMembers, setSupervisoryBoardMembers] = useState([
    { name: "", image: null },
  ]);

  useEffect(() => {
    getOrganization();
  }, []);

  const [getOrganization, { loading: organizationLoading }] = useLazyQuery(
    GET_ORGANIZATION,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onCompleted({ organization }) {
        if (organization) {
          const newCouncilMembers = [];
          const newSupervisoryBoardMembers = [];

          const newCouncilMembersChart = [];
          const newSupervisoryBoardMembersChart = [];

          setOrigin(organization.origin);
          delete organization.image.__typename;
          setImage(organization.image);
          delete organization.chairperson.image.__typename;
          setChairperson(organization.chairperson);
          delete organization.secretaryGeneral.image.__typename;
          setSecretaryGeneral(organization.secretaryGeneral);
          organization.councilMembers.map((item) => {
            delete item.image.__typename;
            newCouncilMembers.push({ name: item.name, image: item.image });
            newCouncilMembersChart.push(item.name);
          });
          setCouncilMembers(newCouncilMembers);
          organization.supervisoryBoardMembers.map((item) => {
            delete item.image.__typename;
            newSupervisoryBoardMembers.push({
              name: item.name,
              image: item.image,
            });
            newSupervisoryBoardMembersChart.push(item.name);
          });
          setSupervisoryBoardMembers(newSupervisoryBoardMembers);
          setOrganizationalChart([
            {
              title: "理事長",
              content: [organization.chairperson.name],
            },
            {
              title: "秘書長",
              content: [organization.secretaryGeneral.name],
            },
            {
              title: "理事群",
              content: newCouncilMembersChart,
            },
            {
              title: "監事群",
              content: newSupervisoryBoardMembersChart,
            },
          ]);
        }
      },
    }
  );

  return (
    <WebLayout>
      <Grid container direction={"column"}>
        {organizationLoading ? (
          <LoadingBox />
        ) : (
          <>
            <OriginAssociation data={origin} />
            <PersonnelArea data={organizationalChart} image={image} />
            <PersonnelList
              data={{
                chairperson,
                secretaryGeneral,
                councilMembers,
                supervisoryBoardMembers,
              }}
            />
          </>
        )}
      </Grid>
    </WebLayout>
  );
}

function OriginAssociation({ data }) {
  return (
    <TitleBox title="協會成立緣起">
      {data.split("\n").map((dataItem) => (
        <Typography paragraph>{dataItem}</Typography>
      ))}
    </TitleBox>
  );
}

function PersonnelArea({ data, image }) {
  return (
    <TitleBox title="組織架構">
      <Grid container spacing={4}>
        <Grid item xs={12} sm={4}>
          {Boolean(image) && (
            <img src={image.location} style={{ width: "100%" }} />
          )}
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={8}
          direction={"column"}
          justify={"center"}
          alignItems={"center"}
        >
          <Box>
            <Box display={"flex"} flexWrap="wrap">
              <Box style={{ width: "8em" }}>
                <Typography color={"primary"}>榮譽理事長</Typography>
              </Box>
              <Box style={{ display: "flex", flex: 1 }} flexWrap="wrap">
                <Typography>陳一德</Typography>
              </Box>
            </Box>
            <Box display={"flex"} flexWrap="wrap">
              <Box style={{ width: "8em" }}>
                <Typography color={"primary"}>名譽理事</Typography>
              </Box>
              <Box style={{ display: "flex", flex: 1 }} flexWrap="wrap">
                <Typography>楊孟達</Typography>
              </Box>
            </Box>
            {Boolean(data[0]) &&
              data.map((item) => (
                <Box display={"flex"} flexWrap="wrap">
                  <Box style={{ width: "8em" }}>
                    <Typography color={"primary"}>{item.title}</Typography>
                  </Box>
                  <Box style={{ display: "flex", flex: 1 }} flexWrap="wrap">
                    {item.content.length > 1 ? (
                      item.content.map((item2) => (
                        <Typography>{item2}&emsp;</Typography>
                      ))
                    ) : (
                      <Typography>{item.content}</Typography>
                    )}
                  </Box>
                </Box>
              ))}
          </Box>
        </Grid>
      </Grid>
    </TitleBox>
  );
}

function PersonnelList({ data }) {
  const theme = useTheme();
  const useStyles = makeStyles({
    PersonPhoto: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
  });
  const classes = useStyles();

  const evenNumber = (e) => e % 2 == 1;
  const [supervisor, setSupervisor] = useState([]);

  useEffect(() => {
    if (Boolean(data.chairperson.name) && Boolean(data.secretaryGeneral.name)) {
      setSupervisor([
        {
          name: data.chairperson.name,
          image: data.chairperson.image,
        },
        {
          name: data.secretaryGeneral.name,
          image: data.secretaryGeneral.image,
        },
      ]);
    }
  }, [data]);

  function PersonBox({ data, index }) {
    return (
      <Grid container item xs={12} sm={6} md={4}>
        <Grid item xs={6}>
          <AspectRatioBox>
            <Box style={{ width: "100%" }}>
              {data.image ? (
                <img
                  src={data.image.location}
                  className={classes.PersonPhoto}
                />
              ) : null}
            </Box>
          </AspectRatioBox>
        </Grid>
        <Grid item xs={6}>
          <AspectRatioBox>
            <Box
              bgcolor={
                theme.palette[evenNumber(index) ? "secondary" : "primary"].light
              }
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              flex={1}
            >
              <Typography variant={"h6"} style={{ color: "white" }}>
                {data.name}
              </Typography>
            </Box>
          </AspectRatioBox>
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      {supervisor[0] && (
        <TitleBox title={"理事長"}>
          <Grid container justify={"space-evenly"}>
            <PersonBox data={supervisor[0]} index={0} />
          </Grid>
        </TitleBox>
      )}
      {supervisor[1] && (
        <TitleBox title={"秘書長"}>
          <Grid container justify={"space-evenly"}>
            <PersonBox data={supervisor[1]} index={1} />
          </Grid>
        </TitleBox>
      )}
      <TitleBox title={"理事群"}>
        <Grid container>
          {Boolean(data.councilMembers[0]) &&
            data.councilMembers.map((item, index) => (
              <PersonBox data={item} index={index} />
            ))}
        </Grid>
      </TitleBox>
      <TitleBox title={"監事群"}>
        <Grid container>
          {Boolean(data.supervisoryBoardMembers[0]) &&
            data.supervisoryBoardMembers.map((item, index) => (
              <PersonBox data={item} index={index} />
            ))}
        </Grid>
      </TitleBox>
    </>
  );
}
