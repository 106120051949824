import React, { useState, useEffect } from "react";
import {
  Grid,
  TextField,
  Box,
  Button,
  useTheme,
  makeStyles,
  Backdrop,
  CircularProgress,
  ButtonBase,
} from "@material-ui/core";

import CardBox from "../components/CardBox";
import WebLayout from "../components/WebLayout";
import { ReactComponent as AddImageIcon } from "../images/addImage.svg";
import AspectRatioBox from "../../components/AspectRatioBox";
import { AddButton, DeleteButton } from "../components/Buttons";
import { useAlert } from "./../components/Alert";
import LoadingBox from "../components/LoadingBox";
import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

const GET_ORGANIZATION = gql`
  query organization {
    organization {
      origin
      image {
        filename
        mimetype
        encoding
        location
      }
      chairperson {
        name
        image {
          filename
          mimetype
          encoding
          location
        }
      }
      secretaryGeneral {
        name
        image {
          filename
          mimetype
          encoding
          location
        }
      }
      councilMembers {
        name
        image {
          filename
          mimetype
          encoding
          location
        }
      }
      supervisoryBoardMembers {
        name
        image {
          filename
          mimetype
          encoding
          location
        }
      }
    }
  }
`;

const UPLOAD_IMAGE = gql`
  mutation UploadImage($image: Upload!) {
    uploadImage(image: $image) {
      filename
      mimetype
      encoding
      location
    }
  }
`;

const SAVE_ORGANIZATION = gql`
  mutation saveOrganization($organizationInput: OrganizationInput!) {
    saveOrganization(organizationInput: $organizationInput) {
      success
      message
    }
  }
`;

export default function ConsoleAboutPage() {
  const Alert = useAlert();
  const theme = useTheme();
  const useStyles = makeStyles({
    Loading: {
      zIndex: theme.zIndex.drawer + 1,
      color: "white",
    },
  });
  const classes = useStyles();
  const [origin, setOrigin] = useState("");
  const [image, setImage] = useState();
  const [chairperson, setChairperson] = useState({
    name: "",
    image: null,
  });
  const [secretaryGeneral, setSecretaryGeneral] = useState({
    name: "",
    image: null,
  });
  const [councilMembers, setCouncilMembers] = useState([
    { name: "", image: null },
  ]);
  const [supervisoryBoardMembers, setSupervisoryBoardMembers] = useState([
    { name: "", image: null },
  ]);

  useEffect(() => {
    getOrganization();
  }, []);

  const [getOrganization, { loading: organizationLoading }] = useLazyQuery(
    GET_ORGANIZATION,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      onCompleted({ organization }) {
        if (organization) {
          const newCouncilMembers = [];
          const newSupervisoryBoardMembers = [];

          setOrigin(organization.origin);
          delete organization.image.__typename;
          setImage(organization.image);
          delete organization.chairperson.image.__typename;
          setChairperson(organization.chairperson);
          delete organization.secretaryGeneral.image.__typename;
          setSecretaryGeneral(organization.secretaryGeneral);
          organization.councilMembers.map((item) => {
            delete item.image.__typename;
            newCouncilMembers.push({ name: item.name, image: item.image });
          });
          setCouncilMembers(newCouncilMembers);
          organization.supervisoryBoardMembers.map((item) => {
            delete item.image.__typename;
            newSupervisoryBoardMembers.push({
              name: item.name,
              image: item.image,
            });
          });
          setSupervisoryBoardMembers(newSupervisoryBoardMembers);
        }
      },
    }
  );

  const [uploadImage, { loading: uploadImageLoading }] = useMutation(
    UPLOAD_IMAGE,
    {
      onCompleted({ uploadImage }) {
        delete uploadImage.__typename;
        setImage({
          filename: uploadImage.filename,
          mimetype: uploadImage.mimetype,
          encoding: uploadImage.encoding,
          location: uploadImage.location,
        });
      },
      onError(error) {
        Alert.alert("", `${error}`, [
          {
            text: "確定",
            type: "ok",
          },
        ]);
      },
    }
  );

  function _saveOrganization() {
    const councilMembersError = councilMembers.find(
      (item) => !Boolean(item.name) || !Boolean(item.image)
    );
    const supervisoryBoardMembersError = supervisoryBoardMembers.find(
      (item) => !Boolean(item.name) || !Boolean(item.image)
    );

    if (!origin) {
      setTimeout(() => {
        Alert.alert("", "協會成立緣起資料未填寫！！", [
          {
            text: "確定",
            type: "ok",
          },
        ]);
      }, 500);
      return null;
    }
    if (!Boolean(image)) {
      setTimeout(() => {
        Alert.alert("", "組織圖未選擇！！", [
          {
            text: "確定",
            type: "ok",
          },
        ]);
      }, 500);
      return null;
    }
    if (!Boolean(chairperson.name)) {
      setTimeout(() => {
        Alert.alert("", "理事長名稱未填寫！！", [
          {
            text: "確定",
            type: "ok",
          },
        ]);
      }, 500);
      return null;
    }
    if (!Boolean(secretaryGeneral.name)) {
      setTimeout(() => {
        Alert.alert("", "秘書長名稱未填寫！！", [
          {
            text: "確定",
            type: "ok",
          },
        ]);
      }, 500);
      return null;
    }
    if (councilMembersError) {
      setTimeout(() => {
        Alert.alert("", "理事名單未填寫完整！！", [
          {
            text: "確定",
            type: "ok",
          },
        ]);
      }, 500);
      return null;
    }
    if (supervisoryBoardMembersError) {
      setTimeout(() => {
        Alert.alert("", "監事名單未填寫完整！！", [
          {
            text: "確定",
            type: "ok",
          },
        ]);
      }, 500);
      return null;
    }

    saveOrganization();
  }

  const [saveOrganization, { loading: saveOrganizationLoading }] = useMutation(
    SAVE_ORGANIZATION,
    {
      variables: {
        organizationInput: {
          origin,
          image,
          chairperson,
          secretaryGeneral,
          councilMembers,
          supervisoryBoardMembers,
        },
      },
      onCompleted({ saveOrganization }) {
        if (saveOrganization.success) {
          Alert.alert("", "儲存成功！", [
            {
              text: "確定",
              type: "ok",
            },
          ]);
        } else {
          Alert.alert("", "儲存失敗，請重新輸入後再次嘗試。", [
            { text: "確定", type: "ok" },
          ]);
        }
      },
      onError(error) {
        Alert.alert("", `${error}`, [
          {
            text: "確定",
            type: "ok",
          },
        ]);
      },
    }
  );

  return (
    <WebLayout
      title={"協會介紹"}
      onUpdate={() => {
        _saveOrganization();
      }}
    >
      <Backdrop
        open={uploadImageLoading || saveOrganizationLoading}
        classes={{ root: classes.Loading }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container spacing={1}>
        {organizationLoading ? (
          <LoadingBox />
        ) : (
          <>
            <Grid container item>
              <CardBox title="協會成立緣起" style={{ flex: 1 }}>
                <TextField
                  variant="outlined"
                  multiline
                  fullWidth
                  value={origin}
                  onChange={(e) => setOrigin(e.target.value)}
                />
              </CardBox>
            </Grid>
            <Grid container item xs={12} sm={6}>
              <CardBox title="組織架構" style={{ flex: 1 }}>
                <Box display="flex" flex={1} height={300}>
                  {Boolean(image) ? (
                    <ButtonBase
                      component="label"
                      htmlFor="imageUpload"
                      style={{
                        flex: 1,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={image.location}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                    </ButtonBase>
                  ) : (
                    <>
                      <Button
                        variant="contained"
                        color="secondary"
                        component="label"
                        htmlFor="imageUpload"
                        disableElevation
                        style={{
                          display: "flex",
                          flex: 1,
                          position: "relative",
                        }}
                      >
                        <AddImageIcon width={50} height={50} fill={"white"} />
                      </Button>
                    </>
                  )}
                  <input
                    id="imageUpload"
                    type="file"
                    accept="image/*"
                    onChange={(event) => {
                      const {
                        target: { validity, files },
                      } = event;

                      if (validity.valid) {
                        for (let file of files) {
                          uploadImage({ variables: { image: file } });
                        }
                      }
                    }}
                    style={{
                      display: "none",
                    }}
                  />
                </Box>
              </CardBox>
            </Grid>
            <Grid container item xs={12} sm={6} spacing={1}>
              <Grid container item>
                <CardBox title="理事長" style={{ flex: 1 }}>
                  <PersonlItem
                    data={chairperson}
                    type="chairman"
                    disableDelete
                    onChangeData={(e) =>
                      setChairperson({ name: e.name, image: e.image })
                    }
                  />
                </CardBox>
              </Grid>
              <Grid container item>
                <CardBox title="秘書長" style={{ flex: 1 }}>
                  <PersonlItem
                    data={secretaryGeneral}
                    type="secretaryGeneral"
                    disableDelete
                    onChangeData={(e) =>
                      setSecretaryGeneral({ name: e.name, image: e.image })
                    }
                  />
                </CardBox>
              </Grid>
            </Grid>
            <Grid container item>
              <CardBox title="理事" style={{ flex: 1 }}>
                <Grid container item spacing={1}>
                  {councilMembers.map((item, index) => (
                    <Grid item xs={12} sm={12} md={6}>
                      <PersonlItem
                        data={item}
                        type={`director${index}`}
                        onDelete={() =>
                          setCouncilMembers((e) =>
                            e.filter((eItem, eIndex) => eIndex !== index)
                          )
                        }
                        onChangeData={(newValue) =>
                          setCouncilMembers((e) => [
                            ...e.fill(newValue, index, index + 1),
                          ])
                        }
                      />
                    </Grid>
                  ))}
                  <Grid container item xs={12} sm={12} md={6}>
                    <AddButton
                      style={{ flex: 1, height: 104 }}
                      onClick={() =>
                        setCouncilMembers((e) => [
                          ...e,
                          { name: "", image: null },
                        ])
                      }
                    />
                  </Grid>
                </Grid>
              </CardBox>
            </Grid>
            <Grid container item>
              <CardBox title="監事" style={{ flex: 1 }}>
                <Grid container item spacing={1}>
                  {supervisoryBoardMembers.map((item, index) => (
                    <Grid item xs={12} sm={12} md={6}>
                      <PersonlItem
                        data={item}
                        type={`supervisor${index}`}
                        onDelete={() =>
                          setSupervisoryBoardMembers((e) =>
                            e.filter((eItem, eIndex) => eIndex !== index)
                          )
                        }
                        onChangeData={(newValue) =>
                          setSupervisoryBoardMembers((e) => [
                            ...e.fill(newValue, index, index + 1),
                          ])
                        }
                      />
                    </Grid>
                  ))}
                  <Grid container item xs={12} sm={12} md={6}>
                    <AddButton
                      style={{ flex: 1, height: 104 }}
                      onClick={() =>
                        setSupervisoryBoardMembers((e) => [
                          ...e,
                          { name: "", image: null },
                        ])
                      }
                    />
                  </Grid>
                </Grid>
              </CardBox>
            </Grid>
          </>
        )}
      </Grid>
    </WebLayout>
  );
}

function PersonlItem({
  data,
  type,
  disableDelete,
  onDelete = () => {},
  onChangeData = () => {},
}) {
  const Alert = useAlert();
  const theme = useTheme();
  const useStyles = makeStyles({
    Loading: {
      zIndex: theme.zIndex.drawer + 1,
      color: "white",
    },
  });
  const classes = useStyles();
  const [name, setName] = useState("");
  const [image, setImage] = useState();
  useEffect(() => {
    if (Boolean(data.name)) {
      if (Boolean(data.image)) {
        delete data.image.__typename;
      }
      setName(data.name);
      setImage(data.image);
    }
  }, [data]);

  useEffect(() => {
    onChangeData({ name, image });
  }, [name, image]);

  const [uploadImage, { loading: uploadImageLoading }] = useMutation(
    UPLOAD_IMAGE,
    {
      onCompleted({ uploadImage }) {
        delete uploadImage.__typename;
        setImage({
          filename: uploadImage.filename,
          mimetype: uploadImage.mimetype,
          encoding: uploadImage.encoding,
          location: uploadImage.location,
        });
      },
      onError(error) {
        Alert.alert("", `${error}`, [
          {
            text: "確定",
            type: "ok",
          },
        ]);
      },
    }
  );

  function _delete() {
    onDelete();
  }
  return (
    <Box display="flex" width="100%" alignItems="center">
      <Backdrop open={uploadImageLoading} classes={{ root: classes.Loading }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box padding={0.5}>
        <AspectRatioBox width={88}>
          {Boolean(image) ? (
            <ButtonBase
              component="label"
              htmlFor={`imageUpload${type}`}
              style={{
                flex: 1,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img
                src={image.location}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </ButtonBase>
          ) : (
            <>
              <Button
                variant="contained"
                color="secondary"
                component="label"
                htmlFor={`imageUpload${type}`}
                disableElevation
                style={{
                  display: "flex",
                  flex: 1,
                  position: "relative",
                }}
              >
                <AddImageIcon width={50} height={50} fill={"white"} />
              </Button>
            </>
          )}
          <input
            id={`imageUpload${type}`}
            type="file"
            accept="image/*"
            onChange={(event) => {
              const {
                target: { validity, files },
              } = event;

              if (validity.valid) {
                for (let file of files) {
                  uploadImage({ variables: { image: file } });
                }
              }
            }}
            style={{
              display: "none",
            }}
          />
        </AspectRatioBox>
      </Box>
      <Box display="flex" flex={1} flexDirection="column">
        <Box padding={0.5}>
          <TextField
            variant="outlined"
            size="small"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </Box>
        {!disableDelete && (
          <Box padding={0.5}>
            <DeleteButton onClick={_delete} fullWidth />
          </Box>
        )}
      </Box>
    </Box>
  );
}
