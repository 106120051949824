import React, { useState, useEffect } from "react";

import WebLayout from "../components/WebLayout";
import {
  Grid,
  Card,
  CardActionArea,
  CardContent,
  makeStyles,
  Box,
  useTheme,
  colors,
  Typography,
  CardHeader,
  MenuItem,
  useMediaQuery,
  Button,
  CircularProgress,
} from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import GridLayout from "../components/GridLayout";
import SwipeableViews from "react-swipeable-views";
import AspectRatioBox from "../components/AspectRatioBox";

const GET_ARTICLES = gql`
  query GetArticles($category: ArticleCategory, $ignored: [ID]) {
    articles(category: $category, ignored: $ignored) {
      id
      category
      title
      source
      picture {
        filename
        mimetype
        encoding
        location
      }
      description
      content
      createdAt
      updatedAt
    }
  }
`;

const GET_ALBUMS = gql`
  query GetAlbums($latest: Boolean) {
    albums(latest: $latest) {
      id
      name
      coverIndex
      files {
        filename
        mimetype
        encoding
        location
      }
    }
  }
`;

export default function Home() {
  const [albumItem, setAlbumItem] = useState([]);
  const { loading: loading2 } = useQuery(GET_ALBUMS, {
    fetchPolicy: "network-only",
    variables: { latest: false },
    onCompleted({ albums }) {
      if (albums) {
        albums.map((albumsItem, albumsIndex) => {
          albumItem.push({
            id: albumsItem.id,
            name: albumsItem.name,
            image:
              albumsItem.files[0] &&
              (albumsItem.coverIndex || albumsItem.coverIndex == 0) &&
              albumsItem.files[albumsItem.coverIndex]
                ? albumsItem.files[albumsItem.coverIndex].location
                : require("../images/backgroundImage.jpg"),
          });
        });
        setAlbumItem((e) => [...e]);
      }
    },
  });
  const eventItem = [
    { id: ":id", title: "催眠師協會說明會，中部地區" },
    { id: ":id", title: "催眠師協會說明會，中部地區" },
    { id: ":id", title: "催眠師協會說明會，中部地區" },
    { id: ":id", title: "催眠師協會說明會，中部地區" },
    { id: ":id", title: "催眠師協會說明會，中部地區" },
  ];
  return (
    <WebLayout>
      <GridLayout spacing={2}>
        <Grid container item justify={"center"}>
          <HomeMainCard />
        </Grid>
        <Grid container item>
          <AboutArea />
        </Grid>
        <Grid container item>
          <EventArea eventData={eventItem} albumData={albumItem} />
        </Grid>
      </GridLayout>
    </WebLayout>
  );
}

function HomeMainCard() {
  const history = useHistory();
  const location = useLocation();
  function _pushPage(e) {
    location.pathname != e && history.push(e);
  }
  const theme = useTheme();
  const useStyles = makeStyles({
    MainCardRoot: {
      maxWidth: 300,
      backgroundColor: "#98546F",
      color: "white",
      margin: theme.spacing(1),
    },
    CardImage: {
      width: `calc(100% - ${theme.spacing(1)}px)`,
      height: `calc(100% - ${theme.spacing(1)}px)`,
      borderRadius: theme.shape.borderRadius,
    },
    CardTtitle: {
      height: `calc(1.5* 2 * 1rem)`,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      textAlign: "center",
    },
  });
  const classes = useStyles();
  const mainLinkArray = [
    { title: "尋找附近催眠師", route: "/hypnotist" },
    { title: "會員諮詢室租用預約", route: "/rental" },
    { title: "研習辦理報名", route: "/enrollment" },
    { title: "催眠師課程諮詢", route: "/course" },
  ];
  return (
    <>
      {mainLinkArray.map((item, index) => (
        <Grid item xs={6} sm={3} md={2}>
          <Card classes={{ root: classes.MainCardRoot }}>
            <CardActionArea onClick={() => _pushPage(item.route)}>
              <AspectRatioBox>
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <img
                    src={require(`../images/mainImage${index + 1}.jpg`)}
                    className={classes.CardImage}
                  />
                </Box>
              </AspectRatioBox>
              <CardContent>
                <Box classes={{ root: classes.CardTtitle }}>
                  <Typography>{item.title}</Typography>
                </Box>
              </CardContent>
            </CardActionArea>
          </Card>
        </Grid>
      ))}
    </>
  );
}

function AboutArea() {
  const theme = useTheme();
  const useStyles = makeStyles({
    CardRoot: {
      backgroundColor: `rgb(255,255,255,.58)`,
    },
    ImageBackGround: {
      height: "100%",
      width: "100%",
      objectFit: "cover",
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: -1,
    },
  });
  const classes = useStyles();

  return (
    <Box
      position={"relative"}
      width={"100%"}
      style={{ padding: theme.spacing(1) }}
    >
      <img
        src={require("../images/homeBanner.jpg")}
        className={classes.ImageBackGround}
      />
      <Card classes={{ root: classes.CardRoot }}>
        <CardHeader title={"關於協會"} />
        <CardContent>
          <Typography>
            我相信所有催眠師們會走上這神聖的工作，大家都肩負著神聖的使命，我們要幫助個案實現廣大的身心靈潛能。所有催眠師不但是職業上的團體，也是天上的團體，我們應該都屬於同一靈魂團體，一起到地球完成我們共同的任務！大家一起加油！
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
}

function EventArea({ eventData = [], albumData = [] }) {
  const history = useHistory();
  const theme = useTheme();
  const mobileSize = useMediaQuery(theme.breakpoints.down("xs"));
  const tabletSize = useMediaQuery(theme.breakpoints.down("sm"));
  const useStyles = makeStyles({
    CardRoot: {
      backgroundColor: colors.grey["900"],
      color: "white",
      height: "100%",
    },
    PageButton: {
      position: "absolute",
      padding: theme.spacing(1),
      height: "100%",
      minWidth: 0,
      top: 0,
      color: "white",
    },
    EventCardRoot: {
      height: "100%",
    },
    ContentText: {
      margin: `${theme.spacing(1)}px 0`,
    },
  });
  const classes = useStyles();
  //活動圖片輪播
  const [imageIndex, setImageIndex] = useState(0);
  function albumDataGroup(array, subGroupLength) {
    let index = 0;
    let newArray = [];
    while (index < array.length) {
      newArray.push(array.slice(index, (index += subGroupLength)));
    }
    return newArray;
  }
  const albumAmount = mobileSize ? 1 : tabletSize ? 2 : 3;
  useEffect(() => setImageIndex(0), [mobileSize, tabletSize]);
  const { data, loading } = useQuery(GET_ARTICLES, {
    fetchPolicy: "network-only",
    variables: { category: "EVENT" },
  });

  return (
    <Grid container item>
      <Grid item xs={12} sm={12} md={4}>
        <Card classes={{ root: classes.CardRoot }}>
          <CardHeader title={"活動訊息"} />
          {loading ? (
            <Grid container justify="center">
              <CircularProgress color="secondary" />
            </Grid>
          ) : (
            <CardContent>
              {data &&
                data.articles.map((item) => (
                  <MenuItem onClick={() => history.push(`/event/${item.id}`)}>
                    <Typography variant={"inherit"} noWrap>
                      {item.title}
                    </Typography>
                  </MenuItem>
                ))}
            </CardContent>
          )}
        </Card>
      </Grid>
      <Grid item xs={12} sm={12} md={8}>
        <Card classes={{ root: classes.EventCardRoot }}>
          <CardHeader title={"活動花絮"} />
          <Box display={"flex"} position={"relative"}>
            <SwipeableViews
              enableMouseEvents
              index={imageIndex}
              style={{ padding: `0 40px`, width: "100%" }}
              onChangeIndex={(index) => setImageIndex(index)}
            >
              {albumDataGroup(albumData, albumAmount).map((item) => (
                <Box display={"flex"}>
                  {item.map((item2) => (
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      justifyContent={"space-evenly"}
                      width={`calc((100% / ${albumAmount}) - ${theme.spacing(
                        2
                      )}px)`}
                      minHeight={250}
                      margin={`0 ${theme.spacing(1)}px`}
                    >
                      <CardActionArea
                        onClick={() =>
                          history.push(`/album/${item2.id}`, { id: item2.id })
                        }
                      >
                        <AspectRatioBox widthSize={16} heightSize={9}>
                          <img
                            src={item2.image}
                            style={{
                              borderRadius: theme.shape.borderRadius,
                              height: "100%",
                              width: "100%",
                              objectFit: "cover",
                            }}
                          />
                        </AspectRatioBox>
                        <Typography
                          noWrap
                          align="center"
                          classes={{ root: classes.ContentText }}
                        >
                          {item2.name}
                        </Typography>
                      </CardActionArea>
                    </Box>
                  ))}
                </Box>
              ))}
            </SwipeableViews>
            {imageIndex > 0 && (
              <Button
                disableElevation
                variant={"contained"}
                color={"primary"}
                classes={{ root: classes.PageButton }}
                style={{ left: 0 }}
                onClick={() => setImageIndex((e) => e - 1)}
              >
                <ChevronLeftIcon />
              </Button>
            )}
            {imageIndex < albumDataGroup(albumData, albumAmount).length - 1 && (
              <Button
                disableElevation
                variant={"contained"}
                color={"primary"}
                classes={{ root: classes.PageButton }}
                style={{ right: 0 }}
                onClick={() => setImageIndex((e) => e + 1)}
              >
                <ChevronRightIcon />
              </Button>
            )}
          </Box>
        </Card>
      </Grid>
    </Grid>
  );
}
