import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  ButtonBase,
  makeStyles,
  useTheme,
  Typography,
  useMediaQuery,
  CircularProgress,
  Backdrop,
} from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { useLazyQuery, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import WebLayout from "../components/WebLayout";
import { useAlert } from "./../console/components/Alert";
import AspectRatioBox from "../components/AspectRatioBox";

const GET_ALBUMS = gql`
  query GetAlbums($latest: Boolean) {
    albums(latest: $latest) {
      id
      name
      coverIndex
      files {
        filename
        mimetype
        encoding
        location
      }
    }
  }
`;

const GET_ALBUM = gql`
  query GetAlbum($id: ID!) {
    album(id: $id) {
      id
      name
      coverIndex
      files {
        filename
        mimetype
        encoding
        location
      }
    }
  }
`;

export default function Album() {
  const Alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const mobileSize = useMediaQuery(theme.breakpoints.down("xs"));
  const [id, setId] = useState(null);
  const useStyles = makeStyles({
    AlbumCover: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: theme.shape.borderRadius * 2,
      overflow: "hidden",
      width: "100%",
      position: "relative",
    },
    AlbumTitleArea: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
      height: "100%",
      position: "absolute",
      color: "white",
      transition: "all 0.2s",
      backgroundColor: `rgb(0,0,0,0.4)`,
      "&:hover": {
        backgroundColor: `rgb(0,0,0,0.2)`,
      },
    },
    Loading: {
      zIndex: theme.zIndex.drawer + 1,
      color: "white",
    },
  });
  const classes = useStyles();
  const [albumArray, setAlbumArray] = useState([]);
  const { loading } = useQuery(GET_ALBUMS, {
    fetchPolicy: "network-only",
    variables: { latest: false },
    onCompleted({ albums }) {
      if (albums) {
        albums.map((albumsItem, albumsIndex) => {
          albumArray.push({
            id: albumsItem.id,
            title: albumsItem.name,
            image:
              albumsItem.files[0] &&
              (albumsItem.coverIndex || albumsItem.coverIndex == 0) &&
              albumsItem.files[albumsItem.coverIndex]
                ? albumsItem.files[albumsItem.coverIndex].location
                : require("../images/backgroundImage.jpg"),
          });
        });
        setAlbumArray((e) => [...e]);
      }
    },
  });

  const [getAlbum, { loading: albumLoading }] = useLazyQuery(GET_ALBUM, {
    fetchPolicy: "network-only",
    variables: { id },
    onCompleted({ album }) {
      if (Boolean(album.files[0])) {
        history.push(`${location.pathname}/${id}`, {
          id: id,
        });
      } else {
        Alert.alert("", "相簿為空！", [
          { text: "確定", onClick: () => setId(undefined), type: "ok" },
        ]);
      }
    },
  });

  useEffect(() => {
    if (Boolean(id)) {
      getAlbum();
    }
  }, [id]);
  return (
    <WebLayout>
      {loading ? (
        <Grid container justify="center">
          <CircularProgress color="secondary" />
        </Grid>
      ) : (
        <Grid container spacing={mobileSize ? 1 : 2}>
          <Backdrop open={albumLoading} classes={{ root: classes.Loading }}>
            <CircularProgress color="inherit" />
          </Backdrop>
          {albumArray.map((item) => (
            <Grid item xs={6} sm={4} md={3}>
              <ButtonBase
                classes={{ root: classes.AlbumCover }}
                onClick={() => {
                  setId(item.id);
                }}
              >
                <AspectRatioBox widthSize={16} heightSize={9}>
                  <img
                    src={item.image}
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                </AspectRatioBox>
                <Box classes={{ root: classes.AlbumTitleArea }}>
                  <Typography>{item.title}</Typography>
                </Box>
              </ButtonBase>
            </Grid>
          ))}
        </Grid>
      )}
    </WebLayout>
  );
}
