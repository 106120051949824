import React, { useState } from "react";
import {
  Grid,
  Typography,
  Card,
  CardActionArea,
  CardContent,
  Box,
  Container,
  useMediaQuery,
  useTheme,
  makeStyles,
  Link,
} from "@material-ui/core";
import moment from "moment";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import TitleBox from "./TitleBox";
import AspectRatioBox from "./AspectRatioBox";
import { useHistory, useLocation, useParams } from "react-router-dom";

const GET_EVENTS = gql`
  query GetEvents($category: ArticleCategory, $ignored: [ID]) {
    articles(category: $category, ignored: $ignored) {
      id
      title
      source
      picture {
        filename
        mimetype
        encoding
        location
      }
      description
      content
      createdAt
    }
  }
`;

export default function PostPage({ data }) {
  const history = useHistory();
  const location = useLocation();
  const theme = useTheme();
  const { id } = useParams();
  const pcSize = useMediaQuery(theme.breakpoints.up("lg"));
  const tabletSize = useMediaQuery(theme.breakpoints.down("md"));
  const [recommend, setRecommend] = useState([]);
  function shuffle(array) {
    var arrayLength = array.length,
      randomObject,
      randomNumber;
    // 如果還剩有元素…
    while (arrayLength) {
      // 隨機選取一個元素…
      randomNumber = Math.floor(Math.random() * arrayLength--);
      // 與當前元素進行交換
      randomObject = array[arrayLength];
      array[arrayLength] = array[randomNumber];
      array[randomNumber] = randomObject;
    }
    return array;
  }
  useQuery(GET_EVENTS, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    variables: {
      category: location.pathname.split("/")[1].toUpperCase(),
      ignored: [`${id}`],
    },
    onCompleted({ articles }) {
      if (articles.length === 1) {
        setRecommend(shuffle(articles).slice(0, 1));
      } else if (articles.length === 2) {
        setRecommend(shuffle(articles).slice(0, 2));
      } else if (articles.length >= 3) {
        setRecommend(shuffle(articles).slice(0, 3));
      }
    },
  });
  const useStyles = makeStyles({
    RecommendTitleText: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      display: "-webkit-box",
      "-webkit-line-clamp": 2,
      "-webkit-box-orient": "vertical",
    },
    URLText: {
      wordBreak: "break-all",
    },
  });
  const classes = useStyles();

  const URLtoLink = (source) => {
    if (source.startsWith("https://") || source.startsWith("http://")) {
      return (
        <Link
          href={source}
          target="_blank"
          rel="noopener"
          classes={{ root: classes.URLText }}
        >
          {source}
        </Link>
      );
    } else {
      return <Typography display="inline">{source}</Typography>;
    }
  };
  return (
    <Grid container spacing={2}>
      <Grid container item xs={12} md={Boolean(recommend[0]) ? 9 : 12}>
        <Container
          maxWidth={(() => {
            if (pcSize) {
              return "md";
            } else {
              if (tabletSize) {
                return "sm";
              } else {
                return false;
              }
            }
          })()}
        >
          <TitleBox title={data.title}>
            <Grid container direction={"column"}>
              <Box
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                style={{
                  color: theme.palette.text.hint,
                }}
              >
                <Typography variant={"subtitle1"} gutterBottom align="center">
                  <Typography display="inline">來源：</Typography>
                  {URLtoLink(data.source)}
                </Typography>
                <Typography variant={"subtitle1"} gutterBottom>
                  {moment(data.createdAt).format("YYYY.MM.DD")}
                </Typography>
              </Box>
              <AspectRatioBox widthSize={16} heightSize={9}>
                <img
                  src={data.picture && data.picture.location}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              </AspectRatioBox>
              <Box margin={`${theme.spacing(1)}px 0 0 0`}>
                <Typography paragraph>
                  {data.content &&
                    data.content.split("\n").map((line) => (
                      <>
                        <br />
                        <span>{line}</span>
                      </>
                    ))}
                </Typography>
              </Box>
            </Grid>
          </TitleBox>
        </Container>
      </Grid>
      {Boolean(recommend[0]) && (
        <TitleBox title={"推薦文章"} xs={12} md={3}>
          <Grid container spacing={1} justify={"center"}>
            {recommend.map((item) => (
              <Grid item xs={12} sm={6} md={12}>
                <Card variant={"outlined"}>
                  <CardActionArea
                    onClick={() =>
                      history.push(
                        `${location.pathname.split("/")[0]}/${
                          location.pathname.split("/")[1]
                        }/${item.id}`
                      )
                    }
                  >
                    <AspectRatioBox widthSize={16} heightSize={9}>
                      <img
                        src={item.picture.location}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        }}
                      />
                    </AspectRatioBox>
                    <CardContent>
                      <Typography
                        classes={{ root: classes.RecommendTitleText }}
                      >
                        {item.title}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
        </TitleBox>
      )}
    </Grid>
  );
}
