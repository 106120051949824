import React, { useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";
import { useHistory } from "react-router-dom";

import WebLayout from "../components/WebLayout";
import PostTable from "../components/PostTable";
import { useAlert } from "./../components/Alert";

const GET_HYPNOTISTS = gql`
  query GetHypnotists($latest: Boolean) {
    hypnotists(latest: $latest) {
      id
      picture {
        filename
        mimetype
        encoding
        location
      }
      email
      address
      line
      facebook
      name
      content
      createdAt
    }
  }
`;

const REMOVE_HYPNOTIST = gql`
  mutation RemoveHypnotist($id: ID!) {
    removeHypnotist(id: $id) {
      success
      message
    }
  }
`;

export default function ConsoleCourseListPage() {
  const Alert = useAlert();
  const [hypnotistId, setHypnotistId] = useState(null);
  const history = useHistory();
  const { data, refetch, loading } = useQuery(GET_HYPNOTISTS, {
    fetchPolicy: 'network-only',
    variables: { latest: true },
  });
  const [_removeHypnotist] = useMutation(REMOVE_HYPNOTIST);
  function removeHypnotist(id) {
    Alert.alert("", "確定要刪除？", [
      {
        text: "確定",
        onClick: () => {
          _removeHypnotist({
            variables: {
              id: id
            },
          })
          .then(({ data }) => {
            if (data.removeHypnotist.success) {
              Alert.alert("", "刪除成功", [
                {
                  text: "確定",
                  onClick: () => {
                    refetch()
                  },
                  type: "ok",
                },
              ]);
            } else {
              Alert.alert("", "刪除失敗，請再次嘗試。", [
                { text: "確定", type: "ok" },
              ]);
            }
          })
        },
        type: "ok",
      },
      {
        text: "取消"
      }
    ]);
  }
  return (
    <WebLayout
      title={"尋找催眠師"}
      onAdd={() => history.push("/console/hypnotist/add")}
    >
      <PostTable
        hypnotist={data ? data.hypnotists : []}
        onEdit={(e) => {
          history.push(`/console/hypnotist/${e}`)
        }}
        onDelete={(id) => {
          setHypnotistId(id);
          
          removeHypnotist(id);
        }}
      />
    </WebLayout>
  );
}
