import React, { useState } from "react";

import WebLayout from "../components/WebLayout";
import TitleBox from "../components/TitleBox";
import {
  Grid,
  Typography,
  Button,
  withStyles,
  useTheme,
  CardContent,
  Box,
  CircularProgress,
  Link,
} from "@material-ui/core";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { ReactComponent as AdminPanelSettingsIcon } from "../images/adminPanelSettings.svg";
import { ReactComponent as AssignmentIcon } from "../images/assignment.svg";
import { ReactComponent as FeedbackIcon } from "../images/feedback.svg";

const GET_MEMBER_CONTENT = gql`
  query GetMemberContent($name: String!) {
    content(name: $name) {
      body
    }
  }
`;

export default function Memeber() {
  const theme = useTheme();
  const iconSetting = { width: 50, height: 50 };
  const TextContent = withStyles({
    root: { textJustify: "inter-character", textAlign: "justify" },
  })(({ classes, color, ...other }) => (
    <Typography classes={classes} {...other} paragraph />
  ));
  const [title, setTitle] = useState("");
  const [renderLoading, setRenderLoading] = useState(true);
  useQuery(GET_MEMBER_CONTENT, {
    fetchPolicy: "network-only",
    variables: { name: "MEMBER_TITLE" },
    onCompleted({ content }) {
      if (content && content.body) {
        setTitle(content.body);
      }
      getContent2();
    },
  });

  const [content, setContent] = useState();
  const [getContent2] = useLazyQuery(GET_MEMBER_CONTENT, {
    fetchPolicy: "network-only",
    variables: { name: "MEMBER_CONTENT" },
    onCompleted({ content }) {
      if (content && content.body) {
        setContent(content.body);
      }
      getContent3();
    },
  });
  const [paymentRules, setPaymentRules] = useState();
  const [getContent3] = useLazyQuery(GET_MEMBER_CONTENT, {
    fetchPolicy: "network-only",
    variables: { name: "MEMBER_PAYMENT_RULES" },
    onCompleted({ content }) {
      if (content && content.body) {
        setPaymentRules(content.body);
      }
      getContent4();
    },
  });
  const [sponsorRules, setSponsorRules] = useState();
  const [getContent4] = useLazyQuery(GET_MEMBER_CONTENT, {
    fetchPolicy: "network-only",
    variables: { name: "MEMBER_SPONSOR_RULES" },
    onCompleted({ content }) {
      if (content && content.body) {
        setSponsorRules(content.body);
      }
      getContent5();
    },
  });
  const [reminder, setReminder] = useState();
  const [getContent5] = useLazyQuery(GET_MEMBER_CONTENT, {
    fetchPolicy: "network-only",
    variables: { name: "MEMBER_REMINDER" },
    onCompleted({ content }) {
      if (content && content.body) {
        setReminder(content.body);
      }
      setRenderLoading(false);
    },
  });

  function SideCard({ children }) {
    return (
      <Box width={"100%"}>
        <CardContent
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {children}
        </CardContent>
      </Box>
    );
  }

  return (
    <WebLayout>
      <TitleBox title={"催眠師協會會員入會方式"}>
        <Grid
          container
          item
          spacing={2}
          style={{ padding: `${theme.spacing(2)}px 0` }}
        >
          {renderLoading ? (
            <Grid container justify="center">
              <CircularProgress color="secondary" />
            </Grid>
          ) : (
            <>
              <Grid item xs={12} md={8}>
                <Typography variant={"h5"} align="center" paragraph>
                  {title}
                </Typography>

                {content &&
                  content
                    .split("\n")
                    .map((contentData) => (
                      <TextContent>{contentData}</TextContent>
                    ))}
              </Grid>
              <Grid container item xs={12} md={4} spacing={1}>
                <Grid container item justify="center">
                  <Link
                    href="/113年亞洲NGH催眠師協會正式會員入會申請書.docx"
                    underline="none"
                    style={{ width: "100%" }}
                  >
                    <Button size={"large"} color={"secondary"} fullWidth>
                      填寫報名表單
                    </Button>
                  </Link>
                </Grid>
                <Grid container item xs={12} sm={6} md={12}>
                  <SideCard>
                    <AdminPanelSettingsIcon {...iconSetting} />
                    <br />
                    <Typography>{paymentRules}</Typography>
                  </SideCard>
                </Grid>
                <Grid container item xs={12} sm={6} md={12}>
                  <SideCard>
                    <AssignmentIcon {...iconSetting} />
                    <br />
                    {sponsorRules &&
                      sponsorRules
                        .split("\n")
                        .map((sponsorRulesData) => (
                          <TextContent>{sponsorRulesData}</TextContent>
                        ))}
                  </SideCard>
                </Grid>
                <Grid container item xs={12} sm={6} md={12}>
                  <SideCard>
                    <FeedbackIcon {...iconSetting} />
                    <br />
                    {reminder &&
                      reminder
                        .split("\n")
                        .map((reminderData) => (
                          <TextContent>{reminderData}</TextContent>
                        ))}
                  </SideCard>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
      </TitleBox>
    </WebLayout>
  );
}
