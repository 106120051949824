import React, { useEffect, useState } from "react";
import {
  Button,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
  Checkbox,
  MenuItem,
  useTheme,
  makeStyles,
  Backdrop,
  CircularProgress,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import ReCAPTCHA from "react-google-recaptcha";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";
import moment from "moment";

import WebLayout from "../components/WebLayout";
import TitleBox from "../components/TitleBox";
import divisions from "../divisions.json";
import { useAlert } from "./../console/components/Alert";

const COUNSELING_ROOMS = gql`
  query counselingRooms($division: Division) {
    counselingRooms(division: $division) {
      id
      name
      division
      address
    }
  }
`;

const RESERVATIONS = gql`
  query reservations(
    $startDate: Date
    $endDate: Date
    $counselingRoomId: ID
    $latest: Boolean
  ) {
    reservations(
      startDate: $startDate
      endDate: $endDate
      counselingRoomId: $counselingRoomId
      latest: $latest
    ) {
      id
      date
      time
    }
  }
`;

const RESERVE_COUNSELINGROOM = gql`
  mutation reserveCounselingRoom($reservationInput: ReservationInput!) {
    reserveCounselingRoom(reservationInput: $reservationInput) {
      id
    }
  }
`;

export default function ReservationPage() {
  const Alert = useAlert();
  const theme = useTheme();
  const useStyles = makeStyles({
    Loading: {
      zIndex: theme.zIndex.drawer + 1,
      color: "white",
    },
  });
  const classes = useStyles();
  const [applicant, setApplicant] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [division, setDivision] = useState("");
  const [time, setTime] = useState(undefined);
  const [counselingRoomId, setCounselingRoomId] = useState("");
  const [counselingRoomArray, setCounselingRoomArray] = useState([]);
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [membership, setMembership] = useState(false);
  const [agree, setAgree] = useState(false);
  const [recaptcha, setRecaptcha] = useState(false);
  const [findMORNING, setFindMORNING] = useState();
  const [findAFTERNOON, setFindAFTERNOON] = useState();
  const [findNIGHT, setFindNIGHT] = useState();
  const timeTypeArray = [
    { title: "上午\u200310:00~13:00", value: "MORNING" },
    { title: "下午\u200314:00~17:00", value: "AFTERNOON" },
    { title: "晚上\u200318:00~21:00", value: "NIGHT" },
  ];

  const { data, loading: counselingRoomsLoading } = useQuery(COUNSELING_ROOMS, {
    fetchPolicy: "network-only",
    variables: {
      division,
    },
  });
  useEffect(() => {
    if (data) {
      data.counselingRooms.map((item, index) => {
        delete item.__typename;
        counselingRoomArray.push({ id: item.id, name: item.name });
      });
      setCounselingRoomArray((e) => [...e]);
    }
  }, [data]);

  useQuery(
    RESERVATIONS,
    {
      fetchPolicy: "network-only",
      notifyOnNetworkStatusChange: true,
      variables: {
        startDate: date,
        endDate: date,
        counselingRoomId,
        latest: true,
      },
      onCompleted({ reservations }) {
        let newCheck = reservations.map((item) => {
          return timeTypeArray.find((item2) => item2.value === item.time);
        });
        let newFindMORNING = newCheck.find(
          (item) => item.value === "MORNING" && counselingRoomId
        );
        let newFindAFTERNOON = newCheck.find(
          (item) => item.value === "AFTERNOON" && counselingRoomId
        );
        let newFindNIGHT = newCheck.find(
          (item) => item.value === "NIGHT" && counselingRoomId
        );
        setFindMORNING(newFindMORNING);
        setFindAFTERNOON(newFindAFTERNOON);
        setFindNIGHT(newFindNIGHT);
      },
    }
  );
  useEffect(() => {
    setTime(undefined);
  }, [date, counselingRoomId]);
  useEffect(() => {
    setCounselingRoomId("");
    setTime(undefined);
  }, [division]);
  const checked = Boolean(
    applicant &&
      phoneNumber &&
      division &&
      time &&
      counselingRoomId &&
      agree &&
      recaptcha
  );
  function sendOut() {
    reserveCounselingRoom();
  }
  const [reserveCounselingRoom, { loading }] = useMutation(
    RESERVE_COUNSELINGROOM,
    {
      variables: {
        reservationInput: {
          applicant,
          phoneNumber,
          division,
          counselingRoomId,
          date: moment(date).format("YYYY-MM-DD"),
          time,
          membership,
        },
      },
      onCompleted({ reserveCounselingRoom }) {
        if (reserveCounselingRoom.id) {
          Alert.alert("", "預約成功", [
            {
              text: "確定",
              onClick: () => {
                setApplicant("");
                setPhoneNumber("");
                setDivision("");
                setTime(undefined);
                setCounselingRoomId("");
                setCounselingRoomArray([]);
                setDate(moment().format("YYYY-MM-DD"));
                setMembership(false);
                setAgree(false);
                setRecaptcha(false);
                setFindMORNING(undefined);
                setFindAFTERNOON(undefined);
                setFindNIGHT(undefined);
              },
              type: "ok",
            },
          ]);
        } else {
          Alert.alert("", "儲存失敗，請重新輸入後再次嘗試。", [
            { text: "確定", type: "ok" },
          ]);
        }
      },
      onError(error) {
        Alert.alert("", `${error}`, [
          {
            text: "確定",
            type: "ok",
          },
        ]);
      },
    }
  );
  return (
    <WebLayout>
      <TitleBox title="填寫預約資料">
        <Backdrop open={loading} classes={{ root: classes.Loading }}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Grid container spacing={1} direction="column">
          <Grid item>
            <Typography gutterBottom>預約人姓名</Typography>
            <TextField
              value={applicant}
              onChange={(e) => setApplicant(e.target.value)}
              variant="outlined"
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item>
            <Typography gutterBottom>預約電話</Typography>
            <TextField
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              variant="outlined"
              size="small"
              fullWidth
            />
          </Grid>
          <Grid item>
            <Typography gutterBottom>選擇地區</Typography>
            <Select
              value={division}
              onChange={(e) => setDivision(e.target.value)}
              variant="outlined"
              size="small"
              fullWidth
              displayEmpty
            >
              <MenuItem value="" disabled>
                請選擇
              </MenuItem>
              {Object.keys(divisions)
                .filter((eItem) => eItem === "TAIPEI_CITY")
                .map((item) => (
                  <MenuItem value={item}>{divisions[item].zhTW}</MenuItem>
                ))}
            </Select>
          </Grid>
          <Grid item>
            <Typography gutterBottom>選擇諮詢室</Typography>
            <Select
              value={counselingRoomId}
              onChange={(e) => setCounselingRoomId(e.target.value)}
              variant="outlined"
              size="small"
              displayEmpty
              fullWidth
            >
              <MenuItem value="" disabled>
                請選擇
              </MenuItem>
              {counselingRoomsLoading ? (
                <MenuItem value="" disabled>
                  載入中
                </MenuItem>
              ) : (
                counselingRoomArray.map((item) => (
                  <MenuItem value={item.id}>{item.name}</MenuItem>
                ))
              )}
            </Select>
          </Grid>
          <Grid item>
            <Typography gutterBottom>預約日期</Typography>
            <DatePicker
              inputVariant="outlined"
              size="small"
              fullWidth
              value={date}
              onChange={setDate}
            />
          </Grid>
          <Grid item>
            <Typography gutterBottom>選擇時段</Typography>
            <Grid container spacing={1}>
              {timeTypeArray.map((item) => {
                return (
                  <Grid item>
                    <Button
                      variant={time === item.value ? "contained" : "outlined"}
                      color={time === item.value ? "primary" : "default"}
                      onClick={() => setTime(item.value)}
                      disabled={
                        item.value === "MORNING"
                          ? findMORNING
                          : false || item.value === "AFTERNOON"
                          ? findAFTERNOON
                          : false || item.value === "NIGHT"
                          ? findNIGHT
                          : false
                      }
                    >
                      {item.title}
                    </Button>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid item>
            <Typography gutterBottom>是否為催眠師協會會員</Typography>
            <Typography variant="caption">
              非本協會催眠師需同時繳交國際催眠證照才可租借諮詢室
            </Typography>
            <RadioGroup
              value={`${membership}`}
              onChange={(e) => setMembership(JSON.parse(e.target.value))}
            >
              <FormControlLabel
                value={`${true}`}
                control={<Radio />}
                label="是，每小時費用400元"
              />
              <FormControlLabel
                value={`${false}`}
                control={<Radio />}
                label="否，每小時原定費用800元，現在優惠僅500元，至109/12/31日止"
              />
            </RadioGroup>
          </Grid>
          <Grid container item>
            <Button
              color="secondary"
              variant="contained"
              fullWidth
              size="large"
              target="_blank"
              rel="noopener"
              href="/rental"
            >
              諮詢室租用預約規範
            </Button>
          </Grid>
          <Grid container item justify="center">
            <FormControlLabel
              control={
                <Checkbox
                  checked={agree}
                  onChange={(e) => setAgree(e.target.checked)}
                />
              }
              label="已詳閱並同意相關規範"
            />
          </Grid>
          <Grid container item justify="center">
            <ReCAPTCHA
              sitekey="6Lf8gdsZAAAAAD6u8IWFhMWu9o-RWv0-XZz163sP"
              onChange={(e) => setRecaptcha(Boolean(e))}
            />
          </Grid>
          <Grid container item justify="center">
            <Button
              color="primary"
              variant="contained"
              disabled={!checked}
              onClick={sendOut}
            >
              送出
            </Button>
          </Grid>
        </Grid>
      </TitleBox>
    </WebLayout>
  );
}
