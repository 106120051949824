import React, { useState, useEffect, useLayoutEffect, useReducer } from "react";
import {
  Box,
  Hidden,
  Container,
  useTheme,
  List,
  ListItem,
  makeStyles,
  Typography,
  Toolbar,
  AppBar,
  IconButton,
  SwipeableDrawer,
  useMediaQuery,
  useScrollTrigger,
  Slide,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { ReactComponent as HomeMenuIcon1 } from "../images/homeMenuIcon1.svg";
import { ReactComponent as HomeMenuIcon2 } from "../images/homeMenuIcon2.svg";
import { ReactComponent as HomeMenuIcon3 } from "../images/homeMenuIcon3.svg";
import { ReactComponent as HomeMenuIcon4 } from "../images/homeMenuIcon4.svg";
import { ReactComponent as HomeMenuIcon5 } from "../images/homeMenuIcon5.svg";
import { ReactComponent as HomeMenuIcon6 } from "../images/homeMenuIcon6.svg";
import { ReactComponent as HomeMenuIcon7 } from "../images/homeMenuIcon7.svg";
import { ReactComponent as HomeMenuIcon8 } from "../images/homeMenuIcon8.svg";
import { ReactComponent as HomeMenuIcon9 } from "../images/homeMenuIcon9.svg";
import { ReactComponent as HomeMenuIcon10 } from "../images/homeMenuIcon10.svg";
import { ReactComponent as LogoIcon } from "../images/logo.svg";
import { useHistory, useLocation } from "react-router-dom";
import { UpdateButton, LogoutButton, AddButton } from "./Buttons";
import { useAlert } from "./Alert";

const menuColor = "#4C4743";
const baseColor = "#F8F3F2";
const iconSize = { height: 24, width: 24 };

export default function WebLayout({ children, title, onUpdate, onAdd }) {
  const history = useHistory();
  useLayoutEffect(() => {
    if (!localStorage.getItem("token")) {
      history.replace("/console/login");
    }
  }, []);
  const trigger = useScrollTrigger();
  const triggerOnTop = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });
  const theme = useTheme();
  const deviceSize = useMediaQuery(theme.breakpoints.down("sm"));
  const useStyles = makeStyles({
    Container: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
      marginTop: theme.spacing(2),
      paddingBottom: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        paddingBottom: theme.spacing(1),
        marginTop: theme.spacing(1),
      },
      position: "relative",
    },
    TitleText: {
      color: baseColor,
    },
    Toolbar: {
      color: "white",
      display: "flex",
      justifyContent: "space-between",
      backgroundColor: theme.palette.primary.main,
    },
  });
  const classes = useStyles();
  const showHeaderBar = Boolean(onUpdate || onAdd);
  const [openDrawer, setOpenDrawer] = useState(false);
  useEffect(() => {
    setOpenDrawer(false);
  }, [deviceSize]);
  const Alert = useAlert();
  function _onUpdate() {
    Alert.alert("", "確認更新？", [
      { text: "確定", onClick: () => onUpdate(), type: "ok" },
      { text: "取消" },
    ]);
  }
  function _onAdd() {
    onAdd();
  }

  function HeaderBar() {
    return (
      <Box
        display="flex"
        position={"sticky"}
        top={0}
        height={75}
        alignItems="center"
        justifyContent="flex-end"
        bgcolor="white"
        boxSizing="border-box"
        padding={`0 ${theme.spacing(1)}px`}
        zIndex={1}
        boxShadow={triggerOnTop && `1px 1px 1px rgb(0,0,0,0.2)`}
      >
        {onAdd && <AddButton onClick={() => _onAdd()} />}
        {onUpdate && <UpdateButton onClick={_onUpdate} />}
      </Box>
    );
  }

  return (
    <>
      <Hidden mdUp>
        <Toolbar />
        {showHeaderBar && <Toolbar style={{ height: 75 }} />}
        <AppBar color="transparent" elevation={false}>
          <Slide in={!trigger} mountOnEnter unmountOnExit>
            <Toolbar classes={{ root: classes.Toolbar }}>
              <Typography variant={"h6"}>{title}</Typography>
              <IconButton onClick={() => setOpenDrawer(true)}>
                <MenuIcon style={{ color: "white" }} />
              </IconButton>
            </Toolbar>
          </Slide>
          {showHeaderBar && <HeaderBar />}
        </AppBar>
        <WebDrawer
          open={openDrawer}
          onOpen={() => setOpenDrawer(true)}
          onClose={() => setOpenDrawer(false)}
        >
          <HomeMenuItem />
        </WebDrawer>
      </Hidden>
      <Box display="flex" height={"100%"} minHeight={"100vh"} width={"100%"}>
        <Hidden smDown>
          <Box bgcolor={menuColor} width={225} position="relative">
            <HomeMenuItem />
          </Box>
        </Hidden>
        <Container
          maxWidth={false}
          disableGutters
          style={{ backgroundColor: baseColor }}
        >
          <Hidden smDown>
            <HeaderBar />
          </Hidden>
          <Container maxWidth={false} classes={{ root: classes.Container }}>
            {children}
          </Container>
        </Container>
      </Box>
    </>
  );
}

function HomeMenuItem() {
  const Alert = useAlert();
  const history = useHistory();
  const location = useLocation();
  const sameRoute = (e) => location.pathname == e;
  const theme = useTheme();
  const useStyles = makeStyles({
    List: { display: "flex", flexDirection: "column", flex: 1, width: "100%" },
    HomeMenuListItem: {
      color: baseColor,
      "&.Mui-disabled": {
        opacity: 1,
        backgroundColor: baseColor,
        color: menuColor,
      },
    },
  });
  const classes = useStyles();
  const HomeMenuArray = [
    {
      title: "協會介紹",
      route: "/console/about",
      icon: HomeMenuIcon2,
    },
    {
      title: "會員諮詢室預約",
      route: "/console/reservation",
      icon: HomeMenuIcon1,
    },
    {
      title: "會員資訊",
      route: "/console/member",
      icon: HomeMenuIcon2,
    },
    {
      title: "活動訊息",
      route: "/console/event",
      icon: HomeMenuIcon3,
    },
    {
      title: "心靈雞湯",
      route: "/console/story",
      icon: HomeMenuIcon4,
    },
    {
      title: "相關文章",
      route: "/console/essay",
      icon: HomeMenuIcon5,
    },
    {
      title: "研習活動報名",
      route: "/console/enrollment",
      icon: HomeMenuIcon6,
    },
    {
      title: "催眠師課程資訊",
      route: "/console/course",
      icon: HomeMenuIcon7,
    },
    {
      title: "活動花絮",
      route: "/console/album",
      icon: HomeMenuIcon8,
    },
    {
      title: "Q & A",
      route: "/console/faq",
      icon: HomeMenuIcon9,
    },
    {
      title: "尋找催眠師",
      route: "/console/hypnotist",
      icon: HomeMenuIcon10,
    },
  ];
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      justifyContent="space-between"
      position="sticky"
      top={0}
      height="100vh"
      width="inherit"
    >
      <Box
        display="flex"
        justifyContent="flex"
        alignItems="center"
        bgcolor={theme.palette.primary.main}
        height={75}
        width="100%"
        padding={2}
      >
        <LogoIcon height={"100%"} width={"100%"} fill={baseColor} />
      </Box>
      <List classes={{ root: classes.List }}>
        {HomeMenuArray.map((item) => {
          let selectItem = sameRoute(item.route);
          return (
            <ListItem
              button
              classes={{ root: classes.HomeMenuListItem }}
              disabled={selectItem}
              onClick={() => history.push(item.route)}
            >
              {React.createElement(item.icon, {
                ...iconSize,
                style: { marginRight: theme.spacing(2) },
                fill: selectItem ? menuColor : baseColor,
              })}
              <Typography>{item.title}</Typography>
            </ListItem>
          );
        })}
      </List>
      <LogoutButton
        style={{ margin: theme.spacing(1) }}
        onClick={() => {
          Alert.alert("", "確定要登出？", [
            {
              text: "確定",
              onClick: () => {
                localStorage.removeItem("token");
                history.push("/console/login");
              },
              type: "ok",
            },
            {
              text: "取消",
            },
          ]);
        }}
      />
    </Box>
  );
}

function WebDrawer({ children, ...props }) {
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const useStyles = makeStyles({
    Drawer: {
      backgroundColor: menuColor,
      width: "80%",
      maxWidth: 300,
    },
  });
  const classes = useStyles();
  return (
    <SwipeableDrawer
      anchor={"right"}
      disableScrollLock
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      classes={{ paper: classes.Drawer }}
      {...props}
    >
      <Box width="100%">{children}</Box>
    </SwipeableDrawer>
  );
}
