import React from "react";
import { Box, Grid, Button, InputBase, useTheme } from "@material-ui/core";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { ReactComponent as AddImageIcon } from "../images/addImage.svg";
import { useAlert } from "./Alert";
import CardBox from "./CardBox";
import { DeleteButton } from "./Buttons";

const UPLOAD_IMAGE = gql`
  mutation UploadImage($image: Upload!) {
    uploadImage(image: $image) {
      filename
      mimetype
      encoding
      location
    }
  }
`;

export default function HypnotistEdit({ hypnotists, onChangeData }) {
  const theme = useTheme();
  const CardSize = { flex: 1, margin: theme.spacing(0.5) };
  const Alert = useAlert();
  function _DeleteImage() {
    Alert.alert("", "確定要刪除照片？", [
      {
        text: "確定",
        onClick: () => onChangeData({ type: "deleteImage", value: null }),
        type: "ok",
      },
      { text: "取消" },
    ]);
  }
  const [uploadFile] = useMutation(UPLOAD_IMAGE, {
    onCompleted({ uploadImage }) {
      delete uploadImage.__typename;
      onChangeData({ type: "uploadImage", value: uploadImage });
    },
  });
  return (
    <Grid container>
      <Grid container item direction="column" xs={12} sm={6}>
        <Grid item>
          <CardBox
            title={"上傳圖片"}
            style={CardSize}
            buttons={[
              hypnotists.picture && (
                <DeleteButton title={"圖片"} onClick={_DeleteImage} />
              ),
            ]}
          >
            <Box display="flex" flex={1} height={200}>
              {hypnotists.picture ? (
                <img
                  src={hypnotists.picture.location}
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              ) : (
                <Button
                  variant="contained"
                  color="secondary"
                  disableElevation
                  style={{ display: "flex", flex: 1 }}
                  for="certificateUpload"
                  component="label"
                >
                  <AddImageIcon width={50} height={50} fill={"white"} />
                  <input
                    type="file"
                    id="certificateUpload"
                    style={{ display: "none" }}
                    onChange={(event) => {
                      const {
                        target: { validity, files },
                      } = event;

                      if (validity.valid) {
                        for (let file of files) {
                          uploadFile({ variables: { image: file } });
                        }
                      }
                    }}
                  />
                </Button>
              )}
            </Box>
          </CardBox>
        </Grid>
        <Grid container item>
          <Grid item xs={12}>
            <CardBox title={"聯絡信箱"} style={CardSize}>
              <InputBase
                value={hypnotists.email}
                onChange={(e) =>
                  onChangeData({ type: "email", value: e.target.value })
                }
                placeholder="請輸入聯絡信箱"
                fullWidth
              />
            </CardBox>
          </Grid>
          <Grid item xs={12}>
            <CardBox title={"地址"} style={CardSize}>
              <InputBase
                value={hypnotists.address}
                onChange={(e) =>
                  onChangeData({ type: "address", value: e.target.value })
                }
                placeholder="請輸入地址"
                fullWidth
              />
            </CardBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CardBox title={"預約方式"} style={CardSize}>
              <InputBase
                value={hypnotists.line}
                onChange={(e) =>
                  onChangeData({ type: "line", value: e.target.value })
                }
                placeholder="請輸入預約方式"
                fullWidth
              />
            </CardBox>
          </Grid>
          <Grid item xs={12} sm={6}>
            <CardBox title={"粉專"} style={CardSize}>
              <InputBase
                value={hypnotists.facebook}
                onChange={(e) =>
                  onChangeData({ type: "facebook", value: e.target.value })
                }
                placeholder="請輸入粉專"
                fullWidth
              />
            </CardBox>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item direction="column" xs={12} sm={6}>
        <Grid item>
          <CardBox title={"名稱"} style={CardSize}>
            <InputBase
              value={hypnotists.name}
              onChange={(e) =>
                onChangeData({ type: "name", value: e.target.value })
              }
              placeholder="請輸入名稱"
              fullWidth
            />
          </CardBox>
        </Grid>
        <CardBox title={"文案內容"} style={CardSize}>
          <InputBase
            value={hypnotists.content}
            onChange={(e) =>
              onChangeData({ type: "content", value: e.target.value })
            }
            placeholder="請輸入文案內容"
            rows={10}
            fullWidth
            multiline
          />
        </CardBox>
      </Grid>
    </Grid>
  );
}
