import React, { useState, useEffect } from "react";
import {
  GoogleMap,
  LoadScript,
  Marker,
  Circle,
  InfoWindow,
} from "@react-google-maps/api";
import {
  ButtonBase,
  makeStyles,
  CircularProgress,
  colors,
  Typography,
  Box,
} from "@material-ui/core";
import GpsFixedIcon from "@material-ui/icons/GpsFixed";
import { useHistory } from "react-router-dom";

export default function GoogleMaps({ style, data }) {
  const useStyles = makeStyles({
    GpsButton: {
      position: "absolute",
      right: 10,
      top: 60,
      height: 40,
      width: 40,
      borderRadius: 2,
      background: "rgb(255, 255, 255) none repeat scroll 0% 0%",
      boxShadow: "rgba(0, 0, 0, 0.3) 0px 1px 4px -1px",
      color: "#666666",
    },
  });
  const classes = useStyles();
  const [ownPosition, setOwnPosition] = useState(null);
  const containerStyle = {
    width: "100%",
    height: "100%",
    position: "relative",
  };
  function _getLocation() {
    navigator.geolocation.getCurrentPosition((e) => {
      setOwnPosition({ lat: e.coords.latitude, lng: e.coords.longitude });
    });
  }
  function _watchPosition() {
    navigator.geolocation.watchPosition((e) => {
      setOwnPosition({ lat: e.coords.latitude, lng: e.coords.longitude });
    });
  }
  useEffect(() => {
    _getLocation();
  }, []);
  const circleOptions = {
    strokeColor: colors.blue["A100"],
    strokeOpacity: 0.8,
    strokeWeight: 2,
    fillColor: colors.blue["A100"],
    fillOpacity: 0.35,
    clickable: false,
    draggable: false,
    editable: false,
    visible: true,
    radius: 500,
    zIndex: 1,
  };
  function createKey(location) {
    return location.lat + location.lng;
  }
  return (
    <LoadScript googleMapsApiKey="AIzaSyCI2xlLq3HhJedcQJRS8c2mtQgtZ71Az44">
      {ownPosition ? (
        <GoogleMap
          mapContainerStyle={style || containerStyle}
          center={ownPosition}
          zoom={15}
        >
          {/* Child components, such as markers, info windows, etc. */}
          <Circle center={ownPosition} options={circleOptions} />
          {data.map((item) => (
            <MakerItem data={item} key={createKey(item)} />
          ))}
          <ButtonBase
            variant="contained"
            classes={{ root: classes.GpsButton }}
            onClick={_watchPosition}
          >
            <GpsFixedIcon />
          </ButtonBase>
        </GoogleMap>
      ) : (
        <CircularProgress />
      )}
    </LoadScript>
  );
}

function MakerItem({ data }) {
  const history = useHistory();
  const [openWindow, setOpenWindow] = useState(true);
  return (
    <Marker
      position={data}
      onClick={() => {
        if (openWindow) {
          history.push(`/hypnotist/${data.id}`);
        } else {
          setOpenWindow(true);
        }
      }}
    >
      {openWindow && (
        <InfoWindow onCloseClick={() => setOpenWindow(false)}>
          <Box>
            <Typography>{data.title}</Typography>
          </Box>
        </InfoWindow>
      )}
    </Marker>
  );
}
