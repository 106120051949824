import React from "react";
import { Button, makeStyles, useTheme, colors } from "@material-ui/core";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";
import AddIcon from "@material-ui/icons/Add";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { ReactComponent as EditIcon } from "../images/edit.svg";
import { ReactComponent as SaveIcon } from "../images/save.svg";

const ButtonSize = { height: 40 };
const iconSetting = { height: 20, width: 20, fill: "white" };

export function LoginButton({ ...props }) {
  const theme = useTheme();
  const useStyles = makeStyles({
    Button: {
      ...ButtonSize,
      borderRadius: 20,
      "&.Mui-disabled":{
        backgroundColor:theme.palette.primary.main + '7f',
        color:theme.palette.action.disabled
      }
    },
  });
  const classes = useStyles();
  return (
    <Button
      color="primary"
      variant="contained"
      disableElevation
      fullWidth
      classes={{ root: classes.Button }}
      {...props}
    >
      登入
    </Button>
  );
}

export function LogoutButton({ ...props }) {
  const useStyles = makeStyles({
    Button: {
      ...ButtonSize,
    },
  });
  const classes = useStyles();
  return (
    <Button
      color="primary"
      variant="contained"
      disableElevation
      classes={{ root: classes.Button }}
      startIcon={<ExitToAppIcon {...iconSetting} />}
      {...props}
    >
      登出
    </Button>
  );
}

export function UpdateButton({ ...props }) {
  const useStyles = makeStyles({
    Button: {
      ...ButtonSize,
      color: "white",
      backgroundColor: "#4FB3AB",
      "&:hover": {
        backgroundColor: "#0a837c",
      },
    },
  });
  const classes = useStyles();
  return (
    <Button
      variant="contained"
      disableElevation
      classes={{ root: classes.Button }}
      {...props}
      startIcon={<SaveIcon {...iconSetting} />}
    >
      更新
    </Button>
  );
}

export function DeleteButton({ title, ...props }) {
  const theme = useTheme();
  const useStyles = makeStyles({
    Button: {
      ...ButtonSize,
      color: "white",
      backgroundColor: theme.palette.error.main,
      "&:hover": {
        backgroundColor: theme.palette.error.dark,
      },
    },
  });
  const classes = useStyles();
  return (
    <Button
      variant="contained"
      disableElevation
      classes={{ root: classes.Button }}
      {...props}
      startIcon={<DeleteOutlinedIcon {...iconSetting} />}
    >
      刪除{title}
    </Button>
  );
}
export function AddButton({ title, ...props }) {
  const useStyles = makeStyles({
    Button: {
      ...ButtonSize,
      color: "white",
      backgroundColor: "#5491AF",
      "&:hover": {
        backgroundColor: "#1e6380",
      },
    },
  });
  const classes = useStyles();
  return (
    <Button
      variant="contained"
      disableElevation
      classes={{ root: classes.Button }}
      {...props}
      startIcon={<AddIcon {...iconSetting} />}
    >
      新增{title}
    </Button>
  );
}
export function EditButton({ title, ...props }) {
  const useStyles = makeStyles({
    Button: {
      ...ButtonSize,
      color: "white",
      backgroundColor: "#4FB3AB",
      "&:hover": {
        backgroundColor: "#0a837c",
      },
    },
  });
  const classes = useStyles();
  return (
    <Button
      variant="contained"
      disableElevation
      classes={{ root: classes.Button }}
      {...props}
      startIcon={<EditIcon {...iconSetting} />}
    >
      編輯{title}
    </Button>
  );
}

export function ConfirmButton({ ...props }) {
  const useStyles = makeStyles({
    Button: {
      ...ButtonSize,
    },
  });
  const classes = useStyles();
  return (
    <Button
      variant="contained"
      color="primary"
      disableElevation
      classes={{ root: classes.Button }}
      {...props}
    >
      確認
    </Button>
  );
}
export function CancelButton({ ...props }) {
  const useStyles = makeStyles({
    Button: {
      ...ButtonSize,
    },
  });
  const classes = useStyles();
  return (
    <Button
      color="primary"
      disableElevation
      classes={{ root: classes.Button }}
      {...props}
    >
      取消
    </Button>
  );
}
