import React from "react";
import { makeStyles } from "@material-ui/core";

export default function AspectRatioBox({
  widthSize = 1,
  heightSize = 1,
  width = "100%",
  children,
}) {
  const useStyles = makeStyles({
    Box: {
      justifyContent: "center",
      position: "relative",
      paddingTop: `calc(${(heightSize / widthSize) * 100}%)`,
      width: "100%",
    },
    InSideBox: {
      display:'flex',
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    OutSideBox: {
      width,
    },
  });
  const classes = useStyles();
  return (
    <div className={classes.OutSideBox}>
      <div className={classes.Box}>
        <div className={classes.InSideBox}>{children}</div>
      </div>
    </div>
  );
}
