import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  ListItem,
  TextField,
  useTheme,
  makeStyles,
  Box,
} from "@material-ui/core";
import { useQuery, useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

import WebLayout from "../components/WebLayout";
import CardBox from "../components/CardBox";
import LoadingBox from "../components/LoadingBox";
import { DeleteButton, AddButton } from "../components/Buttons";
import { useAlert } from "./../components/Alert";

const GET_FAQ_CONTENT = gql`
  query GETFAQContent {
    content(name: "FAQ") {
      body
    }
  }
`;

const SET_FAQ_CONTENT = gql`
  mutation SetFAQContent($body: String!) {
    setContent(name: "FAQ", body: $body) {
      success
      message
    }
  }
`;

export default function ConsoleFaqPage() {
  const Alert = useAlert();
  const theme = useTheme();
  const useStyles = makeStyles({
    FaqListArea: {
      "& > *": {
        margin: `${theme.spacing(1)}px 0`,
      },
    },
  });
  const classes = useStyles();

  const [faqArray, setFaqArray] = useState(
    null
    // [{ question: "", answer: "" }, project底下為 {content:"內容"}]
  );
  const [nowDate, setNowDate] = useState(null); //用來刷新畫面
  //新增跳到最下方
  useEffect(() => {
    nowDate && _scrollBottom();
  }, [nowDate]);
  function _scrollBottom() {
    let anchor = document.body;
    anchor.scrollIntoView({
      block: "end",
      inline: "nearest",
      behavior: "smooth",
    });
  }
  function _addFaq() {
    if (faqArray) {
      setFaqArray([...faqArray, { question: "", answer: "" }]);
    } else {
      setFaqArray([{ question: "", answer: "" }]);
    }
    setNowDate(Date.now()); //解決畫面滾動問題
  }
  function _editAnswer(e, index) {
    let oldItem = faqArray[index];
    oldItem["answer"] = e;
    setFaqArray([...faqArray.fill(oldItem, index, index + 1)]);
  }
  function _editQuestion(e, index) {
    let oldItem = faqArray[index];
    oldItem["question"] = e;
    setFaqArray([...faqArray.fill(oldItem, index, index + 1)]);
  }
  function _removeFaq(index) {
    let oldItem = faqArray.slice();
    oldItem.splice(index, 1);
    setFaqArray([...oldItem]);
  }
  const [setContent] = useMutation(SET_FAQ_CONTENT, {
    onCompleted({ setContent }) {
      if (setContent.success) {
        Alert.alert("", `Q & A 已成功更新！`, [
          {
            text: "確定",
            type: "ok",
          },
        ]);
      } else {
        Alert.alert("", `${setContent.message}`, [
          {
            text: "確定",
            type: "ok",
          },
        ]);
      }
    },
    onError(error) {
      Alert.alert("", `${error}`, [
        {
          text: "確定",
          type: "ok",
        },
      ]);
    },
  });
  const { error, loading } = useQuery(GET_FAQ_CONTENT, {
    fetchPolicy: "network-only",
    onCompleted({ content }) {
      if (content && content.body) {
        setFaqArray(JSON.parse(content.body));
      }
    },
  });
  if (error) {
    Alert.alert("", `${error}`, [
      {
        text: "確定",
        type: "ok",
      },
    ]);
  }
  function submit() {
    setContent({
      variables: {
        body: JSON.stringify(faqArray),
      },
    });
  }
  return (
    <WebLayout title={"Q & A"} onUpdate={faqArray && submit}>
      {!loading ? (
        <>
          <Grid container direction="column">
            <Grid item classes={{ root: classes.FaqListArea }}>
              {Boolean(faqArray) &&
                faqArray.map((item, index) => (
                  <FaqItem
                    data={item}
                    index={index}
                    onRemoveFaq={() => _removeFaq(index)}
                    onChangeAnswer={(e) => _editAnswer(e, index)}
                    onChangeQuestion={(e) => _editQuestion(e, index)}
                  />
                ))}
            </Grid>
          </Grid>
          <AddButton
            title="問答"
            style={{
              position: "sticky",
              bottom: theme.spacing(1),
              right: theme.spacing(1),
              alignSelf: "flex-end",
            }}
            onClick={_addFaq}
          />
        </>
      ) : (
        <LoadingBox />
      )}
    </WebLayout>
  );
}

function FaqItem({
  data,
  index,
  onRemoveFaq = () => {},
  onChangeAnswer = () => {},
  onChangeQuestion = () => {},
}) {
  const theme = useTheme();
  const useStyles = makeStyles({
    IconBox: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "1.5rem",
      width: "1.5rem",
      borderRadius: theme.shape.borderRadius,
      color: "white",
      marginRight: theme.spacing(1),
    },
  });
  const classes = useStyles();
  return (
    <CardBox
      title={`問答${index + 1}`}
      buttons={[
        index > 0 && <DeleteButton title="此項" onClick={onRemoveFaq} />,
      ]}
    >
      <Grid container item direction="column">
        <Grid item>
          <ListItem>
            <Box
              classes={{ root: classes.IconBox }}
              bgcolor={theme.palette.primary.light}
            >
              Q
            </Box>
            <Typography>問題</Typography>
          </ListItem>
          <ListItem>
            <TextField
              variant="outlined"
              size="small"
              placeholder="請輸入問題"
              fullWidth
              multiline
              value={data.question}
              onChange={(e) => onChangeQuestion(e.target.value)}
            />
          </ListItem>
        </Grid>
        <Grid item>
          <ListItem>
            <Box
              classes={{ root: classes.IconBox }}
              bgcolor={theme.palette.secondary.light}
            >
              A
            </Box>
            <Typography>回答</Typography>
          </ListItem>
          <ListItem>
            <TextField
              variant="outlined"
              size="small"
              placeholder="請輸入回答"
              fullWidth
              multiline
              value={data.answer}
              onChange={(e) => onChangeAnswer(e.target.value)}
            />
          </ListItem>
        </Grid>
      </Grid>
    </CardBox>
  );
}
