import React from "react";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/react-hooks";
import gql from "graphql-tag";

import WebLayout from "../components/WebLayout";
import PostPage from "../components/PostPage";

const GET_ENROLLMENT = gql`
  query GetEnrollment($id: ID!) {
    article(id: $id) {
      id
      title
      source
      picture {
        filename
        mimetype
        encoding
        location
      }
      description
      content
      createdAt
    }
  }
`;

export default function EnrollmentDetail() {
  const { id } = useParams();
  const { data } = useQuery(GET_ENROLLMENT, { variables: { id } });
  return (
    <WebLayout>
      {data && <PostPage data={data.article} />}
    </WebLayout>
  );
}
