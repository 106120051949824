import React from "react";
import {
  Card,
  CardHeader,
  CardContent,
  Divider,
  makeStyles,
  CardActions,
} from "@material-ui/core";

export default function CardBox({
  title = "",
  subheader = "",
  children,
  buttons,
  ...props
}) {
  const useStyles = makeStyles({
    Card: {
      display: "flex",
      flexDirection: "column",
    },
    CardTitleText: {
      fontSize: 20,
    },
    CardContent: {
      position:'relative',
      display: "flex",
      flex: 1,
    },
  });
  const classes = useStyles();
  return (
    <Card variant={"outlined"} classes={{ root: classes.Card }} {...props}>
      {title && (
        <CardHeader
          title={title}
          subheader={subheader}
          titleTypographyProps={{ classes: { root: classes.CardTitleText } }}
        />
      )}
      <Divider />
      <CardContent classes={{ root: classes.CardContent }}>
        {children}
      </CardContent>
      {buttons && <CardActions>{buttons}</CardActions>}
    </Card>
  );
}
