import React, { useState, useLayoutEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  makeStyles,
  useTheme,
  useMediaQuery,
  TextField,
  InputAdornment,
  Grid,
  Typography,
  colors,
  Hidden,
  Container,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { useMutation } from "@apollo/react-hooks";
import gql from "graphql-tag";

import { ReactComponent as LogoIcon } from "../images/logo.svg";
import { LoginButton } from "../components/Buttons";
import { useAlert } from "./../components/Alert";

const LOGIN_CONSOLE = gql`
  mutation LoginConsole($username: String!, $password: String!) {
    loginConsole(username: $username, password: $password) {
      token
    }
  }
`;

export default function ConsoleLoginPage() {
  const Alert = useAlert();
  const history = useHistory();
  useLayoutEffect(() => {
    if (localStorage.getItem("token")) {
      history.replace("/console/about");
    }
  }, []);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginFailed, setLoginFailed] = useState(false);
  const [loginConsole, { loading }] = useMutation(LOGIN_CONSOLE, {
    variables: {
      username,
      password,
    },
    onCompleted({ loginConsole }) {
      if (loginConsole) {
        setLoginFailed(false);
        localStorage.setItem("token", loginConsole.token);
        history.replace("/console/about");
      } else {
        setLoginFailed(true);
      }
    },
    onError(error) {
      Alert.alert("", `${error}`, [
        {
          text: "確定",
          type: "ok",
        },
      ]);
    },
  });
  const theme = useTheme();
  const deviceSize = useMediaQuery(theme.breakpoints.down("sm"));
  let iconSetting = {
    style: { color: deviceSize && "white", margin: `0 ${theme.spacing(1)}px` },
  };
  const useStyles = makeStyles({
    Container: {},
    RightBox: {
      zIndex: 0,
    },
    LeftBox: {
      [theme.breakpoints.down("sm")]: {
        position: "absolute",
        height: "100%",
        width: "100%",
        zIndex: 1,
      },
    },
    LeftBoxInSide: {
      "& > *": {
        margin: `${theme.spacing(1)}px 0`,
      },
      "& > :last-child": {
        margin: `${theme.spacing(1)}px 0 0 0`,
      },
      "& > :first-child": {
        margin: `0 0 ${theme.spacing(1)}px 0`,
      },
    },
    ImgBackground: {
      willChange: "auto",
      width: "100%",
      height: "100%",
      objectFit: "cover",
      [theme.breakpoints.down("sm")]: { filter: `brightness(0.5)` },
    },
    TextInput: {
      borderRadius: 25,
      [theme.breakpoints.down("sm")]: {
        "& ::placeholder": {
          color: `rgba(255, 255, 255)`,
          opacity: 0.87,
        },
        color: `rgba(255, 255, 255, 0.87)`,
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: `rgba(255, 255, 255, 0.23)`,
        },
      },
    },
  });
  const classes = useStyles();
  return (
    <Box
      display="flex"
      position="relative"
      minHeight={"100vh"}
      height={"100vh"}
      classes={{ root: classes.Container }}
    >
      <Box
        display="flex"
        flex={1}
        alignItems="center"
        classes={{ root: classes.LeftBox }}
      >
        <Container maxWidth="xs">
          <Grid
            container
            direction="column"
            alignItems="center"
            classes={{ root: classes.LeftBoxInSide }}
          >
            <Grid container item justify="center">
              <LogoIcon fill={deviceSize && "white"} width="100%" />
            </Grid>
            {loginFailed && (
              <Grid container item justify="center" alignItems="center">
                <ErrorOutlineIcon style={{ color: theme.palette.error.main }} />
                <Typography color="error">
                  帳號或密碼不正確，請重新輸入
                </Typography>
              </Grid>
            )}
            <Grid container item justify="center">
              <TextField
                size="small"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment>
                      <AccountCircleOutlinedIcon {...iconSetting} />
                    </InputAdornment>
                  ),
                  classes: { root: classes.TextInput },
                }}
                placeholder="帳號"
                value={username}
                onChange={(event) => setUsername(event.target.value)}
                fullWidth
              />
            </Grid>
            <Grid container item justify="center">
              <TextField
                size="small"
                variant="outlined"
                InputProps={{
                  startAdornment: (
                    <InputAdornment>
                      <LockOutlinedIcon {...iconSetting} />
                    </InputAdornment>
                  ),
                  classes: { root: classes.TextInput },
                }}
                type="password"
                placeholder="密碼"
                value={password}
                onChange={(event) => setPassword(event.target.value)}
                fullWidth
              />
            </Grid>
            <Grid container item justify="center">
              <LoginButton
                disabled={!username || !password}
                onClick={loginConsole}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        display="flex"
        flex={1}
        position="relative"
        justifyContent="center"
        alignItems="center"
        classes={{ root: classes.RightBox }}
      >
        <img
          src={require("../images/backgroundImage.jpg")}
          className={classes.ImgBackground}
        />
        <Hidden smDown>
          <a
            style={{
              color: "gold",
              textShadow: `1px 1px 2px ${colors.grey["900"]}`,
              fontSize: "1.5rem",
              fontFamily: "cwTeXKai",
              fontWeight: "bold",
              display: "flex",
              flexDirection: "column",
              position: "absolute",
            }}
          >
            <a>完美的世界無法向外追尋！</a>
            <a>催眠幫助我們身心安頓！</a>
            <a>輕鬆快樂的活在桃花源！</a>
          </a>
        </Hidden>
      </Box>
    </Box>
  );
}
