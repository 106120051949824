import React from "react";
import { Box, makeStyles } from "@material-ui/core";

export default function AspectRatioBox({
  widthSize = 1,
  heightSize = 1,
  children,
  ...props
}) {
  const useStyles = makeStyles({
    Box: {
      "& > *": {
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100%",
        height: "100%",
        objectFit: "cover",
      },
    },
  });
  const classes = useStyles();
  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      position={"relative"}
      paddingTop={`calc(${(heightSize / widthSize) * 100}%)`}
      width={"100%"}
      classes={{ root: classes.Box }}
    >
      {children}
    </Box>
  );
}
