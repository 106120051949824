import React, { useState, useEffect } from "react";
import {
  Box,
  makeStyles,
  Typography,
  useTheme,
  AppBar,
  MenuItem,
  Menu,
  useScrollTrigger,
  IconButton,
  SwipeableDrawer,
  colors,
  Collapse,
  Toolbar,
  Hidden,
  ButtonBase,
  useMediaQuery,
  Slide,
} from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import MenuIcon from "@material-ui/icons/Menu";
import AspectRatioBox from "./AspectRatioBox";
import GoogleMaps from "../components/GoogleMap";

import { ReactComponent as LogoIcon } from "../images/logo.svg";
let menuTextColor = "#F7D76C";

export default function WebLayout({ children, data, ...props }) {
  const location = useLocation();
  const theme = useTheme();
  const onTop = !useScrollTrigger({ threshold: 0, disableHysteresis: true });
  const trigger = useScrollTrigger();
  const inHome = location.pathname == "/";
  const useStyles = makeStyles({
    AppBarRoot: {
      transition: "background-color 0.2s",
      backgroundColor: !inHome
        ? colors.grey["900"]
        : onTop
        ? `rgb(33, 33, 33, .5)`
        : colors.grey["900"],
    },
    ContainBox: {
      boxSizing: "border-box",
      [theme.breakpoints.down("xs")]: {
        padding: inHome
          ? 0
          : `${theme.spacing(2)}px ${theme.spacing(1.618 * 2)}px`,
      },
      [theme.breakpoints.up("sm")]: {
        padding: inHome
          ? 0
          : `${theme.spacing(3)}px ${theme.spacing(1.618 * 3)}px`,
      },
      [theme.breakpoints.up("md")]: {
        padding: inHome
          ? 0
          : `${theme.spacing(4)}px ${theme.spacing(1.618 * 4)}px`,
      },
    },
    HomeBannerImage: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      position: "absolute",
      zIndex: -1,
    },
    HomeBannerText: {
      color: "gold",
      textShadow: `1px 1px 2px ${colors.grey["900"]}`,
      fontSize: "1.5rem",
      fontFamily: "cwTeXKai",
      fontWeight: "bold",
      display: "flex",
      flexDirection: "column",
    },
    BannerImage: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
      position: "absolute",
    },
    BannerText: {
      color: "white",
      position: "absolute",
      textShadow: `1px 1px 2px ${colors.grey["900"]}`,
    },
  });
  const classes = useStyles();
  const [openDrawer, setOpenDrawer] = useState(false);
  const tabletSize = useMediaQuery(theme.breakpoints.down("sm"));
  useEffect(() => {
    setOpenDrawer(false);
  }, [tabletSize]);
  const MenuArray = [
    {
      logo: (e) => <LogoIcon {...e} />,
      route: "/",
    },
    {
      title: "認識協會",
      secondMenu: [
        { title: "協會介紹", route: "/about" },
        { title: "聯絡我們", route: "/contact" },
        { title: "Q & A", route: "/faq" },
      ],
    },
    {
      title: "活動專區",
      secondMenu: [
        { title: "活動訊息", route: "/event" },
        { title: "活動花絮", route: "/album" },
        { title: "催眠師課程諮詢", route: "/course" },
        { title: "尋找附近催眠師", route: "/hypnotist" },
      ],
    },
    {
      title: "會員專區",
      secondMenu: [
        { title: "會員資訊", route: "/member" },
        // { title: "會員諮詢室租用預約", route: "/rental" },
        // { title: "會員諮商室預約", route: "/reservation" },
        { title: "研習活動報名", route: "/enrollment" },
      ],
    },
    {
      title: "文章專區",
      secondMenu: [
        { title: "相關文章", route: "/essay" },
        { title: "心靈雞湯", route: "/story" },
      ],
    },
  ];
  const BannerArray = [
    { title: "協會介紹", route: "/about", image: "1_about.jpg" },
    { title: "聯絡我們", route: "/contact", image: "8_contact.jpg" },
    { title: "Q & A", route: "/faq", image: "6_faq.jpg" },
    { title: "活動訊息", route: "/event", image: "2_event.jpg" },
    { title: "活動花絮", route: "/album", image: "3_ablum.jpg" },
    { title: "催眠師課程諮詢", route: "/course", image: "12_course.jpg" },
    { title: "尋找附近催眠師", route: "/hypnotist", image: null },
    { title: "會員資訊", route: "/member", image: "4_member.jpg" },
    { title: "會員諮詢室租用預約", route: "/rental", image: "10_rental.jpg" },
    { title: "會員諮詢室預約", route: "/reservation", image: "10_rental.jpg" },
    { title: "研習活動報名", route: "/enrollment", image: "11_evnollment.jpg" },
    { title: "相關文章", route: "/essay", image: "5_essay.jpg" },
    { title: "心靈雞湯", route: "/story", image: "7_story.jpg" },
  ];
  const MenuAppBarArray = () => {
    let nowArray = MenuArray.slice();
    let newArray = [];
    let logoItem = nowArray.splice(0, 1);
    let secondItem = nowArray.splice(0, 2);
    newArray.push(...secondItem, ...logoItem, ...nowArray);
    return newArray;
  };
  const BannerImage = () => {
    return (
      <Box
        display={"flex"}
        height={"100%"}
        width={"100%"}
        justifyContent={"center"}
        alignItems={"center"}
        position={"relative"}
      >
        <img
          src={require("../images/homeBanner.jpg")}
          className={classes.HomeBannerImage}
        />
        <a className={classes.HomeBannerText}>
          <a>完美的世界無法向外追尋！</a>
          <a>催眠幫助我們身心安頓！</a>
          <a>輕鬆快樂的活在桃花源！</a>
        </a>
      </Box>
    );
  };
  return (
    <>
      {!inHome && <Toolbar style={{ height: 75 }} />}

      <Hidden mdUp>
        <Slide in={!trigger}>
          <AppBar elevation={0} classes={{ root: classes.AppBarRoot }}>
            <Box
              display={"flex"}
              height={75}
              justifyContent={"center"}
              alignItems={"center"}
              position={"relative"}
              padding={1}
              boxSizing={"border-Box"}
            >
              <LogoIcon height={"100%"} fill={menuTextColor} />
              <IconButton
                style={{ position: "absolute", right: theme.spacing(1) }}
                onClick={() => setOpenDrawer(true)}
              >
                <MenuIcon style={{ color: menuTextColor }} />
              </IconButton>
            </Box>
          </AppBar>
        </Slide>
      </Hidden>
      <Hidden smDown>
        <AppBar elevation={0} classes={{ root: classes.AppBarRoot }}>
          <Box
            display={"flex"}
            justifyContent={"space-evenly"}
            alignItems={"center"}
            height={75}
          >
            {MenuAppBarArray().map((item) => (
              <HomeMenuItem data={item} />
            ))}
          </Box>
        </AppBar>
      </Hidden>
      <Hidden mdUp>
        <HomeDrawer
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
          onOpen={() => setOpenDrawer(true)}
          data={MenuArray}
        />
      </Hidden>
      <Box display={"flex"} flexDirection={"column"}>
        <Box>
          {inHome ? (
            <Box
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
              height={"100vh"}
              width={"100%"}
            >
              {BannerImage()}
            </Box>
          ) : (
            <>
              {location.pathname.startsWith("/hypnotist") ? (
                <Box
                  height={`calc(100vh - 75px)`}
                  display={"flex"}
                  justifyContent="center"
                  alignItems="center"
                >
                  <GoogleMaps data={data} />
                </Box>
              ) : (
                <AspectRatioBox widthSize={21} heightSize={9}>
                  <Box
                    display="flex"
                    width="100%"
                    height="100%"
                    justifyContent="center"
                    alignItems="center"
                    position="relative"
                  >
                    {BannerArray.map((item) => {
                      if (
                        location.pathname.startsWith(item.route) &&
                        item.image
                      ) {
                        return (
                          <img
                            src={require(`../images/${item.image}`)}
                            className={classes.BannerImage}
                          />
                        );
                      }
                    })}
                    <Typography
                      classes={{ root: classes.BannerText }}
                      variant={"h4"}
                    >
                      {BannerArray.map((item) => {
                        if (location.pathname.startsWith(item.route)) {
                          return item.title;
                        }
                      })}
                    </Typography>
                  </Box>
                </AspectRatioBox>
              )}
            </>
          )}
        </Box>
        <Box classes={{ root: classes.ContainBox }}>{children}</Box>
        <Box
          display={"flex"}
          justifyContent={"space-evenly"}
          alignItems={"center"}
          bgcolor={colors.grey["900"]}
          height={150}
        >
          <Hidden smDown>
            <LogoIcon fill={menuTextColor} style={{ height: "50%" }} />
          </Hidden>
          <Typography style={{ color: menuTextColor }}>
            <Typography variant={"caption"} display={"block"}>
              地　　址：新北市八里區文心街97-5號
            </Typography>
            <Typography variant={"caption"} display={"block"}>
              聯絡我們：0912025115
            </Typography>
            <Typography variant={"caption"} display={"block"}>
              電子信箱：nghasia2024@gmail.com
            </Typography>
          </Typography>
          <Box display={"flex"} flexDirection={"column"} alignItems={"center"}>
            <img
              src={require("../images/NGHQRCode.png")}
              style={{
                height: 100,
                width: 100,
                objectFit: "contain",
              }}
            />
            <Typography variant={"caption"} style={{ color: menuTextColor }}>
              粉絲專頁QRCode
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}

function HomeMenuItem({ data }) {
  const history = useHistory();
  const location = useLocation();
  function _pushPage(e) {
    location.pathname != e && history.push(e);
  }
  const samePage = (e) => location.pathname == e;

  const [openMenu, setOpenMenu] = useState(false);
  const theme = useTheme();
  const useStyles = makeStyles({
    SecondMenu: {
      backgroundColor: colors.grey["900"],
    },
  });
  const classes = useStyles();

  return (
    <>
      {data.logo ? (
        <ButtonBase
          style={{
            height: "100%",
            boxSizing: "border-box",
            padding: theme.spacing(1),
          }}
          onClick={() => _pushPage(data.route)}
        >
          <data.logo fill={menuTextColor} height={"100%"} />
        </ButtonBase>
      ) : (
        <>
          <MenuItem
            onClick={(e) =>
              data.secondMenu
                ? setOpenMenu(e.currentTarget)
                : _pushPage(data.route)
            }
            style={{ height: "100%", color: menuTextColor }}
            disabled={samePage(data.route)}
          >
            {data.title}
          </MenuItem>
          <Menu
            disableScrollLock
            anchorEl={openMenu}
            open={openMenu}
            onClose={() => setOpenMenu(false)}
            classes={{ paper: classes.SecondMenu }}
          >
            {data.secondMenu.map((item) => (
              <MenuItem
                onClick={() => _pushPage(item.route)}
                disabled={samePage(item.route)}
                style={{ color: menuTextColor }}
              >
                {item.title}
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </>
  );
}
function HomeDrawer({ data, ...props }) {
  const theme = useTheme();
  const useStyles = makeStyles({
    DrawerPaper: {
      width: "80%",
      maxWidth: 300,
      backgroundColor: colors.grey["900"],
    },
    LogoArea: {
      width: "100%",
      height: 75,
      padding: theme.spacing(2),
      boxSizing: "border-box",
    },
    MenuItemRoot: {
      color: menuTextColor,
    },
    MenuItemSecondRoot: {
      color: menuTextColor,
      paddingLeft: theme.spacing(5),
    },
  });
  const classes = useStyles();
  const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent);
  const history = useHistory();
  const location = useLocation();
  function _pushPage(e) {
    location.pathname != e && history.push(e);
  }
  const samePage = (e) => location.pathname == e;

  const [openMenu, setOpenMenu] = useState(null);

  return (
    <SwipeableDrawer
      disableScrollLock
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      anchor={"right"}
      classes={{ paper: classes.DrawerPaper }}
      {...props}
    >
      {data.map((item, index) => (
        <>
          {item.logo ? (
            <ButtonBase
              classes={{ root: classes.LogoArea }}
              onClick={() => _pushPage(item.route)}
            >
              <item.logo fill={menuTextColor} height={"100%"} />
            </ButtonBase>
          ) : (
            <>
              <MenuItem
                classes={{ root: classes.MenuItemRoot }}
                onClick={() => {
                  if (item.secondMenu) {
                    if (openMenu) {
                      if (openMenu == index) {
                        setOpenMenu(null);
                      } else {
                        setOpenMenu(index);
                      }
                    } else {
                      setOpenMenu(index);
                    }
                  } else {
                    _pushPage(item.route);
                  }
                }}
                disabled={samePage(item.route)}
              >
                {item.title}
              </MenuItem>
              <Collapse in={openMenu == index} mountOnEnter unmountOnExit>
                {item.secondMenu.map((item) => (
                  <MenuItem
                    classes={{ root: classes.MenuItemSecondRoot }}
                    onClick={() => {
                      _pushPage(item.route);
                    }}
                    disabled={samePage(item.route)}
                  >
                    {item.title}
                  </MenuItem>
                ))}
              </Collapse>
            </>
          )}
        </>
      ))}
    </SwipeableDrawer>
  );
}
