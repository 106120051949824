import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Grid,
  TableContainer,
  Card,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Hidden,
  IconButton,
  useTheme,
  Typography,
  Collapse,
  makeStyles,
  Box,
} from "@material-ui/core";
import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import moment from "moment";

import { DeleteButton, EditButton } from "./Buttons";
import SelectTablePage from "./SelectTablePage";

export default function ProductTable({
  hypnotist = [],
  onEdit = () => {},
  onDelete = () => {},
}) {
  const history = useHistory();
  return (
    <Grid container direction={"column"}>
      <Grid item>
        <Card variant={"outlined"}>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <Hidden mdUp>
                    <TableCell style={{ width: 20, padding: 0 }} />
                  </Hidden>
                  <TableCell style={{ width: 75 }}>編號</TableCell>
                  <TableCell>標題</TableCell>
                  <TableCell style={{ width: 75 }}>日期</TableCell>
                  <Hidden smDown>
                    <TableCell style={{ width: 210 }} />
                  </Hidden>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  hypnotist.map((row, index) =>
                    <ProductItem
                      data={row}
                      index={row.id}
                      onDelete={onDelete}
                      onEdit={onEdit}
                    />
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      </Grid>
    </Grid>
  );
}

function ProductItem({
  data,
  index,
  onDelete = () => {},
  onEdit = () => {},
  onSetting = () => {},
}) {
  const theme = useTheme();
  const useStyles = makeStyles({
    TableRowRoot: {
      "& > *": {
        [theme.breakpoints.down("md")]: {
          borderBottom: "unset",
        },
      },
    },
  });
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  return (
    <>
      <TableRow classes={{ root: classes.TableRowRoot }}>
        <Hidden mdUp>
          <TableCell
            style={{ width: 20, padding: theme.spacing(1) }}
            onClick={() => setOpen(!open)}
          >
            <IconButton style={{ width: 20, height: 20 }}>
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </TableCell>
        </Hidden>
        <TableCell>{data.id}</TableCell>
        <TableCell>{data.title || data.name}</TableCell>
        <TableCell align="center">{moment(data.createdAt).format("YYYY/MM/DD")}</TableCell>
        <Hidden smDown>
          <TableCell>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <EditButton onClick={() => onEdit(index)} />
              </Grid>
              <Grid item xs={6}>
                <DeleteButton onClick={() => onDelete(index)} />
              </Grid>
            </Grid>
          </TableCell>
        </Hidden>
      </TableRow>
      <Hidden mdUp>
        <TableRow>
          <TableCell colSpan={4} style={{ padding: theme.spacing(1) }}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box padding={2}>
                <Grid container spacing={1}>
                  <Grid item xs={6}>
                    <EditButton onClick={() => onEdit(index)} fullWidth />
                  </Grid>
                  <Grid item xs={6}>
                    <DeleteButton onClick={() => onDelete(index)} fullWidth />
                  </Grid>
                </Grid>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </Hidden>
    </>
  );
}
